import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { CustomError } from '../../global-models/custom-error';
import { errorMessage } from '../../helpers/error-message';

@Injectable()
export class ErrorService {
  getCustomStack(error: CustomError): any {}

  getCustomMessage(error: CustomError): string {
    return errorMessage(error) ? errorMessage(error) : error.toString();
  }

  getServerStack(error: HttpErrorResponse): any {
    // throw Error("Method not implemented.");
  }

  getClientStack(error: Error): any {
    // throw Error("Method not implemented.");
  }

  getClientMessage(error: Error): string {
    return errorMessage(error) ? errorMessage(error) : error.toString();
  }

  getServerMessage(error: HttpErrorResponse): string {
    return navigator.onLine ? errorMessage(error) : 'No Internet Connection';
  }
}
