import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Constants } from '../../constants';
import { environment } from '../../../environments/environment';
import { StoreService } from './store.service';
import { take } from 'rxjs/operators';
import { response } from 'express';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class ConnectionService {
  constructor(private http: HttpClient, private store: StoreService, private route: ActivatedRoute) {}

  /**Test backend connectivity */
  async pingBackend(): Promise<boolean> {
    try {
      const response = await this.http
        .get<any>(`${Constants.trackingApiRoot}/ping`, { observe: 'response' })
        .pipe(take(1))
        .toPromise();

      const redirect = response && response.body ? response.body.redirect : undefined;
      redirect ? this.enforceSecure() : null;

      this.store.isOnline = !redirect && navigator.onLine;
      return this.store.isOnline;
    } catch (error) {
      this.store.isOnline = false;
      return false;
    }
  }

  /**Checks for the online status of the user agent */
  async isOnline(): Promise<boolean> {
    if (!navigator.onLine) {
      this.store.isOnline = false;
      return false;
    }
    return await this.pingBackend();
  }

  private enforceSecure() {
    window.location.href = window.location.href.replace('http:', 'https:');
  }
}
