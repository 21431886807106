import { Injectable } from '@angular/core';
import { BackdropManagerService } from './backdrop-manager.service';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  busy = false;
  private spinner: HTMLElement;
  constructor(private backdrop: BackdropManagerService) {
    const div = document.createElement('div');
    const span = document.createElement('span');
    span.setAttribute('class', 'visually-hidden');
    span.textContent = 'Loading...';
    div.setAttribute('class', 'smx-spinner spinner-border text-primary');
    div.setAttribute('role', 'status');
    div.setAttribute(
      'style',
      'position: fixed; top: calc(50% - 2rem); left: calc(50% - 1rem); z-index: 5000;'
    );
    div.appendChild(span);

    this.spinner = div;
  }

  /**
   * Shows a spinner on the screen
   *
   * @param lockScreen A flag whether to effect a screen-wide lockdown during the life of the spinner. Defaults to ```false```.
   */
  show(lockScreen = false) {
    const spinnerShowing = document.querySelector('.smx-spinner');
    this.busy = true;
    if (!spinnerShowing) {
      document.body.appendChild(this.spinner);
    }
    if (lockScreen) {
      this.backdrop.show();
    }
  }

  /**
   * Hides the spinner
   */
  hide() {
    const spinnerShowing = document.querySelector('.smx-spinner');
    this.busy = false;
    if (spinnerShowing) {
      document.body.removeChild(spinnerShowing);
      this.backdrop.onLockdown() ? this.backdrop.hide() : null;
    }
  }
}
