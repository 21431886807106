import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PopoversService {
  initialisePopovers() {
    const popoverTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new (
        window as any
      ).bootstrap.Popover(popoverTriggerEl, { container: 'body' });
    });
  }
}