import { Injectable } from '@angular/core';

import { NotificationService } from '../../core/services/notification.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { Constants } from '../../constants';
import { FilterContext } from '../interfaces-enums/filter-context.enum';
import { ITrackable } from '../interfaces-enums/trackable';
import { DateService } from '../../core/services/date.service';
import { AuthService } from '../../core/services/auth.service';
import { IndexDbService } from '../../core/services/index-db.service';
import { errorMessage } from '../../helpers/error-message';
import {
  IDatesFilterObjectRequestParams,
  IDatesAndTalliesSettings,
  ITrackableGeneralFilter,
} from '../interfaces-enums/trackable-filters';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TrackablesFilterService {
  filterClicked$ = new BehaviorSubject(false);

  private user: string;
  private account: string;

  /**Suffix that represents a certain user. This is intended to be used as key suffix on local or session storage */
  get userSuffix() {
    return `:${this.user}:${this.account}:${Constants.appNamespace}`;
  }

  /**Suffix that represents a certain account. This is intended to be used as key suffix on local or session storage */
  get accountSuffix() {
    return `:${this.account}:${Constants.appNamespace}`;
  }

  /**Suffix that represents the entire application. This is intended to be used as key suffix on local or session storage */
  get applicationSuffix() {
    return `:${Constants.appNamespace}`;
  }

  constructor(
    private notifier: NotificationService,
    private spinner: SpinnerService,
    private date: DateService,
    private authService: AuthService,
    private db: IndexDbService
  ) {
    this.authService.user$.subscribe((profile) => {
      if (profile) {
        const { sub, account } = this.authService.getUserProfile();
        this.account = account;
        this.user = sub;
      }
    });
  }

  /**
   * Removes/resets all logged in user settings of a given Trackable in the LocalStorage
   *
   * @param trackableId Trackable ID
   * @param context Filtering context
   */
  resetOrDeleteTrackableLocalSettings(
    trackableId: string,
    context?: FilterContext
  ) {
    const storage = new Object(localStorage);
    for (const key in storage) {
      if (storage.hasOwnProperty(key)) {
        const prefix = `${context}-${trackableId}`;
        if (context) {
          if (key.startsWith(prefix) && key.endsWith(this.userSuffix)) {
            localStorage.removeItem(key);
          }
        } else {
          if (key.includes(trackableId) && key.endsWith(this.userSuffix)) {
            localStorage.removeItem(key);
          }
        }
      }
    }
  }

  /**
   * Clears application data stored in the device
   *
   * @param depth The depth the clearing operation must use
   */
  async clearLocalData(depth: 'user' | 'account' | 'application') {
    let suffix: string;
    switch (depth) {
      case 'application':
        suffix = this.applicationSuffix;
        break;
      case 'account':
        suffix = this.accountSuffix;
        break;
      default:
        suffix = this.userSuffix;
        break;
    }

    const storage = new Object(localStorage);
    for (const key in storage) {
      if (
        (storage.hasOwnProperty(key) && key.endsWith(suffix)) ||
        key.endsWith('trekapt-spa')
      ) {
        // N.B. Suffix 'trekapt-spa' is included to clean up residuals of legacy Trekapt App
        localStorage.removeItem(key);
      }
    }

    await this.clearIndexedDbTables(depth);
  }

  getActiveSupervisionStatus(context: FilterContext, trackableId: string) {
    const mode = localStorage.getItem(
      `${context}-${trackableId}-supervision-mode:${this.user}:${this.account}:${Constants.appNamespace}`
    );
    if (mode) {
      return JSON.parse(mode) as boolean;
    }
    this.setActiveSupervisionStatus(false, context, trackableId);
    return false;
  }

  setActiveSupervisionStatus(
    isProdMode: boolean,
    context: FilterContext,
    trackableId
  ) {
    localStorage.setItem(
      `${context}-${trackableId}-supervision-mode:${this.user}:${this.account}:${Constants.appNamespace}`,
      JSON.stringify(isProdMode)
    );
  }

  setDatesPrecisionStatus(
    inHighPres: boolean,
    context: FilterContext,
    trackableId: string
  ) {
    const active = this.getActiveSupervisionStatus(context, trackableId);
    active
      ? localStorage.setItem(
          `${context}-${trackableId}-active-high-precision:${this.user}:${this.account}:${Constants.appNamespace}`,
          JSON.stringify(inHighPres)
        )
      : localStorage.setItem(
          `${context}-${trackableId}-passive-high-precision:${this.user}:${this.account}:${Constants.appNamespace}`,
          JSON.stringify(inHighPres)
        );
  }

  getDatesPrecisionStatus(
    context: FilterContext,
    trackableId: string
  ): boolean {
    const active = this.getActiveSupervisionStatus(context, trackableId);
    const mode = active
      ? localStorage.getItem(
          `${context}-${trackableId}-active-high-precision:${this.user}:${this.account}:${Constants.appNamespace}`
        )
      : localStorage.getItem(
          `${context}-${trackableId}-passive-high-precision:${this.user}:${this.account}:${Constants.appNamespace}`
        );
    if (mode) {
      return JSON.parse(mode) as boolean;
    }
    this.setDatesPrecisionStatus(false, context, trackableId);
    return false;
  }

  setEndTodayStatus(
    endToday: boolean,
    context: FilterContext,
    trackableId: string
  ) {
    const active = this.getActiveSupervisionStatus(context, trackableId);
    active
      ? localStorage.setItem(
          `${context}-${trackableId}-active-end-today:${this.user}:${this.account}:${Constants.appNamespace}`,
          JSON.stringify(endToday)
        )
      : localStorage.setItem(
          `${context}-${trackableId}-passive-end-today:${this.user}:${this.account}:${Constants.appNamespace}`,
          JSON.stringify(endToday)
        );

    if (endToday) {
      // This resets the object with endDates set to today
      this.setFilterDateObject(
        this.getFilterDateObject({ context, trackableId }),
        context,
        trackableId
      );
    }
  }

  getEndTodayStatus(context: FilterContext, trackableId: string): boolean {
    const active = this.getActiveSupervisionStatus(context, trackableId);
    const mode = active
      ? localStorage.getItem(
          `${context}-${trackableId}-active-end-today:${this.user}:${this.account}:${Constants.appNamespace}`
        )
      : localStorage.getItem(
          `${context}-${trackableId}-passive-end-today:${this.user}:${this.account}:${Constants.appNamespace}`
        );

    if (mode) {
      return JSON.parse(mode) as boolean;
    }
    this.setEndTodayStatus(false, context, trackableId);
    return false;
  }

  /**
   * Recursively filters Trackables
   *
   * The returned Trackables will have both their Trackers and MonioteredParameters filtered
   *
   * @param trackables Trackables to apply filters to
   * @param context Filtering context. This is reference to the view requesting filtering services
   * @returns Filtered Trackables with filtered Trackers and MoninoteredParameters
   */
  filter(trackables: ITrackable[], context: FilterContext): ITrackable[] {
    // this.localDBTables();

    const { trackableBlackList, trackerBlackList, updateIntervals } =
      this.getFilterObject(context);
    const filtered = trackables
      .filter((tkbl) => {
        if (tkbl) return !trackableBlackList.includes(tkbl._id);
        return false;
      })
      .map((tkbl) => {
        const trackers = tkbl.trackers.filter(
          (tk) => !trackerBlackList.includes(tk._id)
        );
        const monitoredParameters = tkbl.monitoredParameters.filter((mp) =>
          updateIntervals.includes(mp.updateInterval)
        );
        return Object.assign({}, tkbl, { trackers, monitoredParameters });
      })
      .filter(
        (tkbl) => tkbl.monitoredParameters.length && tkbl.trackers.length
      );

    if (trackables.length) {
      !filtered.length
        ? (this.spinner.hide(),
          this.notifier.showInfo(
            'Nothing to display. You may need to adjust your filters'
          ))
        : null;
    } else {
      !filtered.length
        ? (this.spinner.hide(),
          this.notifier.showInfo(`You haven't created any Trackers yet`))
        : null;
    }

    return filtered;
  }

  /**
   * Gets the user's filtering preferences
   *
   * @param context Filtering context. This is reference to the view requesting filtering services
   * @returns The filtering object
   */
  getFilterObject(context: FilterContext): ITrackableGeneralFilter {
    const filterObject = localStorage.getItem(
      `${context}-filter:${this.user}:${this.account}:${Constants.appNamespace}`
    );
    const defaultFilterObj: ITrackableGeneralFilter = {
      trackableBlackList: [],
      trackerBlackList: [],
      updateIntervals: [
        'hourly',
        'shiftly',
        'daily',
        'weekly',
        'monthly',
        'other',
      ],
    };
    if (filterObject) {
      return JSON.parse(filterObject);
    }
    this.setFilterObject(defaultFilterObj, context);
    return defaultFilterObj;
  }

  /**
   * Filters or unfilters a specified Trackable
   *
   * @param id Trackable ID
   * @param context Filtering context. This is reference to the view requesting filtering services
   * @param action Filtering service requested
   */
  filterTrackable(
    id: string,
    context: FilterContext,
    action: 'whiteList' | 'blackList'
  ) {
    const filterObj = this.getFilterObject(context);
    const { trackableBlackList } = filterObj;
    if (action === 'whiteList') {
      trackableBlackList.splice(trackableBlackList.indexOf(id), 1);
    } else if (action === 'blackList') {
      trackableBlackList.push(id);
    }
    this.setFilterObject(
      Object.assign({}, filterObj, {
        trackableBlackList: new Array(...new Set(trackableBlackList)),
      }),
      context
    );
  }

  /**
   * Filters or unfilters a specified Tracker
   *
   * @param id Tracker ID
   * @param context Filtering context. This is reference to the view requesting filtering services
   * @param action Filtering service requested
   */
  filterTracker(
    id: string,
    context: FilterContext,
    action: 'whiteList' | 'blackList'
  ) {
    const filterObj = this.getFilterObject(context);
    const { trackerBlackList } = filterObj;
    if (action === 'whiteList') {
      trackerBlackList.splice(trackerBlackList.indexOf(id), 1);
    } else if (action === 'blackList') {
      trackerBlackList.push(id);
    }
    this.setFilterObject(
      Object.assign({}, filterObj, {
        trackerBlackList: new Array(...new Set(trackerBlackList)),
      }),
      context
    );
  }

  /**
   * Filters or unfilters MonitoredParameters by ```updateInterval```
   *
   * @param intervalName Interval name
   * @param context Filtering context. This is reference to the view requesting filtering services
   * @param action Filtering service requested
   */
  filterInterval(
    intervalName: string,
    context: FilterContext,
    action: 'whiteList' | 'blackList'
  ) {
    const filterObj = this.getFilterObject(context);
    const { updateIntervals } = filterObj;
    if (action === 'whiteList') {
      updateIntervals.push(intervalName);
    } else if (action === 'blackList') {
      updateIntervals.splice(updateIntervals.indexOf(intervalName), 1);
    }
    this.setFilterObject(
      Object.assign({}, filterObj, {
        updateIntervals: new Array(...new Set(updateIntervals)),
      }),
      context
    );
  }

  /**
   * Gets the user's date filtering preferences
   *
   * @param params Params object containing the following properties:
   * @property ```context``` - Required. Filtering context. This is reference to the view requesting filtering services
   * @property ```context``` - Required. Trackable ID. This is reference to the ```Trackable``` requesting filtering services
   * @property ```forDisplayOrRequestsPurposes``` - Optional. Flag indicating whether the client asking for the resource is display/requests or not. Defaults to ```false```
   * @property ```calledBySetter``` - Optional. Flag indicating whether the call is being made by this function's setter or not. Defaults to ```false```
   * @returns The date filtering object
   */
  getFilterDateObject(
    params: IDatesFilterObjectRequestParams
  ): IDatesAndTalliesSettings {
    const activeSupervision = this.getActiveSupervisionStatus(
      params.context,
      params.trackableId
    );
    const inHighPrecision = this.getDatesPrecisionStatus(
      params.context,
      params.trackableId
    );
    const dateObjRaw = activeSupervision
      ? localStorage.getItem(
          `${params.context}-${params.trackableId}-active-dates-filter:${this.user}:${this.account}:${Constants.appNamespace}`
        )
      : localStorage.getItem(
          `${params.context}-${params.trackableId}-passive-dates-filter:${this.user}:${this.account}:${Constants.appNamespace}`
        );

    const endDate = this.date.endOfTime();
    const startDate = this.date.startOfTime(this.date.subtractDate(30));
    const defaultDateObj: IDatesAndTalliesSettings = {
      hourly: {
        startDate,
        endDate,
        autoPeriodStatus: false,
        tally: 10,
        period: this.date.difference(
          this.date.startOfTime(this.date.subtractDate(30)),
          this.date.endOfTime(),
          'seconds'
        ),
      },
      shiftly: {
        startDate,
        endDate,
        autoPeriodStatus: false,
        tally: 10,
        period: this.date.difference(
          this.date.startOfTime(this.date.subtractDate(30)),
          this.date.endOfTime(),
          'seconds'
        ),
      },
      daily: {
        startDate,
        endDate,
        autoPeriodStatus: false,
        tally: 10,
        period: this.date.difference(
          this.date.startOfTime(this.date.subtractDate(30)),
          this.date.endOfTime(),
          'seconds'
        ),
      },
      weekly: {
        startDate,
        endDate,
        autoPeriodStatus: false,
        tally: 10,
        period: this.date.difference(
          this.date.startOfTime(this.date.subtractDate(30)),
          this.date.endOfTime(),
          'seconds'
        ),
      },
      monthly: {
        startDate: this.date.startOfTime(this.date.subtractDate(183)),
        endDate,
        autoPeriodStatus: false,
        tally: 10,
        period: this.date.difference(
          this.date.startOfTime(this.date.subtractDate(183)),
          this.date.endOfTime(),
          'seconds'
        ),
      },
      other: {
        startDate,
        endDate,
        autoPeriodStatus: false,
        tally: 10,
        period: this.date.difference(
          this.date.startOfTime(this.date.subtractDate(30)),
          this.date.endOfTime(),
          'seconds'
        ),
      },
    };

    if (dateObjRaw) {
      const dateObj: IDatesAndTalliesSettings = JSON.parse(dateObjRaw);

      // Adapt for display and request purposes
      if (params.forDisplayOrRequestsPurposes) {
        const precSensitiveObj: Partial<IDatesAndTalliesSettings> = {};
        for (const key in dateObj) {
          if (dateObj.hasOwnProperty(key)) {
            const element = dateObj[key];
            const sliceEnd = inHighPrecision ? 16 : 10; // N.B. start- and end-date are in the format 'YYYY-MM-DDTHH:mm:ss.SSS'
            precSensitiveObj[key] = Object.assign({}, element, {
              startDate: (element.startDate as string).slice(0, sliceEnd),
              endDate: (element.endDate as string).slice(0, sliceEnd),
            });
          }
        }
        return precSensitiveObj as IDatesAndTalliesSettings;
      }

      // Not for display or requests. Always return high-precision for other things like saving the object
      return dateObj;
    } else {
      if (!params.calledBySetter) {
        // No dateObject found, set the default
        this.setFilterDateObject(
          defaultDateObj,
          params.context,
          params.trackableId,
          true
        );
      } else {
        return defaultDateObj;
      }
    }
    // Default date object filter set, now get and return it
    return this.getFilterDateObject(params);
  }

  /**
   * Sets the user's date filtering preferences
   *
   * @param dateFilterObj Dates filter object
   * @param context Filtering context. This is reference to the view requesting filtering services
   * @param trackableId Trackable ID. This is reference to the Trackable requesting filtering services
   * @param settingDefault Flag indicating whether a default date object is being set or not
   * @returns void
   */
  setFilterDateObject(
    dateFilterObj: IDatesAndTalliesSettings,
    context: FilterContext,
    trackableId: string,
    settingDefault = false
  ) {
    const inHighPrecision = this.getDatesPrecisionStatus(context, trackableId);
    const endToday = this.getEndTodayStatus(context, trackableId);
    const active = this.getActiveSupervisionStatus(context, trackableId);
    const originalDateObj = this.getFilterDateObject({
      context,
      trackableId,
      calledBySetter: true,
    });
    const highPrecisionFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';
    const lowPrecisionFormat = 'YYYY-MM-DD';
    let dateObj = {};
    if (!settingDefault) {
      for (const key in dateFilterObj) {
        if (dateFilterObj.hasOwnProperty(key)) {
          const element = dateFilterObj[key];
          const { autoPeriodStatus, period } = element;

          let endDate: string;
          let startDate: string;

          if (inHighPrecision) {
            endDate = endToday ? this.date.endOfTime() : element.endDate;
          } else {
            endDate = endToday
              ? this.date.endOfTime()
              : `${(element.endDate as string).slice(
                  0,
                  10
                )}T${this.date.getDatePart(
                  originalDateObj[key]['endDate'],
                  'HH:mm:ss.SSS'
                )}`;
          }

          if (inHighPrecision) {
            startDate = autoPeriodStatus
              ? this.date.subtractDate(
                  period,
                  endDate,
                  'seconds',
                  highPrecisionFormat,
                  highPrecisionFormat
                )
              : element.startDate;
          } else {
            const time = `${this.date.getDatePart(
              originalDateObj[key]['startDate'],
              'HH:mm:ss.SSS'
            )}`;
            startDate = autoPeriodStatus
              ? `${this.date.subtractDate(
                  period,
                  endDate,
                  'seconds',
                  lowPrecisionFormat,
                  lowPrecisionFormat
                )}T${time}`
              : `${(element.startDate as string).slice(0, 10)}T${time}`;
          }

          dateObj[key] = Object.assign({}, originalDateObj[key], element, {
            startDate,
            endDate,
          });
        }
      }
      dateObj = Object.assign({}, originalDateObj, dateFilterObj, dateObj);
    } else {
      dateObj = Object.assign({}, originalDateObj, dateFilterObj);
    }

    active
      ? localStorage.setItem(
          `${context}-${trackableId}-active-dates-filter:${this.user}:${this.account}:${Constants.appNamespace}`,
          JSON.stringify(dateObj)
        )
      : localStorage.setItem(
          `${context}-${trackableId}-passive-dates-filter:${this.user}:${this.account}:${Constants.appNamespace}`,
          JSON.stringify(dateObj)
        );
  }

  setPageIndex({ index, trackableId, context, earMark = undefined }) {
    localStorage.setItem(
      `${context}-${trackableId}${earMark ? '-' + earMark : ''}-page-index:${
        this.user
      }:${this.account}:${Constants.appNamespace}`,
      index
    );
  }

  getPageIndex({ trackableId, context, earMark = undefined }): number {
    const index = localStorage.getItem(
      `${context}-${trackableId}${earMark ? '-' + earMark : ''}-page-index:${
        this.user
      }:${this.account}:${Constants.appNamespace}`
    );
    if (index && !Number.isNaN(Number(index))) return +index;
    return;
  }

  getPageSize({ trackableId, context, earMark = undefined }): number {
    const index = localStorage.getItem(
      `${context}-${trackableId}${earMark ? '-' + earMark : ''}-page-size:${
        this.user
      }:${this.account}:${Constants.appNamespace}`
    );
    if (index && !Number.isNaN(Number(index))) return +index;
    return;
  }

  setPageSize({ size, trackableId, context, earMark = undefined }) {
    localStorage.setItem(
      `${context}-${trackableId}${earMark ? '-' + earMark : ''}-page-size:${
        this.user
      }:${this.account}:${Constants.appNamespace}`,
      size
    );
  }

  private setFilterObject(
    filterObject: ITrackableGeneralFilter,
    context: FilterContext
  ) {
    localStorage.setItem(
      `${context}-filter:${this.user}:${this.account}:${Constants.appNamespace}`,
      JSON.stringify(filterObject)
    );
  }

  private async clearIndexedDbTables(depth: any) {
    try {
      const batchDeletes = [];
      this.db.tables.forEach((table) => {
        const indexNames = table.schema.indexes.map((index) => index.name);
        if (indexNames.includes('account') && indexNames.includes('owner')) {
          batchDeletes.push(
            table
              .where('account')
              .equals(this.account)
              .and((record) => record.owner === this.user)
              .delete()
          );
        }
      });

      await Promise.all(batchDeletes);
      this.notifier.showSuccess(
        `${(depth as string).toUpperCase()} local data cleared successfully`
      );
    } catch (error) {
      this.notifier.showError(
        `Some data may not have been cleared due to: ${errorMessage(error)}`
      );
    }
  }
}
