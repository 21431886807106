import { NbMenuItem } from '@nebular/theme';
// import { StaffType, UserType } from '@smx/node-lib';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../../core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class MenuItemsService implements OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  private homeMenuItem: NbMenuItem[] = [
    { title: 'Home', icon: 'home-outline', link: '/home' },
  ];
  private trackersMenuItem: NbMenuItem[] = [
    {
      title: 'Tracked Items',
      icon: 'radio-outline',

      children: [
        {
          title: 'Tracked Groups',
          icon: 'radio',
          link: '/subscribed/tracking/trackables',
        },
        {
          title: 'Add Group',
          icon: 'plus',
          link: '/subscribed/tracking/trackables/0/edit',
        },
        {
          title: 'Supervise',
          icon: 'activity',
          link: '/subscribed/tracking/trackables/command-center',
        },
      ],
    },
  ];

  private teamMenuItem: NbMenuItem[] = [
    {
      title: 'My Team',
      icon: 'people-outline',
      children: [
        { title: 'Members', icon: 'people', link: '/admin/team' },
        {
          title: 'Add Member',
          icon: 'person-add',
          link: '/admin/team/0/edit',
        },
      ],
    },
  ];

  private contactMenuItem = [
    {
      title: 'Contact',
      icon: 'email-outline',
      link: '/admin/account/contact-support',
    },
  ];
  private signUpMenuItem = [
    {
      title: 'Sign Up',
      icon: 'person-add-outline',
      link: '/signup',
    },
  ];
  private helpMenuItem = [
    {
      title: 'Help',
      icon: 'question-mark-circle-outline',
      link: '/faq',
    },
  ];
  private menuItems: NbMenuItem[] = [];

  constructor(private authService: AuthService) {
    this.authService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.menuItems = []
        .concat(this.homeMenuItem)
        .concat(this.trackersMenuItem)
        .concat(this.teamMenuItem)
        .concat(this.contactMenuItem);
      // .concat(this.signUpMenuItem)
      // .concat(this.helpMenuItem);
      // if (user) {
      //   const role = user.scope;
      //   switch (role) {
      //     case UserType.admin:
      //     case UserType.superuser:
      //       this.menuItems = this.basicMenu
      //         .concat(this.studentsMenu)
      //         .concat(this.staffMenu)
      //         .concat(this.financeMenu)
      //         .concat(this.settingsMenu);
      //       break;
      //     case UserType.student:
      //       this.menuItems = this.basicMenu.concat(this.studentsMenu);
      //       break;
      //     case StaffType.teacher:
      //       this.menuItems = this.basicMenu
      //         .concat(this.studentsMenu)
      //         .concat(this.staffMenu);
      //       break;
      //     case StaffType.accounts:
      //     case StaffType.multi:
      //     case StaffType.administrative:
      //       this.menuItems = this.basicMenu
      //         .concat(this.studentsMenu)
      //         .concat(this.staffMenu)
      //         .concat(this.financeMenu);
      //       break;
      //     case StaffType.general:
      //       this.menuItems = new Array(...this.basicMenu);
      //       break;
      //     default:
      //       this.menuItems = [];
      //       break;
      //   }
      // } else {
      //   this.menuItems = [];
      // }
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**Gets the main menu items relevant to the logged in `user` */
  getMenuItems() {
    return this.menuItems;
  }
}
