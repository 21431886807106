import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class PaginatorIntl extends MatPaginatorIntl {
  itemsPerPageLabel = 'Tracker Groups per page';
  nextPageLabel = 'Next Set';
  previousPageLabel = 'Previous Set';
  firstPageLabel = 'First Set';
  lastPageLabel = 'Last Set';
  constructor() {
    super();
  }
}
