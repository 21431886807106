import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbCardModule, NbSelectModule, NbThemeService } from '@nebular/theme';
import { ProfileService } from '../../../utils/services/profile.service';

@Component({
  selector: 'ngx-preferences',
  standalone: true,
  imports: [CommonModule, NbCardModule, NbSelectModule],
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
})
export class PreferencesComponent implements OnDestroy {
  private themeKey: string;
  private isAlive = true;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  constructor(
    private themeService: NbThemeService,
    private profileService: ProfileService
  ) {
    this.themeKey = `${this.profileService.institutionAbreviation}:theme`;
    this.setTheme();
  }
  ngOnDestroy(): void {
    this.isAlive = false;
  }

  changeTheme(themeName: string) {
    localStorage.setItem(this.themeKey, themeName);
    setTimeout(() => {
      this.themeService.changeTheme(themeName);
    });
  }

  private setTheme() {
    const storedTheme = localStorage.getItem(this.themeKey);
    this.currentTheme = storedTheme || this.themeService.currentTheme;
    this.changeTheme(this.currentTheme);
  }
}
