<footer class="d-flex flex-column align-items-center w-100">
  <div
    *ngIf="footerTop"
    class="rounded-0 m-0 py-3 px-2 px-md-3 d-flex flex-column flex-md-row justify-content-md-between footer-top w-100"
    data-aos="flip-down"
    data-aos-easing="ease-in-cubic"
    data-aos-offset="40"
  >
    <section
      *ngIf="footerTopLeftHeader || footerTopLeftParagraph"
      class="vision text-center text-md-start mx-auto mx-md-0 order-1 order-md-0"
    >
      <header *ngIf="footerTopLeftHeader">
        <h2>{{ footerTopLeftHeader }}</h2>
      </header>
      <p *ngIf="footerTopLeftParagraph">
        {{ footerTopLeftParagraph }}
      </p>
    </section>
    <section
      *ngIf="footerTopLogoUrl"
      class="logo text-center mx-auto mx-md-4 mb-2 mb-md-0 order-0 order-md-1 d-flex justify-content-center"
    >
      <img
        class="align-self-start align-self-md-center"
        src="{{ footerTopLogoUrl }}"
        alt="{{ institutionFullName + ' logo' }}"
      />
    </section>
    <section
      *ngIf="footerTopRightHeader || footerTopRightParagraph"
      class="mission text-center text-md-end mx-auto mx-md-0 order-2 order-md-2"
    >
      <header *ngIf="footerTopRightHeader">
        <h2>{{ footerTopRightHeader }}</h2>
      </header>
      <p *ngIf="footerTopRightParagraph">
        {{ footerTopRightParagraph }}
      </p>
    </section>
  </div>
  <div
    class="container-fluid m-0 rounded-0 py-3 px-1 px-sm-2 px-md-3 d-flex flex-column footer-base align-items-center w-100"
  >
    <smx-social
      *ngIf="!hideSocial"
      class="mb-2"
      [socialProfile]="footerData?.socialProfile || {}"
      [iconColor]="footerData?.footerDesign?.socialIconColor || 'blue'"
    ></smx-social>
    <p class="text-center">
      {{
        '&copy; ' + year + ' ' + institutionFullName + ' All rights reserved.'
      }}
    </p>
    <p class="text-center">
      <span class="" *ngIf="designerUrl && (designerLogoUrl || designerName)">
        <span class="d-inline-block"> Website design & development by: </span>
        <span class="d-inline-block spacer"></span>
        <a class="d-inline-block" href="{{ designerUrl }}" target="_blank">
          <span class="fst-italic designer-name" *ngIf="!designerLogoUrl">
            <small class="fw-lighter">{{ designerName }}</small>
          </span>
          <img
            class="designer-logo"
            *ngIf="designerLogoUrl"
            src="{{ designerLogoUrl }}"
            alt="{{ designerName + ' logo' }}"
            data-bs-html="true"
            data-bs-toggle="popover"
            data-bs-trigger="hover focus"
            attr.data-bs-content="
                <div class='mb-3 text-center'>
                  <img src='{{ designerLogoUrl }}'/> 
                </div>              
                <p> 
                  For all your modern day responsive websites, web applications, desktop applications, 
                  mobile applications and custom software designs. 
                </p>
                <p class='text-center'><strong>You dream it, we bring it to life!</strong></p>
              "
            title="Jasper Devs"
          />
        </a>
      </span>
    </p>
  </div>
</footer>
