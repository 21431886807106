import { Injectable } from '@angular/core';

import { take } from 'rxjs/operators';
import { SyncErrorContext } from '../../global-models/sync-error-context.enum';

import { errorMessage } from '../../helpers/error-message';
import { IParameterValue } from '../../tracking/interfaces-enums/parameter-value';
import { AuthService } from './auth.service';
import { DataService } from './data.service';
import { IndexDbService } from './index-db.service';
import { ErrorLoggingService } from './logging.service';
import { NotificationService } from './notification.service';
import { PushNotificationService } from './push-notification.service';

/**
 * Provides background sync as a fallback for browsers that do not support it
 */
@Injectable()
export class NoBgSyncService {
  /**Flag indicating if there are unsynced Broadcasts in the indexedDb */
  pendingBroadcasts = false;

  constructor(
    private data: DataService,
    private db: IndexDbService,
    private notifier: NotificationService,
    private pushService: PushNotificationService,
    private errorLogger: ErrorLoggingService,
    private authService: AuthService,
  ) {}

  /**
   * Posts KPIs values and push notifications stored in IndexedDb
   */
  async updateKpi() {
    try {
      const parameterValues: IParameterValue[] = await this.db.getAll('parameterValues');
      let pushMessages = await this.db.getAll('pushMessages');

      const sendPushMessages = async (successMsg: string) => {
        try {
          if (pushMessages.length) {
            await this.pushService.send(pushMessages).pipe(take(1)).toPromise();
            // Push messages synced successfully
            await this.db.clearData('pushMessages');
          }
          this.notifier.showSuccess(successMsg);
        } catch (error) {
          this.handleSyncError(SyncErrorContext.messagesSync, errorMessage(error), false);
        }
      };

      if (parameterValues.length) {
        try {
          await this.data.updateKpi(parameterValues).pipe(take(1)).toPromise();
          // Param values synced successfully
          await this.db.clearData('parameterValues');
          this.pendingBroadcasts = false;
          const successMsg = 'Broadcasting successful';
          pushMessages.length ? await sendPushMessages(successMsg) : null;
        } catch (error) {
          return this.handleSyncError(SyncErrorContext.paramsSync, errorMessage(error), false);
        }
      } else {
        // No param values to send, but check if there are remanent push messages and try sending them
        pushMessages.length ? await sendPushMessages('Pending notifications send successfully') : null;

        // If still fails to send, abort and remove them from IndexedDb
        pushMessages = await this.db.getAll('pushMessages');
        pushMessages.length ? await this.db.clearData('pushMessages') : null;
        this.pendingBroadcasts = false;
        return;
      }
    } catch (error) {
      this.notifier.showError(errorMessage(error));
      this.errorLogger.logError(errorMessage(error));
    }
  }

  /**
   * Handles the reporting of sync errors
   *
   * This function centralises the reporting of sync error messages. The need arises due to two different
   * areas handling the syncing of data (e.g broadcasting messages), i.e for the browsers that
   * support background syncing and for those that don't. For those that support bgSync, the code runs in
   * the ServiceWorker whereas for the other, it runs within the browser. This raises some maintenance
   * challenges because of the need to do the same thing twice to cater for the two places the same code
   * is being run. This function minimise that by reporting errors from both fronts, thereby giving the
   * same UX for all users irregardless of the browser being used.
   *
   * @param context The context in which the error was raised
   * @param error The error message
   * @param isExternal Flag indicating whether this method is being called by another method in the same class
   * or from an external one
   */
  handleSyncError(context: SyncErrorContext, error: string, isExternal = true) {
    // If user is not logged in, there is no need to inform of him/her of sync failure; this will be most probably
    // due to an issue of an expired auth-token. The situation will be resolved when the user logs in and if not,
    // then will the user be informed of the error
    if (!this.authService.isLoggedIn) return;

    let message = '';
    switch (context) {
      case SyncErrorContext.paramsSync:
        message = `Your Broadcasts are safely saved but sync failed due to: ${errorMessage(
          error,
        )}. Will try later, or you can try to sync manually.`;
        this.notifier.showError(message);
        break;
      case SyncErrorContext.messagesSync:
        message = `Your update was sent to the database but broadcasting failed due to: ${errorMessage(error)}`;
        if (!isExternal) {
          this.notifier.showSuccess(message);
        } else {
          this.notifier.showError(message);
        }
        break;
      default:
        this.notifier.showError('A sync error happened');
        break;
    }
    this.errorLogger.logError(errorMessage(error));
  }
}
