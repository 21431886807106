import { Component } from '@angular/core';

import { PrintService } from '../../../utils/services/print.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ngx-trackables-print',
  templateUrl: './trackables-print.component.html',
  styleUrls: ['./trackables-print.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class TrackablesPrintComponent {
  trackables: any[] = [];

  constructor(private printService: PrintService) {
    this.trackables = this.printService.printData as any[];
  }
}
