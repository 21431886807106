import { AbstractControl } from '@angular/forms';

export const passwordMatch = (c: AbstractControl): { [key: string]: boolean } | null => {
  const password = c.get('password').value;
  const confirm = c.get('confirmPassword').value;
  if (password !== confirm) {
    return { passwordMatch: true };
  }
  return null;
};
