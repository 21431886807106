import {
  AfterViewInit,
  Component,
  ContentChild,
  Input,
  Renderer2,
} from '@angular/core';

import { ISiteProfile, ISocial } from '../..';
import { SocialComponentToken } from '../social/social.component';

/**Interface for properties expected in a ```footer``` element */
export interface IFooter {
  /**Footer base color. The main Footer color */
  footerBaseColor?: string;
  /**FooterTop color. Only necessary if ```footerTop = true``` */
  footerTopColor?: string;
  /**Flag whether to display Footer Top */
  footerTop?: boolean;
  /**FooterTop left heading */
  footerTopLeftHeader?: string;
  /**FooterTop right heading */
  footerTopRightHeader?: string;
  /**FooterTop logo URL */
  footerTopLogoUrl?: string;
  /**FooterTop left content */
  footerTopLeftParagraph?: string;
  /**FooterTop right content */
  footerTopRightParagraph?: string;
  /**Color for social icons */
  socialIconColor?: string;
  /**Flag whether to hide social links or not */
  hideSocial?: boolean;
}

@Component({
  selector: 'smx-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements AfterViewInit, IFooter {
  @Input() footerData!: Partial<ISiteProfile>;
  @ContentChild(SocialComponentToken) social: SocialComponentToken | null =
    null;

  socialProfile: ISocial | undefined;
  footerBaseColor: string | undefined = 'initial';
  footerTopColor: string | undefined = 'initial';
  footerTop: boolean | undefined = true;
  footerTopLeftHeader: string | undefined;
  footerTopRightHeader: string | undefined;
  footerTopLogoUrl: string | undefined;
  footerTopLeftParagraph: string | undefined;
  footerTopRightParagraph: string | undefined;
  hideSocial?: boolean | undefined;

  year: number;

  // Client details
  institutionFullName: string | undefined;
  mainContactNumber: string | undefined;
  mainContactEmail: string | undefined;

  // Designer details
  designerUrl: string | undefined;
  designerLogoUrl: string | undefined;
  designerName: string | undefined;

  // Legal
  tcsUrl: string | undefined;
  privacyUrl: string | undefined;
  eulaUrl: string | undefined;
  cookiesUrl: string | undefined;
  disclaimerUrl: string | undefined;

  constructor(private renderer: Renderer2) {
    this.year = new Date().getFullYear();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.socialProfile = this.footerData?.socialProfile;
      this.mainContactEmail = this.footerData?.mainContactEmail;
      this.mainContactNumber = this.footerData?.mainContactNumber;
      this.institutionFullName = this.footerData
        ? `${this.footerData?.institutionFullName}.`
        : '.';

      this.designerUrl = this.footerData?.developerDetails?.designerUrl;
      this.designerLogoUrl = this.footerData?.developerDetails?.designerLogoUrl;
      this.designerName = this.footerData?.developerDetails?.designerName;

      this.hideSocial = this.footerData?.footerDesign?.hideSocial;
      this.footerBaseColor = this.footerData?.footerDesign?.footerBaseColor;
      this.footerTopColor = this.footerData?.footerDesign?.footerTopColor;
      this.footerTop = this.footerData?.footerDesign?.footerTop;
      this.footerTopLeftHeader =
        this.footerData?.footerDesign?.footerTopLeftHeader;
      this.footerTopRightHeader =
        this.footerData?.footerDesign?.footerTopRightHeader;
      this.footerTopLogoUrl = this.footerData?.footerDesign?.footerTopLogoUrl;
      this.footerTopLeftParagraph =
        this.footerData?.footerDesign?.footerTopLeftParagraph;
      this.footerTopRightParagraph =
        this.footerData?.footerDesign?.footerTopRightParagraph;

      const rootElem = this.renderer.selectRootElement(
        'footer',
        true
      ) as HTMLElement;

      const footerTop = rootElem.querySelector('.footer-top') as HTMLElement;
      const footerBase = rootElem.querySelector('.footer-base') as HTMLElement;

      footerTop
        ? this.renderer.setStyle(
            footerTop,
            'backgroundColor',
            this.footerTopColor
          )
        : null;

      footerBase
        ? this.renderer.setStyle(
            footerBase,
            'backgroundColor',
            this.footerBaseColor
          )
        : null;
    });
  }

  goToTnC() {
    window.open(this.tcsUrl);
  }
  goToPrivacyPolicy() {
    window.open(this.privacyUrl);
  }
  goToEula() {
    window.open(this.eulaUrl);
  }
  goToCookiesPolicy() {
    window.open(this.cookiesUrl);
  }
  goToDisclaimer() {
    window.open(this.disclaimerUrl);
  }
}
