import { Injectable } from '@angular/core';
import { SwPush, SwUpdate } from '@angular/service-worker';

import { Observable, of } from 'rxjs';

import { errorMessage } from '../../helpers/error-message';
import { IndexDbService } from './index-db.service';
import { ErrorLoggingService } from './logging.service';
import { NoBgSyncService } from './no-sync.service';
import { NotificationService } from './notification.service';
import { SpinnerService } from './spinner.service';
import { StoreService } from './store.service';

@Injectable()
export class SwMasterService {
  /**
   * Flag indicating whether service-worker push-notifications is supported
   */
  swPushSupported: boolean;

  /**Flag emitting the update status */
  updateAvailable: Observable<boolean>;

  /**Flag indicating if the browser supports background syncing */
  private backgroundSyncSupported: boolean;

  constructor(
    public updates: SwUpdate,
    private swPush: SwPush,
    private indexedDb: IndexDbService,
    private noSync: NoBgSyncService,
    private errorLogger: ErrorLoggingService,
    private notifier: NotificationService,
    private spinner: SpinnerService,
    private store: StoreService,
  ) {
    this.swPushSupported = this.swPush.isEnabled;
    this.updateAvailable = of(false);
  }

  /**
   * Checks whether service-worker is supported
   */
  swSupported(): boolean {
    if (window.navigator.serviceWorker) return true;
    return false;
  }

  /**Installs updates and reloads the app */
  installUpdate() {
    this.updates.activateUpdate().then(() => document.location.reload());
  }

  /**
   * Gets the service-worker registration object
   *
   * @param clientURL Optional, service-worker url
   */
  async getRegistration(clientURL?: string): Promise<ServiceWorkerRegistration> {
    try {
      return await window.navigator.serviceWorker.getRegistration(clientURL);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Checks if background syncing is supported
   */
  async bgSyncSupported(): Promise<boolean> {
    try {
      if (this.backgroundSyncSupported !== undefined) {
        // Avoids running expensive async operations if the status is already known
        return this.backgroundSyncSupported;
      }

      if (this.swSupported() && (await this.getSyncManager())) {
        // console.log('Background sync supported!');
        this.backgroundSyncSupported = true;
        return true;
      }
      this.backgroundSyncSupported = false;
      return false;
    } catch (error) {
      console.error(error);
      this.backgroundSyncSupported = undefined;
      return false;
    }
  }

  /**
   * Gets the background sync manager
   *
   * @param clientURL Optional, url of the service-worker
   */
  async getSyncManager(clientURL?: string): Promise<any> {
    try {
      const reg = (await this.getRegistration(clientURL)) as any; // Silencing Typescript for experimental reg.sync used below
      return reg && reg.sync ? reg.sync : undefined; // reg.sync is experimental and not supported in some browsers hence the need to check for it's availability
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  /**
   * Makes a background-sync booking
   *
   * If background-syncing is not supported, it implements a fallback that imitates background syncing
   *
   * @param syncData An object with the following properties:
   * @property ```tag``` Booking identifier
   * @property ```payload``` The payload to be synced
   * @property ```tableName``` The ```IndexedDb``` table the sync will be executed on
   * @property ```token``` The bearer-token to access the backend API
   * @property ```pushMessages``` The messages to be broadcasted - optional
   */
  async registerBackgroundSync(syncData: any): Promise<void> {
    if (syncData.token) {
      // Store fresh access token in indexedDb
      await this.indexedDb.clearData('tokens');
      await this.indexedDb.addData({ token: syncData.token }, 'tokens');
    }

    // Store data into indexedDb
    await this.indexedDb.addData(syncData.payload, syncData.tableName);
    if (syncData.pushMessages) {
      await this.indexedDb.addData(syncData.pushMessages, 'pushMessages');
    }

    if (await this.bgSyncSupported()) {
      // Allow the stored data to be send through the native background sync mechanism
      try {
        const syncManager = await this.getSyncManager();
        await syncManager.register(syncData.tag);
        !this.store.isOnline ? this.notifier.showSuccess('Broadcast Booking Successful') : null;
      } catch (error) {
        this.notifier.showError(errorMessage(error));
        this.errorLogger.logError(errorMessage(error));
      }
      this.spinner.hide();
    } else {
      // Bg sync fallback. If app is online, retrive the stored data and send it manually.
      // If not online, just assure the user that we are good. Sending will be attempted
      // when the device is online.
      this.store.isOnline ? this.noSync.updateKpi() : this.notifier.showSuccess('Broadcast Booking Successful');
      this.spinner.hide();
    }
  }

  /**
   * Subscribes to Web Push Notifications, after requesting and receiving user permission.
   *
   * @param options An object containing the ```serverPublicKey``` string.
   * @returns A Promise that resolves to the new subscription object.
   */
  async requestSubscription(options: { serverPublicKey: string }): Promise<any> {
    return await this.swPush.requestSubscription(options);
  }

  /**
   * Unsubscribes from Service Worker push notifications.
   *
   * @returns A Promise that is resolved when the operation succeeds, or is rejected
   * if there is no active subscription or the unsubscribe operation fails.
   */
  async unsubscribe(): Promise<void> {
    return await this.swPush.unsubscribe();
  }

  /**Emits the payloads of the received push notification messages.*/
  pushMessages(): Observable<any> {
    return this.swPush.messages;
  }

  /**
   * Emits the payloads of the received push notification messages as well
   * as the action the user interacted with. If no action was used the action
   * property contains an empty string ```''```.
   *
   * Note that the notification property does not contain a ```Notification``` object
   * but rather a ```NotificationOptions``` object that also includes the title of the
   * ```Notification``` object.
   */
  notificationClicks(): Observable<{
    action: string;
    notification: NotificationOptions & {
      title: string;
    };
  }> {
    return this.swPush.notificationClicks;
  }

  /**
   * Emits the currently active ```PushSubscription``` associated to the Service Worker registration
   * or ```null``` if there is no subscription.
   */
  getPushSubscription(): Observable<PushSubscription> {
    return this.swPush.subscription;
  }
}
