import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { catchError, retry, take, tap } from 'rxjs/operators';

import { IBundle } from '../../global-models/bundle.interface';
import { errorMessage } from '../../helpers/error-message';
import { Constants } from '../../constants';
import { ErrorLoggingService } from './logging.service';
import { NotificationService } from './notification.service';
import { StoreService } from './store.service';
import { IndexDbService } from './index-db.service';

@Injectable({
  providedIn: 'root',
})
export class BundlesService {
  private trackingApiRoot = Constants.trackingApiRoot;
  private bundlesPath = Constants.bundlesPath;
  private account: string;
  private owner: string;
  private dbActiveBundle: IBundle;
  private dbBundles: IBundle[];
  private backend = Constants.backendUrl;

  constructor(
    private errorLogger: ErrorLoggingService,
    private notifier: NotificationService,
    private http: HttpClient,
    private store: StoreService,
    private db: IndexDbService,
  ) {
    this.store.account$.subscribe(account => {
      if (account) {
        this.account = account;
      }
    });

    this.store.user$.subscribe(user => {
      if (user) {
        this.owner = user;
      }
    });

    this.store.activeBundle$.subscribe(bundle => {
      if (bundle) {
        this.dbActiveBundle = bundle;
      }
    });

    this.store.bundles$.subscribe(bundles => {
      if (bundles) {
        this.dbBundles = bundles;
      }
    });
  }

  /**
   * Fetches the available bundles from the backend
   */
  getBundles(): Observable<IBundle[]> {
    let fromIndexedDb = false;
    return this.http.get<IBundle[]>(`${this.backend}/${this.bundlesPath}/get`).pipe(
      retry(!this.dbBundles && !this.store.isOnline ? 50 : 2),
      catchError(error => {
        if (this.dbBundles && error instanceof HttpErrorResponse && (error.status === 0 || error.status === 504)) {
          fromIndexedDb = true;
          return of(this.dbBundles);
        }
        throw error;
      }),
      tap(bundles => {
        if (!fromIndexedDb && this.account && this.owner) {
          if (bundles) {
            const userBundles = bundles.map(bndl =>
              Object.assign({}, bndl, { account: this.account, owner: this.owner }),
            );
            this.db.bundles
              .where('account')
              .equals(this.account)
              .and(bndl => bndl.owner === this.owner)
              .delete()
              .then(() => {
                this.db.bundles
                  .bulkAdd(userBundles)
                  .then(_ => {
                    this.db.bundles
                      .where('account')
                      .equals(this.account)
                      .and(bndl => bndl.owner === this.owner)
                      .toArray()
                      .then(bndls => (this.store.bundles = bndls))
                      .catch(error => {
                        this.notifier.showError(errorMessage(error));
                      });
                  })
                  .catch(error => {
                    this.notifier.showError(errorMessage(error));
                  });
              })
              .catch(error => {
                this.notifier.showError(errorMessage(error));
              });
          }
        }
        fromIndexedDb = false;
      }),
    );
  }

  /**
   * Fetches the active bundle from the backend
   */
  getActiveBundle(): Observable<IBundle> {
    let fromIndexedDb = false;
    return this.http.get<IBundle>(`${this.trackingApiRoot}/${this.bundlesPath}/active-bundle`).pipe(
      retry(!this.dbActiveBundle && !this.store.isOnline ? 50 : 2),
      catchError(error => {
        if (this.dbActiveBundle && error instanceof HttpErrorResponse && (error.status === 0 || error.status === 504)) {
          fromIndexedDb = true;
          return of(this.dbActiveBundle);
        }
        throw error;
      }),
      tap(async bundle => {
        if (!fromIndexedDb && this.account && this.owner) {
          if (bundle.name) {
            this.db.activeBundle
              .where('account')
              .equals(this.account)
              .and(bndl => bndl.owner === this.owner)
              .delete()
              .then(() => {
                this.db.activeBundle
                  .add(Object.assign({}, bundle, { account: this.account, owner: this.owner }))
                  .then(value => {
                    this.db.activeBundle
                      .where('account')
                      .equals(this.account)
                      .and(bndl => bndl.owner === this.owner)
                      .first()
                      .then(bndl => (this.store.activeBundle = bndl))
                      .catch(error => {
                        this.notifier.showError(errorMessage(error));
                      });
                  })
                  .catch(error => {
                    this.notifier.showError(errorMessage(error));
                  });
              })
              .catch(error => {
                this.notifier.showError(errorMessage(error));
              });
          }
        }
        fromIndexedDb = false;
      }),
    );
  }

  /**
   * Fetches the specified bundle from the backend
   */
  async getBundle(id: string) {
    try {
      return await this.fetchBundle(id).pipe(take(1)).toPromise();
    } catch (error) {
      this.notifier.showError(errorMessage(error));
      this.errorLogger.logError(errorMessage(error));
    }
  }

  private fetchBundle(id: string) {
    return this.http.get<any>(`${this.trackingApiRoot}/${this.bundlesPath}/get/${id}`);
  }
}
