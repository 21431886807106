import { Component, EventEmitter, Input, Output } from '@angular/core';

interface IUser {
  firstName: string;
  lastName: string;
  isLoggedIn: boolean;
}

@Component({
  selector: 'smx-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent {
  @Input() user!: IUser;
  @Output() logoutRequest = new EventEmitter(false);
  @Output() loginRequest = new EventEmitter(false);
  @Output() viewUserProfileRequest = new EventEmitter(false);

  logout() {
    this.logoutRequest.emit(true);
  }

  login() {
    this.loginRequest.emit(true);
  }

  userProfile() {
    this.viewUserProfileRequest.emit(true);
  }
}
