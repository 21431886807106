import { Injectable } from '@angular/core';
import { NbMediaBreakpointsService, NbThemeService } from '@nebular/theme';
import { BehaviorSubject, Subject, map, takeUntil, tap } from 'rxjs';

/**
 * Gives specific information about the user's device
 */
@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private destroy$: Subject<void> = new Subject<void>();
  networkChange$: BehaviorSubject<boolean>;
  /**Emits viewport size */
  viewportSize$: BehaviorSubject<'xs' | 'sm' | 'md' | 'lg' | 'xl'>;

  /**
   * Returns the number of logical processors available to run threads on the user's device
   * Defaults to ```0``` if number of cores can't be determined
   *
   * Modern computers have multiple physical processor cores in their
   * CPU (two or four cores is typical), but each physical core is also usually able to
   * run more than one thread at a time using advanced scheduling techniques. So a
   * four-core CPU may offer eight logical processor cores, for example. The number of
   * logical processor cores can be used to measure the number of threads which can
   * effectively be run at once without them having to context switch.
   * The browser may, however, choose to report a lower number of logical cores in order
   * to represent more accurately the number of Workers that can run at once, so don't
   * treat this as an absolute measurement of the number of cores in the user's system.
   */
  get cores(): number {
    const cores = navigator.hardwareConcurrency;
    return cores ? cores : 0;
  }

  /**
   * Returns the speed of the connection.
   *
   * This value is determined using a combination of recently observed round-trip time
   * and downlink values.
   */
  get networkSpeed(): 'slow' | 'medium' | 'fast' | 'unknown' {
    const connection: any = (navigator as any).connection; // Typescript doesn't know connection on navigator
    const speed = connection
      ? connection.effectiveType
        ? connection.effectiveType
        : 'unknown'
      : 'unknown';
    let status: 'slow' | 'medium' | 'fast' | 'unknown';
    switch (speed) {
      case '4g':
        status = 'fast';
        break;
      case '3g':
        status = 'medium';
        break;
      case 'unknown':
        status = 'unknown';
        break;
      default:
        status = 'slow';
        break;
    }
    return status;
  }

  private connection = (navigator as any).connection; // Typescript doesn't know connection on navigator
  constructor(
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService
  ) {
    this.networkChange$ = new BehaviorSubject(false);
    (navigator as any).connection // Still experimental and not recognized by Typescript
      ? (navigator as any).connection.addEventListener('change', () => {
          this.networkChange();
        })
      : null;

    this.themeService
      .onMediaQueryChange()
      .pipe(
        tap(([, currentBreakpoint]) => {
          const currentWidth = currentBreakpoint.width;
          this.setScreenSize(currentWidth);
        }),

        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Gives an overall rating of device performance and network speed
   */
  getOverallPerformance(): 'low' | 'medium' | 'high' | 'unknown' {
    let status: 'low' | 'medium' | 'high' | 'unknown';
    const cores = this.cores;
    const network = this.networkSpeed;
    if (network === 'slow' || cores < 4) {
      status = 'low';
    } else if (network === 'medium' || (cores >= 4 && cores < 8)) {
      status = 'medium';
    } else if (network === 'fast' || cores >= 8) {
      status = 'high';
    } else {
      status = 'unknown';
    }
    return status;
  }

  private networkChange() {
    this.networkChange$.next(true);
  }

  private setScreenSize(currentWidth) {
    const { sm, md, lg, xl } = this.breakpointService.getBreakpointsMap();
    if (currentWidth <= sm) {
      this.emitViewportSize('xs');
    } else if (currentWidth <= md) {
      this.emitViewportSize('sm');
    } else if (currentWidth <= lg) {
      this.emitViewportSize('md');
    } else if (currentWidth <= xl) {
      this.emitViewportSize('lg');
    } else {
      this.emitViewportSize('xl');
    }
  }

  private emitViewportSize(size: 'xs' | 'sm' | 'md' | 'lg' | 'xl') {
    if (!this.viewportSize$) {
      this.viewportSize$ = new BehaviorSubject(size);
    } else {
      this.viewportSize$.next(size);
    }
  }
}
