import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { Subscription } from 'rxjs';

/**Confirm dialog settings */
export interface IConfirmDialog {
  /**Dialog header */
  header?: string;
  /**Dialog body */
  body?: string;
  /**Label of cancel button */
  cancelBtnLabel?: string;
  /**Label of submit button */
  submitBtnLabel?: string;
}

@Component({
  selector: 'smx-nb-confirm-dialog',
  templateUrl: 'nb-dialog.component.html',
  styleUrls: ['nb-dialog.component.scss'],
})
export class NbConfirmDialogComponent
  implements AfterViewInit, OnDestroy, IConfirmDialog
{
  @ViewChild(TemplateRef) dialog!: TemplateRef<any>;
  @Input() header = 'Confirm';
  @Input() body = 'Are you sure?';
  @Input() cancelBtnLabel = 'Cancel';
  @Input() submitBtnLabel = 'Yes';
  @Input() closeOnBackdropClick = true;
  @Input() closeOnEsc = true;
  @Output() confirmed: EventEmitter<boolean> = new EventEmitter();
  @Output() closed: EventEmitter<boolean> = new EventEmitter();

  subscriptions = new Subscription();

  constructor(private dialogService: NbDialogService) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.dialog) {
        this.openDialog(this.dialog);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  submit() {
    this.confirmed.emit(true);
  }

  cancel() {
    this.confirmed.emit(false);
  }

  private openDialog(dialog: TemplateRef<any>) {
    const ref = this.dialogService.open(dialog, {
      context: {
        header: this.header,
        body: this.body,
        cancelBtnLabel: this.cancelBtnLabel,
        submitBtnLabel: this.submitBtnLabel,
      },
      closeOnBackdropClick: this.closeOnBackdropClick,
      closeOnEsc: this.closeOnEsc,
      autoFocus: true,
    });

    const sub = ref.onClose.subscribe({
      next: () => {
        this.closed.emit(true);
      },
    });

    this.subscriptions.add(sub);
  }
}
