import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { NbAuthService, NbAuthToken, NbTokenService } from '@nebular/auth';

@Injectable({ providedIn: 'root' })
export class NbGlobalHttpInterceptor implements HttpInterceptor {
  private authService!: NbAuthService;
  private tokenService!: NbTokenService;
  constructor(private injector: Injector) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.authService = this.injector.get<NbAuthService>(NbAuthService);
    this.tokenService = this.injector.get<NbTokenService>(NbTokenService);

    if (req.url.includes('/logout')) {
      this.tokenService.clear();
    }

    return this.authService.isAuthenticated().pipe(
      switchMap((authenticated) => {
        if (authenticated) {
          return this.authService.getToken().pipe(
            switchMap((token: NbAuthToken) => {
              const JWT = `Bearer ${token.getValue()}`;
              req = req.clone({
                setHeaders: {
                  Authorization: JWT,
                },
              });

              return next.handle(req);
            })
          );
        } else {
          // Request is sent to server without authentication so that the client code
          // receives the 401/403 error and can act as desired ('session expired', redirect to login, aso)
          return next.handle(req);
        }
      })
    );
  }
}
