import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';

import { take } from 'rxjs/operators';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { PayService } from '../../core/services/pay.service';
import { IMobile } from '../../global-models/mobile';
import { SpinnerService } from '../../core/services/spinner.service';
import { NotificationService } from '../../core/services/notification.service';
import { BackdropManagerService } from '../../core/services/backdrop-manager.service';
import { StoreService } from '../../core/services/store.service';
import { ErrorLoggingService } from '../../core/services/logging.service';
import { errorMessage } from '../../helpers/error-message';
import { DataService } from '../../core/services/data.service';
import { IExchangeRate } from '../../tracking/interfaces-enums/exchange-rate';
import { Constants } from '../../constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss'],
})
export class MakePaymentComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('paypalBtnContainer') paypalWrapper: ElementRef<HTMLDivElement>;
  faArrowLeft = faArrowLeft;
  pricingForm: UntypedFormGroup;
  isEcocash: boolean;
  isOnemoney: boolean;
  isOnline: boolean;

  private subscriptions = new Subscription();

  constructor(
    private payService: PayService,
    private fb: UntypedFormBuilder,
    private spinner: SpinnerService,
    private notifier: NotificationService,
    private backdrop: BackdropManagerService,
    private store: StoreService,
    private router: Router,
    private errorLogger: ErrorLoggingService,
    private route: ActivatedRoute,
    private dataService: DataService
  ) {
    const sub = this.store.isOnline$.subscribe(
      (online) => {
        if (
          online &&
          this.paypalWrapper &&
          !this.paypalWrapper.nativeElement.children.length
        ) {
          this.initialisePaypalSmartButtons();
        } else {
          online &&
          this.paypalWrapper &&
          !this.paypalWrapper.nativeElement.children.length
            ? window.setTimeout(() => {
                this.store.isOnline$.next(online);
              }, 1000)
            : null;
        }
        this.isOnline = online;
      },
      (error) => console.error(error)
    );
    this.subscriptions.add(sub);
  }

  async ngOnInit(): Promise<void> {
    try {
      this.initialiseForm();
      this.listenForFormChanges();
      this.updateForm('initial');
    } catch (error) {
      this.spinner.hide();
      this.notifier.showError(errorMessage(error));
      this.errorLogger.logError(errorMessage(error));
    }
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  detectMobile(mobile: string) {
    this.payService.mobileProvider = mobile;
    if (mobile === 'ecocash') {
      this.isEcocash = true;
      this.isOnemoney = false;
    } else if (mobile === 'onemoney') {
      this.isEcocash = false;
      this.isOnemoney = true;
    }
  }

  async payMobile(formData: any) {
    try {
      const payload: IMobile = {
        number: formData.name,
        provider: this.payService.mobileProvider,
        terms: this.payService.terms,
        bundleName: this.payService.bundleName,
      };
      this.spinner.show(true);
      const response = await this.payService
        .payMobile(payload)
        .pipe(take(1))
        .toPromise();
      this.spinner.hide();
      const pollUrl = response.pollUrl;
      const data: IMobile = Object.assign({}, payload, { pollUrl });
      this.payService.mobileData = data;
      this.showCheckPaymentDialog();
    } catch (error) {
      this.spinner.hide();
      this.notifier.showError(errorMessage(error));
      this.errorLogger.logError(errorMessage(error));
    }
  }

  async checkMobilePayment() {
    try {
      this.spinner.show(true);
      const response = await this.payService
        .checkMobilePayment(this.payService.mobileData)
        .pipe(take(1))
        .toPromise();
      this.spinner.hide();
      this.notifier.showSuccess(response.message);
      this.hideCheckPaymentDialog();
      await this.dataService.setAccountDetails();
      this.goToManage();
      this.store.appExpired;
    } catch (error) {
      this.spinner.hide();
      this.notifier.showError(errorMessage(error));
      this.errorLogger.logError(errorMessage(error));
    }
  }

  abortPayment() {
    this.hideCheckPaymentDialog();
  }

  goToManage() {
    this.router.navigate(['/admin/account/manage-account']);
  }

  private showCheckPaymentDialog() {
    this.toggleDialog();
    this.lockChanges();
  }

  private hideCheckPaymentDialog() {
    this.toggleDialog();
    this.unlockChanges();
  }

  private toggleDialog() {
    document.querySelector('#check-payment').classList.toggle('hide');
  }

  private lockChanges() {
    document
      .querySelectorAll('.pay-button')
      .forEach((btn) => btn.setAttribute('disabled', 'true'));
    document.querySelector('#terms').setAttribute('disabled', 'true');
  }

  private unlockChanges() {
    document
      .querySelectorAll('.pay-button')
      .forEach((btn) => btn.removeAttribute('disabled'));
    document.querySelector('#terms').removeAttribute('disabled');
    this.backdrop.hide();
  }

  private initialisePaypalSmartButtons() {
    const user = `oidc.user:${Constants.stsAuthority}:${Constants.clientId}`; // ghhh
    const paypalSdk = window['paypal_sdk'];
    if (paypalSdk) {
      paypalSdk
        .Buttons({
          createOrder: async () => {
            try {
              const terms = this.payService.terms;
              const bundleName = this.payService.bundleName;
              const res = await fetch(
                `${Constants.trackingApiRoot}/${Constants.paypalPath}/orders/create`,
                {
                  method: 'post',
                  headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${
                      JSON.parse(localStorage.getItem(user)).access_token
                    }`,
                  },
                  body: JSON.stringify({ terms, bundleName }),
                }
              );

              const result = await res.json();
              if (res.ok) {
                return result.orderID;
              } else {
                this.notifier.showError(result.error);
              }
            } catch (error) {
              this.notifier.showError(errorMessage(error));
            }
          },
          onApprove: async (data) => {
            try {
              this.spinner.show();
              const terms = this.payService.terms;
              const bundleName = this.payService.bundleName;
              const orderID = data.orderID;
              const res = await fetch(
                `${Constants.trackingApiRoot}/${Constants.paypalPath}/orders/capture`,
                {
                  method: 'post',
                  headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${
                      JSON.parse(localStorage.getItem(user)).access_token
                    }`,
                  },
                  body: JSON.stringify({ orderID, terms, bundleName }),
                }
              );

              if (res.ok) {
                this.notifier.showSuccess('Thank you for your payment');
                await this.dataService.setAccountDetails();
                this.goToManage();
                this.store.appExpired;
              } else {
                const payload = await res.json();
                this.notifier.showError(payload.error);
                this.spinner.hide();
              }
            } catch (error) {
              this.notifier.showError(errorMessage(error));
            }
          },
        })
        .render('#paypal-button-container');
    }
  }

  private listenForFormChanges() {
    // Capture the intial terms and selected bundle on page load
    const termsField = this.pricingForm.get('terms');
    const bundleField = this.pricingForm.get('bundleName');
    this.payService.terms = +termsField.value;

    // Capture the subsequent changes that are effected by the user
    termsField.valueChanges.subscribe(
      (value) => {
        this.payService.terms = +termsField.value;
        this.updateForm('terms');
      },
      (error) => {
        this.notifier.showError(errorMessage(error));
        this.errorLogger.logError(errorMessage(error));
      }
    );

    bundleField.valueChanges.subscribe(
      async (value) => {
        this.payService.bundleName = value;
        this.updateForm('bundleName');
      },
      (error) => {
        this.notifier.showError(errorMessage(error));
        this.errorLogger.logError(errorMessage(error));
      }
    );
  }

  private async updateForm(caller: string) {
    try {
      const terms = Number(this.pricingForm.get('terms').value);
      const bundleName = this.pricingForm.get('bundleName').value;
      const exchangeRate = (
        this.route.snapshot.data.exchangeRate as IExchangeRate
      ).rate;
      let usdTotal: number;
      switch (caller) {
        case 'initial':
          // Page is in initial loading
          const { price } = await this.getActiveBundle();
          usdTotal = price * terms;
          this.pricingForm.patchValue({
            bundleName: this.store.activeBundle.name,
            usd: usdTotal,
            zwd: usdTotal * exchangeRate,
          });
          break;
        case 'bundleName':
        case 'terms':
        default:
          let bundles = this.store.bundles;
          if (!bundles) {
            const data = await this.route.parent.data.pipe(take(1)).toPromise();
            bundles = data.bundles;
          }

          const selectedBundle = bundles.find(
            (bndl) => bndl.name === bundleName
          );
          usdTotal = bundles ? selectedBundle.price * terms : null;
          this.pricingForm.patchValue({
            usd: usdTotal,
            zwd: usdTotal * exchangeRate,
          });
          break;
      }
    } catch (error) {
      this.notifier.showError(errorMessage(error));
      this.errorLogger.logError(errorMessage(error));
    }
  }

  private async getActiveBundle() {
    try {
      const storeActiveBundle = this.store.activeBundle;
      if (!storeActiveBundle) {
        const data = await this.route.parent.data.pipe(take(1)).toPromise();
        this.store.activeBundle = data.activeBundle;
      }
      if (!this.store.activeBundle) {
        throw Error(
          'Failed to locate your active bundle. Check your internet connection and try again'
        );
      }
      return this.store.activeBundle;
    } catch (error) {
      throw Error(errorMessage(error));
    }
  }

  private initialiseForm() {
    this.pricingForm = this.fb.group({
      bundleName: ['basic', [Validators.required]],
      terms: ['1', [Validators.required]],
      usd: [null, [Validators.required]],
      zwd: [null, [Validators.required]],
    });
  }
}
