import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(value: string): string {
    return value
      .replace(/\(/, '')
      .replace(/\)/, '')
      .replace(/\s/, '')
      .replace(/-/, '')
      .replace(/-/, '');
  }
}
