import { AbstractControl } from '@angular/forms';

export const emailDot = (c: AbstractControl): { [key: string]: boolean } | null => {
  const value = c.value as string;
  const pattern = /[.]+/;
  if (!pattern.test(value)) {
    return { email: true };
  }
  if (value.lastIndexOf('.') < value.lastIndexOf('@')) {
    return { email: true };
  }
  return null;
};
