import { Injectable } from '@angular/core';

import moment from 'moment';

@Injectable()
export class DateService {
  constructor() {}

  /**
   * Returns today's date
   *
   * @param format The format to out put the date. Defaults to ```YYYY-MM-DD```
   */
  today(format = 'YYYY-MM-DD'): string {
    return moment(undefined, true).format(format);
  }

  /**
   * Gleans the specified date part from a date string
   *
   * @param date Date string such as ```YYYY-MM-DD``` or any of IETF-compliant RFC 2822 timestamps plus strings in a version of ISO8601
   * @param format The format to out put the date part. Defaults to ```YYYY-MM-DD```
   */
  getDatePart(date: string, format = 'YYYY-MM-DD'): string {
    return moment(date, true).format(format);
  }

  /**
   * Adds days to a date, returning the the final date
   *
   * @param days Number of days to add to the date
   * @param date Start date in the format ```YYYY-MM-DD```, defaults to today's date
   * @returns A date string in the format ```YYYY-MM-DD```
   */
  addDate(days: number, date?: string): string {
    const suffix = days === 1 ? 'day' : 'days';
    const startDate = date !== undefined ? date : this.today();
    return moment(startDate, 'YYYY-MM-DD').add(days, suffix).format('YYYY-MM-DD');
  }

  /**
   * Subtracts days from a date, returning the final date
   *
   * @param timeValue Time value to subtract from the date
   * @param date Start date in the format ```YYYY-MM-DD```,or any of IETF-compliant RFC 2822 timestamps date formats plus strings in a version of ISO8601, defaults to today's date
   * @param unitOfTime ```timeValue``` units. Defaults to ```days```
   * @param inputFormat The format in which the ```date``` is in. Defaults to ```YYYY-MM-DD```
   * @param outputFormat The format in which to output the final date. Defaults to ```YYYY-MM-DD```
   * @returns A date string in the specified format. Defaults to ```YYYY-MM-DD```
   */
  subtractDate(
    timeValue: number,
    date?: string,
    unitOfTime?: moment.unitOfTime.DurationConstructor,
    inputFormat = 'YYYY-MM-DD',
    outputFormart = 'YYYY-MM-DD',
  ): string {
    const suffix = unitOfTime ? unitOfTime : 'days';
    const startDate = date !== undefined ? date : this.today();
    return moment(startDate, inputFormat).subtract(timeValue, suffix).format(outputFormart);
  }

  /**
   * Calculates the difference between 2 dates
   *
   * @param startDate Start date in the format ```YYYY-MM-DD```
   * @param endDate End date in the format ```YYYY-MM-DD```, defaults to today's date
   * @param units The units to use for the difference. Defaults to ```days```
   * @returns The difference between the given dates in the specified ```units```
   */
  difference(startDate: string, endDate?: string, units?: moment.unitOfTime.Diff): number {
    const end = endDate ? moment(endDate) : moment(this.today());
    const u = units ? units : 'days';
    const start = moment(startDate);
    return end.diff(start, u);
  }

  /**
   * Gives the start of a given time
   *
   * @param date Date string or any of IETF-compliant RFC 2822 timestamps plus strings in a version of ISO8601
   * @param unitOfTime Unit of time e.g. ```year```. Defaults to ```day```
   * @param format Output format. Defaults to ```YYYY-MM-DDTHH:mm:ss.SSS```
   * @returns Date string in the specified format. Defaults to ```YYYY-MM-DDTHH:mm:ss.SSS```
   */
  startOfTime(date = this.today(), unitOfTime: moment.unitOfTime.StartOf = 'day', format = 'YYYY-MM-DDTHH:mm:ss.SSS') {
    return moment(date).startOf(unitOfTime).format(format);
  }

  /**
   * Gives the end of a given time
   *
   * @param date Date string or any of IETF-compliant RFC 2822 timestamps plus strings in a version of ISO8601
   * @param unitOfTime Unit of time e.g. ```year```. Defaults to ```day```
   * @param format Output format. Defaults to ```YYYY-MM-DDTHH:mm:ss.SSS```
   * @returns Date string in the specified format. Defaults to ```YYYY-MM-DDTHH:mm:ss.SSS```
   */
  endOfTime(date = this.today(), unitOfTime: moment.unitOfTime.StartOf = 'day', format = 'YYYY-MM-DDTHH:mm:ss.SSS') {
    return moment(date).endOf(unitOfTime).format(format);
  }

  /**
   * Converts Unix time to any other date format
   *
   * @param num Unix time in seconds
   * @param format Output format. Defaults to ```YYYY-MM-DD```
   */
  fromUnix(num: number, format = 'YYYY-MM-DD') {
    return moment(num, 'X').format(format);
  }

  /**
   * Converts a date string to Unix time
   *
   * @param date Date string such as ```YYYY-MM-DD``` or any of IETF-compliant RFC 2822 timestamps plus strings in a version of ISO8601
   */
  toUnix(date: string | number) {
    return +moment(date).format('X');
  }

  /**
   * Mutates the given date interval to that of the previous day
   *
   * @param startDate Interval starting date in the format ```YYYY-MM-DD```
   * @param endDate Interval ending date in the format ```YYYY-MM-DD```
   */
  previousDayInterval(startDate: string, endDate: string) {
    endDate = this.subtractDate(1, endDate);
    startDate = this.subtractDate(1, startDate);
    const offset = this.difference(endDate, this.today());
    return { startDate, endDate, offset };
  }

  /**
   * Initialises a date interval
   *
   * @param endDate Interval ending date in the format ```YYYY-MM-DD```. Defaults to today's date.
   * @param interval The difference, in ```days``` between ```startDate``` and ```endDate```. Defaults to 30 days.
   * @param startingDate Interval starting date in the format ```YYYY-MM-DD``` or any of IETF-compliant RFC 2822 timestamps plus strings in a version of ISO8601. Defaults to ```endDate - interval```.
   */
  initialiseInterval(endDate = this.today(), interval = 30, startingDate?: string) {
    const startDate = startingDate ? startingDate : this.subtractDate(interval, endDate);
    const offset = this.difference(endDate, this.today());
    return { startDate, endDate, offset };
  }
}
