<nb-card>
  <nb-card-header class="d-flex">
    <h1 class="page-heading" id="page-title">Team</h1>
    <span *ngIf="false; else actions"></span>
    <ng-template #actions>
      <nb-actions class="ms-auto">
        <!-- <nb-action
          (click)="goToManage()"
          title="Go to Manage Account"
          icon="arrow-back-outline"
          nbTooltipIcon="arrow-back-outline"
          nbTooltip="Go to Manage Account"
        ></nb-action> -->
        <nb-action
          [routerLink]="['./0/edit']"
          title="Add Team Member"
          mat-raised-button
          *ngIf="'level-5' | restrict"
          icon="plus-outline"
          nbTooltipIcon="plus-outline"
          nbTooltip="Add Team Member"
        ></nb-action>
      </nb-actions>
    </ng-template>
  </nb-card-header>
  <nb-card-body class="container mx-0 py-4 wrapper">
    <div class="arranger mb-3" id="team">
      <div
        class="p-0 m-2 flex-item member"
        id="{{ 'member' + i }}"
        *ngFor="let member of team; let i = index"
      >
        <app-member
          (formData)="deleteMember($event)"
          [index]="i"
          [member]="member"
        ></app-member>
      </div>
      <div class="card placeholder" *ngIf="!team?.length">
        <p>Ummm, looks like you haven't set up your Winning Team yet!</p>
        <p>Click the ADD button to add members to your team.</p>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer class="d-flex">
    <span *ngIf="false; else actions"></span>
  </nb-card-footer>
</nb-card>
