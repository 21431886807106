import { Component } from '@angular/core';
import { ProfileService } from '../../../utils/services/profile.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'ngx-print-layout',
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class PrintLayoutComponent {
  generationDate = `Document generated on ${new Date().toLocaleString()}`;
  contactDetails!: string[];
  constructor(public profileService: ProfileService) {
    this.contactDetails = profileService.streetAddress.concat([
      profileService.mainContactNumber || '',
      profileService.mainContactEmail || '',
      profileService.websiteUrl || '',
    ]);
  }
}
