<table>
  <thead>
    <tr>
      <td>
        <div class="header-space">&nbsp;</div>
      </td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <div class="content">
          <router-outlet></router-outlet>
        </div>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td>
        <div class="footer-space">&nbsp;</div>
      </td>
    </tr>
  </tfoot>
</table>

<div class="header">
  <nav class="navbar navbar-light bg-light d-flex justify-content-between">
    <div class="d-inline-block">
      <a class="navbar-brand" href="#">
        <img
          [src]="profileService.headerDesign.headerLogoUrl"
          alt="logo"
          height="75"
        />
      </a>
    </div>
    <div class="d-inline-block contacts">
      <p class="text-end my-0" *ngFor="let segment of contactDetails">
        {{ segment }}
      </p>
    </div>
  </nav>
</div>

<div class="footer">
  {{ generationDate }}
</div>
