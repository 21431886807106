import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { StyleClassModule } from 'primeng/styleclass';
import { FileUploadModule } from 'primeng/fileupload';
import { ButtonModule } from 'primeng/button';
import { SpeedDialModule } from 'primeng/speeddial';
import { RippleModule } from 'primeng/ripple';
import { PaginatorModule } from 'primeng/paginator';
import { MultiSelectModule } from 'primeng/multiselect';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {
  NbSidebarModule,
  NbMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbWindowModule,
  NbToastrModule,
  NbCardModule,
  NbLayoutModule,
  NbIconModule,
  NbButtonModule,
  NbActionsModule,
  NbTooltipModule,
} from '@nebular/theme';

import { PhonePipe } from './pipes/phone.pipe';

@NgModule({
  declarations: [PhonePipe],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    FontAwesomeModule,
    HttpClientModule,
    MatRippleModule,
    MatSidenavModule,
    MatMenuModule,
    NbLayoutModule,
    NbSidebarModule,
    NbMenuModule,
    NbDatepickerModule,
    NbDialogModule,
    NbWindowModule,
    NbToastrModule,
    NbTooltipModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NbActionsModule,
    TableModule,
    ToolbarModule,
    ButtonModule,
    StyleClassModule,
    FileUploadModule,
    SpeedDialModule,
    RippleModule,
    MultiSelectModule,
    PaginatorModule,
    SplitButtonModule,
    InputTextModule,
    ToastModule,
    MatSnackBarModule,
  ],
  exports: [
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    FontAwesomeModule,
    HttpClientModule,
    MatRippleModule,
    MatSidenavModule,
    MatMenuModule,
    CommonModule,
    RouterModule,
    PhonePipe,
    NbLayoutModule,
    NbSidebarModule,
    NbMenuModule,
    NbDatepickerModule,
    NbDialogModule,
    NbWindowModule,
    NbToastrModule,
    NbTooltipModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NbActionsModule,
    TableModule,
    ToolbarModule,
    StyleClassModule,
    FileUploadModule,
    ButtonModule,
    SpeedDialModule,
    RippleModule,
    MultiSelectModule,
    PaginatorModule,
    SplitButtonModule,
    InputTextModule,
    ToastModule,
    MatSnackBarModule,
  ],
})
export class SharedModule {}
