import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { take } from 'rxjs/operators';

import { emailDot } from '../../core/validators/email.validator';
import { digit } from '../../core/validators/digit.validator';
import { NotificationService } from '../../core/services/notification.service';
import { specialCharacter } from '../../core/validators/special-character.validator';
import { SpinnerService } from '../../core/services/spinner.service';
import { TeamService } from '../../core/services/team.service';
import { ValidationErrorCheckService } from '../../core/services/validation-error-check.service';
import { ITeamMember } from '../../global-models/team-member';
import { ErrorLoggingService } from '../../core/services/logging.service';
import { Subscription } from 'rxjs';
import { errorMessage } from '../../helpers/error-message';
import { StoreService } from '../../core/services/store.service';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss'],
})
export class AddMemberComponent implements OnInit, OnDestroy {
  teamMemberForm: UntypedFormGroup;
  formErrors: any;
  id: string;
  isLoading = false;
  member: ITeamMember;
  title: string;
  isNewMember: boolean;
  nextRoute: string;

  private subscriptions = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private errorCheck: ValidationErrorCheckService,
    private router: Router,
    private spinner: SpinnerService,
    private notifier: NotificationService,
    private teamService: TeamService,
    private route: ActivatedRoute,
    private erroLogger: ErrorLoggingService,
    private store: StoreService
  ) {}

  ngOnInit() {
    this.spinner.hide();
    this.isNewMember = true;
    this.initialiseForm();
    this.listenForValidationErrors();
    this.getMember();
    this.setTitle();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  processMember() {
    this.isNewMember ? this.addMember() : this.editMember();
  }

  /**
   * Shows a popup for user to confirm/cancel leaving an unsaved page.
   *
   * ```NB.``` This method is called in the canDeactivate guard
   *
   * @param nextRoute The url the user intents to route to
   */
  confirmleavePage(nextRoute: string): boolean {
    this.nextRoute = nextRoute;
    (document.querySelector('#leave-page-btn') as HTMLElement).click();
    return false;
  }

  /**
   * Leaves the page after user confirmed
   */
  leavePage() {
    this.teamMemberForm.markAsPristine();
    this.nextRoute ? this.router.navigateByUrl(this.nextRoute) : null;
    this.nextRoute = undefined;
  }

  private async addMember() {
    try {
      this.spinner.show();
      const formData = this.teamMemberForm.value;
      const response = await this.teamService
        .addMember(formData)
        .pipe(take(1))
        .toPromise();
      this.spinner.hide();
      this.teamMemberForm.markAsPristine();
      this.notifier.showSuccess(response.message);
      this.store.teamRefreshNeeded = true;
      this.router.navigate(['/admin/team']);
    } catch (error) {
      this.spinner.hide();
      this.notifier.showError(errorMessage(error));
      this.erroLogger.logError(errorMessage(error));
    }
  }

  private async editMember() {
    try {
      this.spinner.show();
      const response = await this.teamService
        .editMember(this.teamMemberForm.value)
        .pipe(take(1))
        .toPromise();
      this.spinner.hide();
      this.teamMemberForm.markAsPristine();
      this.notifier.showSuccess(response.message);
      this.store.teamRefreshNeeded = true;
      this.router.navigate(['/admin/team']);
    } catch (error) {
      this.spinner.hide();
      this.notifier.showError(errorMessage(error));
      this.erroLogger.logError(errorMessage(error));
    }
  }

  private initialiseForm() {
    this.teamMemberForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      username: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, emailDot]],
      password: [
        '',
        [Validators.required, Validators.minLength(8), digit, specialCharacter],
      ],
    });
  }

  private listenForValidationErrors() {
    const sub = this.errorCheck.errorCheck(this.teamMemberForm).subscribe(
      (errors) => (this.formErrors = errors),
      (error) => {
        throw Error(errorMessage(error));
      }
    );
    this.subscriptions.add(sub);
  }

  private getMember() {
    try {
      const memberId = this.route.snapshot.paramMap.get('id');
      if (memberId === '0') {
        this.id = '0';
        this.setFocus();
        return;
      }
      const payload = this.route.parent.snapshot.data.team;
      if (payload) {
        this.member = payload.find(
          (member: ITeamMember) => member._id === memberId
        );
        this.onMemberRetrieved(this.member);
      } else {
        this.id = '0';
        this.router.navigateByUrl('/admin/team/0/edit');
        this.setFocus();
      }
    } catch (error) {
      this.notifier.showError(errorMessage(error));
      this.erroLogger.logError(errorMessage(error));
    }
  }

  private onMemberRetrieved(member: ITeamMember): void {
    if (!member) return;
    this.id = member._id;
    this.teamMemberForm.removeControl('password');
    this.teamMemberForm.removeControl('username');
    this.teamMemberForm.addControl('clearance', new UntypedFormControl());
    this.teamMemberForm.addControl('_id', new UntypedFormControl());
    this.teamMemberForm.patchValue(member);
    this.isNewMember = false;
  }

  private setFocus(): void {
    (document.querySelector('#firstName') as HTMLInputElement).focus();
  }

  private setTitle(): void {
    this.title =
      this.id === '0' || !this.id ? 'Add Team Member' : 'Edit Team Member';
  }
}
