import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, ActivatedRoute, RouterStateSnapshot } from '@angular/router';

import { Observable, of, EMPTY } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';

import { SpinnerService } from '../core/services/spinner.service';
import { NotificationService } from '../core/services/notification.service';
import { StoreService } from '../core/services/store.service';
import { BundlesService } from '../core/services/bundles.service';
import { IBundle } from '../global-models/bundle.interface';
import { errorMessage } from '../helpers/error-message';
import { IndexDbService } from '../core/services/index-db.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class BundlesResolverService  {
  private previousData: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: SpinnerService,
    private notifier: NotificationService,
    private store: StoreService,
    private bundlesService: BundlesService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (this.refresh()) {
      this.store.bundlesRefreshNeeded = false;
      return this.fetchData();
    }
    this.spinner.hide();
    return of(this.previousData);
  }

  private fetchData(): Observable<IBundle[]> {
    this.spinner.show();
    return this.bundlesService.getBundles().pipe(
      tap(bundles => {
        this.previousData = bundles;
        this.spinner.hide();
      }),
      catchError(error => {
        this.spinner.hide();
        this.router.navigate([''], { relativeTo: this.route });
        this.notifier.showError(errorMessage(error));
        throw error;
      }),
    );
  }

  private refresh(): boolean {
    return this.store.bundlesRefreshNeeded || !this.previousData;
  }
}
