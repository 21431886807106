import { environment } from '../environments/environment';

export class Constants {
  static stsAuthority: string = environment.stsAuthority;
  static trackingApiRoot: string = environment.trackingApiRoot;
  static clientRoot: string = environment.clientRoot;
  static backendUrl: string = environment.backendUrl;
  static trackablesPath = 'trackables';
  static trackersPath = 'trackers';
  static monitoredParametersPath = 'monitoredParameters';
  static parameterValuesPath = 'parameterValues';
  static usersPath = 'accounts/users';
  static membersPath = 'accounts/clients';
  static mailingPath = 'accounts/mailing';
  static clientId = 'AYoQASDnmYifYtYj7PpeBii2a5AmvdLm';
  static pushNotificationsSubscriptionsPath = 'subscriptions';
  static paypalPath = 'paypal';
  static paynowPath = 'paynow';
  static pricingPath = 'pricing';
  static appNamespace = 'nirgel-spa';
  static broadcastsPath = 'muted-broadcasts';
  static personalPath = 'me';
  static bundlesPath = 'bundles';
  static timeout = 30000;

  static tcs =
    'https://www.termsconditionstemplate.net/live.php?token=AODTToQUtazfZ2fwM44jM9PzUwmhznqN';
  static privacy =
    'https://www.privacy-policy-template.com/live.php?token=s6I06dixjiJiIa1Z8NYsmN2UjbksSLmm';
  static eula =
    'https://www.eulatemplate.com/live.php?token=yLx6mS7EWaGEDyQ0oNb9AElO7RHxiH5K';
  static cookies =
    'https://www.cookiespolicytemplate.com/live.php?token=VHUTeZjYp5SjMEPAgUJcjSjCBPJhQLgx';
  static disclaimer =
    'https://www.disclaimer-template.com/live.php?token=MvteHbzViI4OVp1idx2EdjDjDAIkh4sX';
  static fbMessenger = 'https://m.me/nirgel';
  static fbPage = 'https://fb.me/nirgel';
  static twitter = 'https://twitter.com/nirgel';
  static website = 'https://nirgel.com';
  static broadcastingIntervals = [
    'hourly',
    'shiftly',
    'daily',
    'weekly',
    'monthly',
    'other',
  ];
  static userRoles = 'https://nirgel.com/roles';
  static userMetadata = 'https://nirgel.com/user_metadata';

  // TODO: The private key should be kept private
  static vapidKeys = {
    publicKey:
      'BJF61v-iyfY0_OWYCEUVuUQa9A0YQxnzkdBfqB5B3K_Lb7uTX0rrD8Tb3SK55HQVcytXJkVJTlnLU8zyFyImRkw',
  };

  /**
   * Defines user roles
   *
   * The clearance levels are cummulative, i.e a user inherits all roles below his/her level
   *
   * @constant ```level1``` The least clearance level. Broadcast observed values
   * @constant ```level2``` Allowed to edit and delete broadcasted values, view ```Team Members```
   * @constant ```level3``` Allowed to edit ```Tracker Groups```
   * @constant ```level4``` Allowed to create ```Tracker Groups```
   * @constant ```level5``` Allowed to add, edit & remove ```Team Members``` who are not above his/her clearance level
   * @constant ```level6``` Allowed to delete ```Tracker Groups```
   * @constant ```admin``` Has the highest clearance level. Allowed to do anything in the application
   */
  static ROLES = {
    level1: `level-1`,
    level2: `level-2`,
    level3: `level-3`,
    level4: `level-4`,
    level5: `level-5`,
    level6: `level-6`,
    admin: `admin`,
  };

  static ROLE_MAPPINGS = {
    level1: [Constants.ROLES.level1],
    level2: [Constants.ROLES.level1, Constants.ROLES.level2],
    level3: [
      Constants.ROLES.level1,
      Constants.ROLES.level2,
      Constants.ROLES.level3,
    ],
    level4: [
      Constants.ROLES.level1,
      Constants.ROLES.level2,
      Constants.ROLES.level3,
      Constants.ROLES.level4,
    ],
    level5: [
      Constants.ROLES.level1,
      Constants.ROLES.level2,
      Constants.ROLES.level3,
      Constants.ROLES.level4,
      Constants.ROLES.level5,
    ],
    level6: [
      Constants.ROLES.level1,
      Constants.ROLES.level2,
      Constants.ROLES.level3,
      Constants.ROLES.level4,
      Constants.ROLES.level5,
      Constants.ROLES.level6,
    ],
    admin: [
      Constants.ROLES.level1,
      Constants.ROLES.level2,
      Constants.ROLES.level3,
      Constants.ROLES.level4,
      Constants.ROLES.level5,
      Constants.ROLES.level6,
      Constants.ROLES.admin,
    ],
  };
}
