/**
 * Converts duration adjective into the duration name eg. ```monthly``` to ```month```
 *
 * @param value The value to be converted into a time span
 * @returns The converted value
 */

export const broadcastInterval = (value: string) => {
  let lbl: string;
  switch (value) {
    case 'hourly':
      lbl = 'hour';
      break;
    case 'shiftly':
      lbl = 'shift';
      break;
    case 'daily':
      lbl = 'day';
      break;
    case 'weekly':
      lbl = 'week';
      break;
    case 'monthly':
      lbl = 'month';
      break;
    case 'yearly':
      lbl = 'year';
      break;
    default:
      lbl = 'interval';
      break;
  }
  return lbl;
};
