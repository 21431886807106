/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Subscription } from 'rxjs';

// TODO: Find out why module @smx/node-lib is not being found
// import { IStudent } from '@smx/node-lib';
import { NbTrigger } from '@nebular/theme';

import { Table, TableLazyLoadEvent } from 'primeng/table';
import { FileUploadHandlerEvent } from 'primeng/fileupload';
import { MenuItem, MessageService } from 'primeng/api';
import { MultiSelect } from 'primeng/multiselect';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import readExcel from 'read-excel-file';
import writeExcel from 'write-excel-file';
import { HttpClient } from '@angular/common/http';
import saveAs from 'file-saver';

interface IColumn {
  field: string;
  header: string;
  customExportHeader?: string;
}

interface IExportColumn {
  title: string;
  dataKey: string;
}

enum CRUD {
  create = 'create',
  read = 'read',
  update = 'update',
  delete = 'delete',
}

@Component({
  selector: 'smx-smart-table',
  templateUrl: './smart-table.component.html',
  styleUrls: ['./smart-table.component.scss'],
  providers: [MessageService],
})
export class SmxSmartTableComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(Table)
  dataTable!: Table;
  @ViewChild('actionsSelector') actionsSelector!: MultiSelect;
  /**Legacy. Use `columns` instead */
  @Input() settings!: any;
  /**Legacy. Use `value` instead */
  @Input() source!: any[];
  @Input() columns!: IColumn[];
  @Input() value!: any;
  @Input() tableHeader!: string;
  @Input() dataKey!: any;
  /**Styles to apply to the table. Defaults to `{'min-width': '40rem'}` */
  @Input() tableStyle: Record<string, string> = { 'min-width': '40rem' };
  /**Flag whether to show paginator or not. Defaults to `true` */
  @Input() showPaginator = true;
  /**Flag whether the table should implement lazy loading of items. Defaults to `false` */
  @Input() lazyLoad = false;
  /**Flag whether there should be column filters. Defaults to `false` */
  @Input() columnFilters = false;
  /**Flag whether the table should be scrollable, i.e have fixed headers. Defaults to `true` */
  @Input() scrollable = true;
  /**Table viewport scroll height in any css compatible units */
  @Input() scrollHeight = 'flex';
  /**Filename to use when exporting table data. Defaults to `table` */
  @Input() filename = 'table';
  /**Url to upload the imported files to. Defaults to `undefined`, i.e files are downloaded to the local machine */
  @Input() fileUploadUrl = undefined;
  /**Url to download the file from. Defaults to `undefined`, i.e file download is disabled */
  @Input() fileDownloadUrl: string | undefined = undefined;
  /**
   * Flag whether to use friendly headers for excel sheet columns when exporting table to excel such as `DATE OF BIRTH`. Defaults to `true`
   *
   * If `false` then the underlying table headers will be used, like `dateOfBirth`
   */
  @Input() beutifyHeaders = true;
  /**The key to use to store table state in storage. Defaults to `smx-smart-table` */
  @Input() stateKey!: string;
  /**Where to store the state: `localStorage` or `sessionStorage` */
  @Input() stateStorage: 'local' | 'session' = 'local';
  /**Flag whether to show `payments` action buttons */
  @Input() paymentActions!: boolean;
  /**Flag whether to show `CRUD` action buttons.Defaults to `true` */
  @Input() customActions = true;
  /**Flag whether to show `tests` action buttons. This is meant to be used in `School Management Apps` */
  @Input() testActions!: boolean;
  /**Flag whether to show `achievements` action buttons */
  @Input() achievementActions!: boolean;
  /**List of allowed `CRUD` options. Defaults to all but `read` option */
  @Input() crudOptions: CRUD[] = [CRUD.create, CRUD.update, CRUD.delete];
  /**Name of the single item of the collection */
  @Input() itemName = 'item';
  /**Name given to the collection of items */
  @Input() collection = 'items';
  /**Flag whether to display the `print` button. Defaults to `false` */
  @Input() printable = false;
  /**Flag whether to display a `done` button. Defaults to `false` */
  @Input() donable = false;
  /**Flag whether to display a `refresh` button. Defaults to `false` */
  @Input() refreshable = false;
  /**Flag whether to display a `bulk enroll` button. Defaults to `false` */
  @Input() enrollable = false;
  /**Flag whether to display a `Create New` button. Defaults to `true` */
  @Input() creatable = true;
  /**Flag whether to enable table to import external excel data. Defaults to `false` */
  @Input() importable = false;
  /**Flag whether to enable table to export data. Defaults to `true` */
  @Input() exportable = true;
  /**Emits the selected data (in all pages) to be enrolled */
  @Output() enroll: EventEmitter<unknown[] | undefined> = new EventEmitter();
  /**Emits the selected data (in all pages) to be printed */
  @Output() print: EventEmitter<unknown[] | undefined> = new EventEmitter();
  /**Signals that whatever the user was doing on the table is done */
  @Output() done: EventEmitter<unknown[] | undefined> = new EventEmitter();
  /**Signal to refresh the underlying table */
  @Output() refresh = new EventEmitter();
  /**Emits the `rowData` when the user clicks the row */
  @Output() rowClicked: EventEmitter<Record<string, any>> = new EventEmitter();
  /**Emits the `rowData` when a row is selected, whether by checkbox or otherwise */
  @Output() rowSelected: EventEmitter<Record<string, any>> = new EventEmitter();
  /**Emits the `rowData` when a row is unselected, whether by checkbox or otherwise */
  @Output() rowUnSelected: EventEmitter<Record<string, any>> =
    new EventEmitter();
  @Output() deleteConfirm = new EventEmitter();
  @Output() editConfirm = new EventEmitter();
  @Output() createConfirm = new EventEmitter();
  @Output() create = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() viewPayments = new EventEmitter();
  @Output() viewStatus = new EventEmitter();
  @Output() makePayment = new EventEmitter();
  @Output() view = new EventEmitter();
  @Output() test = new EventEmitter();
  @Output() tests = new EventEmitter();
  @Output() appraisal = new EventEmitter();
  @Output() award = new EventEmitter();
  @Output() displinary = new EventEmitter();
  @Output() smartTable = new EventEmitter<SmxSmartTableComponent>();

  tooltipTrigger = NbTrigger.HINT;
  selectedItems: Record<string, unknown>[] = [];
  uploadedFiles: any[] = [];
  selectedColumns!: IColumn[];
  selectedActionColumns: IColumn[] = [];
  actionColumns: IColumn[] = [];
  exportColumns!: IExportColumn[];
  itemActionsMenuItems!: MenuItem[];
  testsActionsMenuItems!: MenuItem[];
  paymentActionsMenuItems!: MenuItem[];
  achievementActionsMenuItems!: MenuItem[];
  fileMenuItems!: MenuItem[];
  // Workaround to store the rowData of the row being worked it at the moment
  // TODO: Find  the recommended way to do this
  private activeRow!: any;
  private subscriptions = new Subscription();
  private stateKeyPrefix = 'smx:table';

  get displayCrudActions() {
    return this.selectedActionColumns.some((col) => col.field === 'actions');
  }
  get displayPaymentsActions() {
    return this.selectedActionColumns.some((col) => col.field === 'payments');
  }
  get displayTestsActions() {
    return this.selectedActionColumns.some((col) => col.field === 'tests');
  }
  get displayAchievementsActions() {
    return this.selectedActionColumns.some(
      (col) => col.field === 'achievements'
    );
  }

  constructor(
    public detectorRef: ChangeDetectorRef,
    private messageService: MessageService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.handleState();
    this.configureColumnsAndDataSource();
    this.configureActions();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.smartTable.emit(this);
      this.displayDefaultAction();
    }, 10);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSearch(event: any) {
    this.dataTable.filterGlobal(event.target.value, 'contains');
  }

  setActiveRow(rowData: any) {
    this.activeRow = rowData;
  }

  onDownloadRequest() {
    if (this.fileDownloadUrl) {
      this.http
        .get(this.fileDownloadUrl, { responseType: 'blob' })
        .subscribe((blob) => {
          saveAs(blob, `${this.collection}.xlsx`);
        });
    } else {
      this.messageService.add({
        severity: 'info',
        summary: 'Info',
        detail: 'Download not available for this collection',
      });
    }
  }

  onViewTestsRequest(rowData: any) {
    this.tests.emit(rowData);
  }

  onEditItemRequest(rowData: any) {
    this.onEdit(rowData);
    // if (this.crudOptions.includes(CRUD.read)) {
    //   this.view.emit(rowData);
    // } else {
    //   this.messageService.add({
    //     severity: 'info',
    //     summary: 'Info',
    //     life: 10000,
    //     detail: `${this.titleCase(
    //       this.itemName
    //     )} viewing option not yet supported. Contact support if you need this functionality to be added asap`,
    //   });
    // }
  }

  onMakePaymentRequest(rowData: any) {
    this.makePayment.emit(rowData);
  }

  onPrintRequest() {
    if (this.dataTable) {
      this.getAllElements()
        .then((elems) => {
          this.print.emit(elems);
        })
        .catch((error) => console.error(error));
    } else {
      this.print.emit(undefined);
    }
  }

  onDone() {
    if (this.dataTable) {
      this.getAllElements()
        .then((elems) => this.done.emit(elems))
        .catch((error) => console.error(error));
    } else {
      this.done.emit(undefined);
    }
  }

  onEnrollRequest() {
    if (this.dataTable) {
      this.getAllElements()
        .then((elems) => this.enroll.emit(elems))
        .catch((error) => console.error(error));
    } else {
      this.enroll.emit(undefined);
    }
  }

  onRefreshRequest() {
    this.refresh.emit();
  }

  onDeleteConfirm(event: Record<string, unknown>) {
    this.deleteConfirm.emit(event);
  }

  onEditConfirm(event: Record<string, unknown>) {
    this.editConfirm.emit(event);
  }

  onCreateConfirm(event: Record<string, unknown>) {
    this.createConfirm.emit(event);
  }

  onCreate(event?: Record<string, unknown>) {
    this.creatable && this.crudOptions.includes(CRUD.create)
      ? this.create.emit(event)
      : this.messageService.add({
          severity: 'info',
          summary: 'Info',
          detail: `Sorry, you are not allowed to create new ${this.titleCase(
            this.collection
          )}`,
        });
  }
  onEdit(event: Record<string, unknown>) {
    this.edit.emit(event);
  }
  onDelete(data: Record<string, unknown>) {
    this.delete.emit({ data });
  }

  /**Removes an item from the table */
  async removeItem(rowData: Record<string, unknown> & { _id: string }) {
    let index = -1;
    (this.value as (typeof rowData)[]).find((rd, i) => {
      index = i;
      return rd._id === rowData._id;
    });
    if (index !== -1) {
      this.value.splice(index, 1); // Remove the row from the array
      this.value = new Array(...this.value);
      this.detectorRef.markForCheck();
    }
  }

  async updateItem(
    rowData: (Record<string, unknown> & { _id: string }) | undefined = undefined
  ) {
    if (rowData === undefined) return;
    let index = -1;
    (this.value as (typeof rowData)[]).find((rd, i) => {
      index = i;
      return rd._id === rowData._id;
    });
    if (index !== -1) {
      this.value[index] = Object.assign({}, this.value[index], rowData); // Update the row data
      this.value = new Array(...this.value); // Create a new array for immutable data structures
      this.detectorRef.markForCheck();
    }
  }

  /**Forces table refresh */
  refreshTable() {
    this.configureColumnsAndDataSource(false);
    this.compileRequiredActions();
    this.displayDefaultAction();
    this.detectorRef.markForCheck();
  }

  onRowClicked(event: any) {
    this.rowClicked.emit(event);
  }

  onRowSelect(event: any) {
    this.rowSelected.emit(event);
  }

  onRowUnselect(event: any) {
    this.rowUnSelected.emit(event);
  }

  onSelectAllChange(event: any) {
    console.log(event);
  }

  loadItems(event: TableLazyLoadEvent) {
    // this.loading = true;

    // setTimeout(() => {
    //   this.customerService
    //     .getCustomers({ lazyEvent: JSON.stringify(event) })
    //     .then((res) => {
    //       this.customers = res.customers;
    //       this.totalRecords = res.totalRecords;
    //       this.loading = false;
    //     });
    // }, 1000);
    console.log(event);
  }

  exportPdf() {
    this.exportColumns = this.columns.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then((x) => {
        const doc = new jsPDF.default('landscape', 'px', 'a4');
        (doc as any).autoTable(this.exportColumns, this.selectedItems);
        doc.save(`${this.filename}.pdf`);
      });
    });
  }

  exportExcel() {
    if (!this.selectedItems.length) {
      return this.messageService.add({
        severity: 'info',
        summary: 'Info',
        detail: 'Please select at least one item',
      });
    }

    if (this.beutifyHeaders) {
      writeExcel(this.beautifyColumns() as any, {
        fileName: `${this.filename}_export_${new Date().getTime()}.xlsx`,
        dateFormat: 'mm/dd/yyyy',
        sheet: this.filename,
      }).catch((error) => console.error(error));
    } else {
      const worksheet = XLSX.utils.json_to_sheet(this.selectedItems);
      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: [this.filename],
      };
      const excelBuffer: any = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer);
    }
  }

  onUpload(event: FileUploadHandlerEvent) {
    const uploadedFiles = [];
    for (const file of event.files) {
      uploadedFiles.push(file);
    }

    this.readExcel(uploadedFiles);
  }

  /**
   * Gets the sorted and filtered data (in all pages)
   */
  async getAllElements(): Promise<any[]> {
    return this.selectedItems;
  }

  private handleState() {
    this.stateKey = this.stateKey
      ? `${this.stateKeyPrefix}:${this.stateKey}`
      : this.stateKeyPrefix;

    const storage =
      this.stateStorage === 'session' ? sessionStorage : localStorage;
    const storedString = storage.getItem(this.stateKey);

    if (storedString) {
      const storedState = JSON.parse(storedString);
      const cleanedState = Object.assign({}, storedState, {
        filters: {},
        selection: [],
      });
      storage.setItem(this.stateKey, JSON.stringify(cleanedState));
    }
  }

  private displayDefaultAction() {
    if (this.customActions) {
      this.selectedActionColumns = [{ field: 'actions', header: 'RUD' }];
    } else {
      this.selectedActionColumns = [];
    }
    if (this.paymentActions) {
      this.selectedActionColumns = this.selectedActionColumns.concat([
        { field: 'payments', header: 'Payments' },
      ]);
    } else if (this.testActions) {
      this.selectedActionColumns = this.selectedActionColumns.concat([
        { field: 'tests', header: 'Tests' },
      ]);
    } else if (this.achievementActions) {
      this.selectedActionColumns = this.selectedActionColumns.concat([
        { field: 'achievements', header: 'Achievements' },
      ]);
    }

    this.actionsSelector?.updateModel(this.selectedActionColumns);
  }

  private exportToCSV() {
    this.dataTable.exportCSV({ selectionOnly: true });
  }

  private configureActions() {
    this.compileRequiredActions();

    this.itemActionsMenuItems = [];

    const viewItem = [
      {
        label: `View`,
        icon: 'pi pi-eye',
        tooltip: 'View entry',
        tooltipPosition: 'bottom',
        command: () => {
          this.view.emit(this.activeRow);
        },
      },
    ];
    const deleteItem = [
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        tooltip: 'Delete entry',
        tooltipPosition: 'bottom',
        command: () => {
          this.onDelete(this.activeRow);
        },
      },
    ];

    if (this.crudOptions.includes(CRUD.read)) {
      this.itemActionsMenuItems = this.itemActionsMenuItems.concat(viewItem);
    }
    if (this.crudOptions.includes(CRUD.delete)) {
      this.itemActionsMenuItems = this.itemActionsMenuItems.concat(deleteItem);
    }

    this.testsActionsMenuItems = [
      {
        label: 'Record',
        icon: 'pi pi-file-edit',
        tooltip: 'Record Test',
        tooltipPosition: 'bottom',
        command: () => {
          this.test.emit(this.activeRow);
        },
      },
    ];

    this.paymentActionsMenuItems = [
      {
        label: 'Account',
        icon: 'pi pi-wallet',
        tooltip: 'View Account',
        tooltipPosition: 'bottom',
        command: () => {
          this.viewStatus.emit(this.activeRow);
        },
      },
      {
        label: 'View Payments',
        icon: 'pi pi-list',
        tooltip: 'View Payments',
        tooltipPosition: 'bottom',
        command: () => {
          this.viewPayments.emit(this.activeRow);
        },
      },
    ];

    this.achievementActionsMenuItems = [
      {
        label: 'Add Appraisal',
        icon: 'pi pi-chart-bar',
        tooltip: 'Add Appraisal',
        tooltipPosition: 'bottom',
        command: () => {
          this.appraisal.emit(this.activeRow);
        },
      },
      {
        label: 'Add Displinary',
        icon: 'pi pi-thumbs-down',
        tooltip: 'Add Displinary',
        tooltipPosition: 'bottom',
        command: () => {
          this.displinary.emit(this.activeRow);
        },
      },
    ];
    this.fileMenuItems = [
      {
        label: 'Export to CSV',
        icon: 'pi pi-file',
        tooltip: 'Export to CSV',
        tooltipPosition: 'bottom',
        command: () => {
          this.exportToCSV();
        },
      },
      {
        label: 'Export to Excel',
        icon: 'pi pi-file-excel',
        tooltip: 'Export to Excel',
        tooltipPosition: 'bottom',
        command: () => {
          this.exportExcel();
        },
      },
      {
        label: 'Export to PDF',
        icon: 'pi pi-file-pdf',
        tooltip: 'Export to PDF',
        tooltipPosition: 'bottom',
        command: () => {
          this.exportPdf();
        },
      },
    ];

    const downloadLink = [
      {
        label: `${this.titleCase(this.collection)} Template`,
        icon: 'pi pi-download',
        tooltip: 'Download Excel Template',
        tooltipPosition: 'bottom',
        command: () => {
          this.onDownloadRequest();
        },
      },
    ];

    if (this.fileDownloadUrl) {
      this.fileMenuItems = this.fileMenuItems.concat(downloadLink);
    }
  }

  private configureColumnsAndDataSource(initialLoad = true) {
    if (initialLoad) {
      this.columns = this.columns
        ? new Array(...this.columns)
        : new Array(...this.pTableColumnsAdapter(this.settings));
    } else {
      // For backward compatibility purposes. This allows the legacy code that was using ng2-smart-table
      // which use 'settings' instead of 'columns' to continue working on table refresh
      this.columns = this.settings
        ? new Array(...this.pTableColumnsAdapter(this.settings))
        : new Array(...this.columns);
    }

    this.selectedColumns = [...this.columns];

    if (initialLoad) {
      this.value = this.value
        ? new Array(...this.value)
        : new Array(...this.source);
    } else {
      // For backward compatibility purposes. This allows the legacy code that was using ng2-smart-table
      // which use 'source' instead of 'value' to continue working on table refresh
      this.value = this.source
        ? new Array(...this.source)
        : new Array(...this.value);
    }
  }

  private compileRequiredActions() {
    this.actionColumns = [];
    if (this.customActions) {
      this.actionColumns = this.actionColumns.concat([
        { header: 'RUD', field: 'actions' },
      ]);
    }
    if (this.paymentActions) {
      this.actionColumns = this.actionColumns.concat([
        { header: 'Payments', field: 'payments' },
      ]);
    }
    if (this.testActions) {
      this.actionColumns = this.actionColumns.concat([
        { header: 'Tests', field: 'tests' },
      ]);
    }
    if (this.achievementActions) {
      this.actionColumns = this.actionColumns.concat([
        { header: 'Achievements', field: 'achievements' },
      ]);
    }
  }

  private readExcel(files: any[]) {
    if (files.length !== 1)
      throw new Error('Can only import exactly one file at a time');

    readExcel(files[0])
      .then((rows) => {
        const sanitizedHeaders = rows[0].map((header) =>
          this.capitalToCamelCase(header as unknown as string)
        );
        this.columns = sanitizedHeaders.map((h) => {
          return { field: h, header: this.camelCaseToCapital(h) };
        });
        // Update selected columns to reflect the imported ones
        this.selectedColumns = this.columns;

        // Clear action columns
        this.actionColumns = [];
        this.selectedActionColumns = [];

        this.detectorRef.markForCheck();
        const copy = rows.slice();
        copy.splice(0, 1, sanitizedHeaders);
        this.displayData(copy);
      })
      .catch((error) => console.error(error));
  }

  private displayData(data: any[][]) {
    const headers = data[0];
    const transformedData: any[] = [];
    data.forEach((row, index) => {
      if (index !== 0) {
        const item: any = {};
        row.forEach((value, i) => {
          item[`${headers[i]}`] = value;
        });
        transformedData.push(item);
      }
    });
    this.value = transformedData; // Assign to your p-table data property
    this.detectorRef.markForCheck();
  }

  private saveAsExcelFile(buffer: any): void {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      this.filename + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  /**
   * Transforms the `Ng2SmartTable` settings object to `PrimeNg` `columns` array
   *
   * This is a helper function to help in migrating from using `Ng2SmartTable` to `PrimeNg` table.
   * The main objective of this adapter is to more or less keep the existing codebase which was
   * using this library as an `Ng2SmartTable` one continue working as much as possibe
   *
   * @param settings `Ng2SmartTable`'s `settings` object or the actual `PrimeNg`'s `columns` array
   *
   */
  private pTableColumnsAdapter(settings: any = []) {
    if (settings instanceof Array) {
      // We assume that a PrimeNg 'columns' array was passed in, so we return as is
      return settings;
    }
    // NB:. Ng2SmartTable's settings object has a columns property
    if (!settings.columns) {
      // Instead of throwing we return an empty array
      return [];
    }

    // The passed in object is most probably a 'Ng2SmartTable' settings object
    const fields = Object.keys(settings.columns);
    const headers = Object.values(settings.columns).map(
      (val: any) => val.title
    );
    const displayColumns = Object.values(settings.columns).map(
      (val: any) => !val.hide
    );

    const colObj: any = {};

    fields.forEach((field, i) => {
      if (displayColumns[i]) {
        colObj[field] = headers[i];
      }
    });

    return Object.entries(colObj).map((entry) => {
      return { field: entry[0], header: entry[1] };
    });
  }

  private camelCaseToCapital(str: string) {
    // Replace all lowercase letters followed by uppercase letters with the same letters separated by a space
    // Then convert the string to uppercase
    return str.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase();
  }

  private capitalToCamelCase(str: string) {
    return (
      str
        // Lowercase the entire string
        .toLowerCase()
        // Split the string into an array of words
        .split(' ')
        // Transform the array of words into camelCase
        .map((word, index) =>
          index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
        )
        // Join the array of words back into a single string
        .join('')
    );
  }

  private beautifyColumns() {
    const columnHeaders = Object.keys(this.selectedItems[0]).map((key) => {
      return { value: this.camelCaseToCapital(key), fontWeight: 'bold' };
    });

    const rows = this.selectedItems.map((item) => {
      return Object.values(item).map((value) => {
        return { value };
      });
    });
    return [columnHeaders, ...rows];
  }

  /**
   * Formats a word or phrase into title-case
   *
   * @param phrase The word or phrase to be formatted to title-case
   * @param strict If ```true```, a pure title-case string will be returned, otherwise the first letter of a word
   *  will be capitalized and the remaining  part is left as is, whether or not it has capitalized characters.
   *  Defaults to ```true```
   * @returns The formatted string
   */
  titleCase(phrase: string, strict = true) {
    const sentence = strict
      ? phrase.toLowerCase().split(' ')
      : phrase.split(' ');
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0]
        ? sentence[i][0].toUpperCase() + sentence[i].slice(1)
        : '';
    }
    return sentence.join(' ');
  }
}
