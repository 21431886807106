import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, debounceTime, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WindowScrollService implements OnDestroy {
  scrollY$ = new BehaviorSubject(0);
  scrollPercent$ = new BehaviorSubject(0);

  private subscriptions = new Subscription();

  constructor() {
    const sub = this.scrollY$.subscribe((y) => {
      if (!Number.isNaN(y)) {
        this.scrollPercent$.next(
          Math.trunc((y / (this.getScrollHeight() - window.innerHeight)) * 100)
        );
      }
    });

    this.subscriptions.add(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getYPosition(): number {
    return this.getScrollingElement().scrollTop;
  }

  updateScrollY(value: number): void {
    this.scrollY$.next(value);
  }

  getScrollHeight(): number {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
  }

  getScrollingElement(): Element {
    return document.scrollingElement || document.documentElement;
  }
}
