import { ITrackable } from './trackable';
import { ITracker } from './tracker';

interface IBaseOfferOrAnswer {
  type: RTCSdpType;
  sdp?: string;
}

export enum RtcMessageType {
  newCallerIceCandidate = 'newCallerIceCandidate',
  newCalleeIceCandidate = 'newCalleeIceCandidate',
  answer = 'answer',
}

export enum RtcChannel {
  room = 'room',
  calleeSockets = 'calleeSockets',
}

export enum Actions {
  create = 'create',
  delete = 'delete',
  join = 'join',
  leave = 'leave',
  update = 'update',
  fetchRooms = 'fetchRooms',
}

export enum Results {
  created = 'created',
  deleted = 'deleted',
  joined = 'joined',
  left = 'left',
  updatedICE = 'updatedICE',
  updatedAnswer = 'updatedAnswer',
  updateAnswerFailure = 'updateAnswerFailure',
  calleeICESent = 'calleeICESent',
  joiningFailed = 'joiningFailed',
  failedToJoinRemoteNotOpen = 'failedToJoinRemoteNotOpen',
  error = 'error',
  updatedSocket = 'updatedSocket',
}

export interface IRtcPayload {
  channel: RtcChannel;
  action: Actions;
  type?: RtcMessageType;
  result?: Results;
  offer?: IOffer;
  answer?: IAnswer;
  newICE?: RTCIceCandidateInit;
  targetIp?: string;
  errorMessage?: string | Error;
  roomId?: string;
}

export interface IOffer extends IBaseOfferOrAnswer {}
export interface IAnswer extends IBaseOfferOrAnswer {
  // callee: any;
  // calleeIp?: string;
}

export interface IRoom extends Document {
  caller: string; // User or Member username.
  // Since rooms are short lived, we can confidently identify the
  // user by their usernames without worrying about what will happen to
  // the room objects when the user changes username in the future

  /**Main user, i.e, account holder */
  user: any;
  app: string;
  /**User friendly name of the object(s) being filmed. This could be Tracker Group, Item or custom name */
  target: string;
  answers?: IAnswer[];
  callerICE?: any[];
  offer?: IOffer;
  /**ip address of the caller i.e, room creator */
  ip?: string;
  trackable?: ITrackable | string;
  tracker?: ITracker | string;
  _id?: string;
}

export interface IIceCandidate {
  candidate: string;
}

export interface ISocketMessage {
  token: string;
  payload: Partial<IRtcPayload>;
}
