<div class="trackable" style="color: #000">
  <table class="table caption-top">
    <caption class="pt-3">
      Tracked Groups
    </caption>
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">Name</th>
        <th scope="col">Items</th>
        <th scope="col">Privacy</th>
        <th scope="col">Tracking</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let tr of trackables; let i = index">
        <th scope="row">{{ 1 + i }}</th>
        <td>{{ tr.name }}</td>
        <td>{{ tr.numberOfItems }}</td>
        <td>{{ tr.access }}</td>
        <td>{{ tr.tracking }}</td>
      </tr>
    </tbody>
  </table>
</div>
