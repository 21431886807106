import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { ModalPopupComponent } from './modal-popup/modal-popup.component';
import { RestrictPipe } from './restrict.pipe';
import { ChartComponent } from './chart/chart.component';
import { FromUnixPipe } from './from-unix.pipe';
import { PaginatorComponent } from './paginator/paginator.component';
import {
  NbFormFieldModule,
  NbInputModule,
  NbCardModule,
  NbButtonModule,
  NbActionsModule,
  NbUserModule,
  NbCheckboxModule,
  NbRadioModule,
  NbDatepickerModule,
  NbSelectModule,
  NbIconModule,
  NbLayoutModule,
  NbAccordionModule,
  NbToastrModule,
  NbStepperModule,
  NbWindowModule,
  NbListModule,
  NbContextMenuModule,
  NbPopoverModule,
} from '@nebular/theme';
import { NgxSmxComponentsModule } from '@smx/components';

@NgModule({
  declarations: [
    ModalPopupComponent,
    RestrictPipe,
    ChartComponent,
    FromUnixPipe,
    PaginatorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FontAwesomeModule,
    MatButtonModule,
    MatSnackBarModule,
    MatPaginatorModule,
    NgxChartsModule,
    NbFormFieldModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    NbLayoutModule,
    NbAccordionModule,
    NbToastrModule,
    NbStepperModule,
    NbWindowModule,
    NbListModule,
    NbContextMenuModule,
    NbPopoverModule,
    NgxSmxComponentsModule,
  ],
  providers: [],

  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FontAwesomeModule,
    ModalPopupComponent,
    PaginatorComponent,
    ChartComponent,
    MatButtonModule,
    MatSnackBarModule,
    MatPaginatorModule,
    RestrictPipe,
    FromUnixPipe,
    NgxChartsModule,
    NbFormFieldModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    NbLayoutModule,
    NbAccordionModule,
    NbToastrModule,
    NbStepperModule,
    NbWindowModule,
    NbListModule,
    NbContextMenuModule,
    NbPopoverModule,
    NgxSmxComponentsModule,
  ],
})
export class SharedModule {}
