<nb-card accent="info">
  <nb-card-header class="d-flex">
    <h2 class="d-inline-block">{{ member.firstName }} {{ member.lastName }}</h2>
    <nb-actions class="ms-auto">
      <nb-action
        attr.aria-label="Edit {{ member.firstName | titlecase }}"
        (click)="goToMemberEdit()"
        *ngIf="'level-5' | restrict"
        icon="edit-2-outline"
        nbTooltip="Edit {{ member.firstName | titlecase }}"
        nbTooltipIcon="edit-2-outline"
      ></nb-action>
      <nb-action
        attr.aria-label="Delete {{ member.firstName | titlecase }}"
        title="Delete {{ member.firstName | titlecase }}"
        data-bs-toggle="modal"
        attr.data-bs-target="{{ '#delete-member' + index }}"
        *ngIf="'level-5' | restrict"
        icon="trash-outline"
        nbTooltipIcon="trash-outline"
        nbTooltip="Delete {{ member.firstName | titlecase }}"
      ></nb-action>
    </nb-actions>
  </nb-card-header>
  <nb-card-body class="table-responsive">
    <table class="">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">User:</th>
          <td id="{{ 'username' + index }}">
            &nbsp;&nbsp;{{ member.username }}
          </td>
        </tr>
        <tr *ngIf="!!member.email">
          <th scope="row">Email:</th>
          <td id="{{ 'email' + index }}">&nbsp;&nbsp;{{ member.email }}</td>
        </tr>
        <tr>
          <th scope="row">Level:</th>
          <td id="{{ 'clearance' + index }}">
            &nbsp;&nbsp;{{ member.clearance | titlecase }}
          </td>
        </tr>
      </tbody>
    </table>
  </nb-card-body>
</nb-card>

<app-modal-popup
  (formData)="deleteMember($event, index)"
  [target]="member"
  buttonText="Delete"
  identifier="delete-member"
  [isForm]="false"
  [index]="index"
  bodyText="Are you sure you want to remove {{
    member.firstName | titlecase
  }} {{ member.lastName | titlecase }}?"
>
</app-modal-popup>
