import { AbstractControl } from '@angular/forms';

export const trimValue = (c: AbstractControl): { [key: string]: boolean } | null => {
  const value = c.value;
  const pattern = /[\S]/;
  if (!pattern.test(value[0])) {
    return { trimValue: true };
  }
  if (!pattern.test(value[value.length - 1])) {
    return { trimValue: true };
  }
  return null;
};
