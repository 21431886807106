import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, map, retry, take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { ITrackable } from '../../tracking/interfaces-enums/trackable';
import { ITracker } from '../../tracking/interfaces-enums/tracker';
import { IParameterValue } from '../../tracking/interfaces-enums/parameter-value';
import { Constants } from '../../constants';
import { StoreService } from './store.service';
import { IAccountDetails } from '../../tracking/interfaces-enums/account-details';
import { IndexDbService } from './index-db.service';
import { NotificationService } from './notification.service';
import { errorMessage } from '../../helpers/error-message';
import { IParameterValueRequest } from '../../tracking/interfaces-enums/parameter-value-request';

@Injectable()
export class DataService {
  private trackingApiRoot = Constants.trackingApiRoot;
  private trackablesPath = Constants.trackablesPath;
  private personalPath = Constants.personalPath;
  private trackersPath = Constants.trackersPath;
  private monitoredParametersPath = Constants.monitoredParametersPath;
  private parameterValuesPath = Constants.parameterValuesPath;
  private account: string;
  private owner: string;
  private dbAccountDetails: IAccountDetails;

  constructor(
    private http: HttpClient,
    private store: StoreService,
    private db: IndexDbService,
    private notifier: NotificationService
  ) {
    this.store.account$.subscribe((account) => {
      if (account) {
        this.account = account;
      }
    });

    this.store.user$.subscribe((user) => {
      if (user) {
        this.owner = user;
      }
    });

    this.store.accountDetails$.subscribe((acc) => {
      if (acc) {
        this.dbAccountDetails = acc;
      }
    });
  }

  createTrackable(data: ITrackable): Observable<ITrackable> {
    return this.http
      .post<ITrackable>(
        `${this.trackingApiRoot}/${this.trackablesPath}/create`,
        data
      )
      .pipe(retry(2));
  }

  createTracker(data: any): Observable<ITrackable> {
    return this.http
      .post<ITrackable>(
        `${this.trackingApiRoot}/${this.trackersPath}/create`,
        data
      )
      .pipe(retry(2));
  }

  updateKpi(parameterValues: IParameterValue[]): Observable<any> {
    return this.http
      .post(`${this.trackingApiRoot}/${this.parameterValuesPath}/create`, {
        parameterValues,
      })
      .pipe(retry(2));
  }

  getTrackables(): Observable<ITrackable[]> {
    return this.http.get<ITrackable[]>(
      `${this.trackingApiRoot}/${this.trackablesPath}/get`
    );
  }

  getTrackableById(id: string): Observable<ITrackable> {
    return this.http
      .get<any>(`${this.trackingApiRoot}/${this.trackablesPath}/get/${id}`)
      .pipe(retry(2));
  }

  editTrackable(id: string, data: Partial<ITrackable>): Observable<ITrackable> {
    return this.http
      .put<any>(
        `${this.trackingApiRoot}/${this.trackablesPath}/edit/${id}`,
        data
      )
      .pipe(retry(2));
  }

  editTracker(data: any): Observable<ITracker> {
    // Preferred this way in case we may need to update other Tracker parameters in the future
    // This will allow us to include them in 'data' object without having to change the method signature
    const trackableId = data.trackableId;
    const trackerId = data.trackerId;
    delete data.trackableId;
    delete data.trackerId;
    return this.http
      .put<any>(
        `${this.trackingApiRoot}/${this.trackersPath}/edit/${trackableId}/${trackerId}`,
        data
      )
      .pipe(retry(2));
  }

  deleteTrackable(id: string): Observable<any> {
    return this.http
      .delete(`${this.trackingApiRoot}/${this.trackablesPath}/delete/${id}`)
      .pipe(retry(2));
  }

  deleteTracker(data: {
    trackableId: string;
    trackerId: string;
  }): Observable<any> {
    return this.http
      .delete<any>(
        `${this.trackingApiRoot}/${this.trackersPath}/delete/${data.trackableId}/${data.trackerId}`
      )
      .pipe(retry(2));
  }

  deleteMonitoredParameter(data: any): Observable<any> {
    return this.http
      .delete<any>(
        `${this.trackingApiRoot}/${this.monitoredParametersPath}/delete/${data.monitoredParameterId}?trackableId=${data.trackableId}`
      )
      .pipe(retry(2));
  }

  getTrackerById(id: string): Observable<ITracker> {
    return this.http.get<ITracker>(
      `${this.trackingApiRoot}/${this.trackersPath}/get/${id}`
    );
  }

  getParameterValues(data: {
    monitoredParameter: string;
    tracker: string;
    interval: { startDate: number; endDate: number };
  }): Observable<IParameterValue[]> {
    return this.http
      .get<IParameterValue[]>(
        `${this.trackingApiRoot}/${this.parameterValuesPath}/select/?monitoredParameter=${data.monitoredParameter}&tracker=${data.tracker}&startDate=${data.interval.startDate}&endDate=${data.interval.endDate}`
      )
      .pipe(
        map((payload) => {
          return payload;
        })
      );
  }

  getBatchParameterValues(
    data: IParameterValueRequest[][]
  ): Observable<IParameterValue[][][]> {
    return this.http
      .post<IParameterValue[][][]>(
        `${this.trackingApiRoot}/${this.parameterValuesPath}/get-batch`,
        data
      )
      .pipe(
        map((payload) => {
          return payload;
        })
      );
  }

  editParameterValue(
    parameterValue: Partial<IParameterValue>
  ): Observable<any> {
    return this.http
      .put(
        `${this.trackingApiRoot}/${this.parameterValuesPath}/edit/${parameterValue._id}`,
        {
          parameterValue,
        }
      )
      .pipe(retry(2));
  }

  deleteParameterValue(id: string): Observable<any> {
    return this.http
      .delete<any>(
        `${this.trackingApiRoot}/${this.parameterValuesPath}/delete/${id}`
      )
      .pipe(retry(2));
  }

  accountUpgrade(bundleName: string) {
    return this.http
      .get<any>(
        `${this.trackingApiRoot}/${this.personalPath}/account-upgrade?bundleName=${bundleName}`
      )
      .pipe(retry(2));
  }

  getAccountDetails() {
    let fromIndexedDb = false;
    return this.http
      .get<any>(`${this.trackingApiRoot}/${this.personalPath}/account-details`)
      .pipe(
        retry(!this.dbAccountDetails && !this.store.isOnline ? 50 : 2),
        catchError((error) => {
          if (
            this.dbAccountDetails &&
            error instanceof HttpErrorResponse &&
            (error.status === 0 || error.status === 504)
          ) {
            fromIndexedDb = true;
            return of(this.dbAccountDetails);
          }
          throw error;
        }),
        tap<IAccountDetails>(async (data) => {
          if (!fromIndexedDb && this.account && this.owner) {
            const details = Object.assign({}, data, {
              account: this.account,
              owner: this.owner,
            });
            if (data.expiry) {
              this.db.accountDetails
                .where('account')
                .equals(this.account)
                .and((acc) => acc.owner === this.owner)
                .delete()
                .then(() => {
                  this.db.accountDetails
                    .add(details)
                    .then((_) => (this.store.accountDetails = details))
                    .catch((error) => {
                      this.notifier.showError(errorMessage(error));
                    });
                })
                .catch((error) => {
                  this.notifier.showError(errorMessage(error));
                });
            }
          }
          fromIndexedDb = false;
        })
      );
  }

  async setAccountDetails() {
    try {
      const details = await this.getAccountDetails().pipe(take(1)).toPromise();
      this.store.appExpiry = details.expiry;
    } catch (error) {
      throw Error(errorMessage(error));
    }
  }
}
