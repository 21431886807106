import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  BehaviorSubject,
  Observable,
  Subscription,
  firstValueFrom,
} from 'rxjs';
import { take } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import 'webrtc-adapter';

import {
  Actions,
  IAnswer,
  IOffer,
  IRoom,
  IRtcPayload,
  Results,
  RtcChannel,
  RtcMessageType,
} from '../../tracking/interfaces-enums/webrtc';
import { errorMessage } from '../../helpers/error-message';
import { Constants } from '../../constants';
import { ITicket } from '../../tracking/interfaces-enums/rtc-ticket';
import { IVideoTarget } from '../../tracking/interfaces-enums/video-target';
import { AuthService } from './auth.service';
import { ErrorLoggingService } from './logging.service';
import { NotificationService } from './notification.service';
import { SpinnerService } from './spinner.service';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root',
})
export class WebRtcService {
  localStream: MediaStream;
  localStreamClone: MediaStream;
  remoteStream: MediaStream;

  socketIsOpen$ = new BehaviorSubject<boolean>(false);
  isCamera$ = new BehaviorSubject<boolean>(false);
  availableRooms$ = new BehaviorSubject<IRoom[]>([]);

  private trackingApiRoot = Constants.trackingApiRoot;
  private rtcSocket$: WebSocketSubject<any>; // The main socket gateway
  private callerRoom$: Observable<any>; // Room multiplex observable
  private calleeRoom$: Observable<any>; // Room multiplex observable
  private socketIsOpen: boolean;
  private callerRoomSub: Subscription;
  private calleeRoomSub: Subscription;
  private account: string;
  private interval: number;
  private callerPeerConnection: RTCPeerConnection;
  private calleePeerConnection: RTCPeerConnection;
  private rtcConfig: RTCConfiguration = {
    iceServers: [
      { urls: ['stun:eu-turn4.xirsys.com'] },
      {
        username:
          '-VGk3fSpVw6RQSSc1RGNHVNpQgusvuVi0qH4J9y-Pq7xbQeCmNBm1WGV-pieEUUSAAAAAGEJN4VzZWFtbWluZXg=',
        credential: 'f5bd5d2a-f456-11eb-a785-0242ac140004',
        urls: [
          // 'turn:eu-turn4.xirsys.com:80?transport=udp',
          // 'turn:eu-turn4.xirsys.com:3478?transport=udp',
          'turn:eu-turn4.xirsys.com:80?transport=tcp',
          'turn:eu-turn4.xirsys.com:3478?transport=tcp',
          'turns:eu-turn4.xirsys.com:443?transport=tcp',
          'turns:eu-turn4.xirsys.com:5349?transport=tcp',
        ],
      },
    ],
    iceCandidatePoolSize: 10,
  };

  constructor(
    private http: HttpClient,
    private store: StoreService,
    private authService: AuthService,
    private notifier: NotificationService,
    private errorLogger: ErrorLoggingService,
    private spinner: SpinnerService
  ) {
    this.store.account$.subscribe(
      (account) => {
        if (account) {
          this.account = account;
        }
      },
      (error) => this.notifier.showError(errorMessage(error))
    );

    this.socketIsOpen$.subscribe(
      (isOpen) => (this.socketIsOpen = isOpen),
      (error) => this.notifier.showError(errorMessage(error))
    );

    this.prepareToReceiveRemoteStream();
  }

  send(payload: any) {
    this.rtcSocket$.next(payload);
  }

  async performHandShake() {
    try {
      if (
        !this.store.isOnline ||
        !this.authService.isLoggedIn ||
        this.socketIsOpen
      )
        return;

      const rawTicket = await firstValueFrom(this.getTicket().pipe(take(1)));
      const { ticket, id } = rawTicket;
      this.rtcSocket$ = webSocket({
        url: `wss://nirgel.eu-4.evennode.com/api/rtc/connect?ticket=${ticket}&id=${id}&app=${Constants.appNamespace}&user=${this.account}`,
        serializer: (payload) =>
          JSON.stringify({ token: this.authService.getToken(), payload }),
        openObserver: {
          next: () => {
            const updateMsg: IRtcPayload = {
              channel: RtcChannel.calleeSockets,
              action: Actions.update,
            };
            this.send(updateMsg);
            this.socketIsOpen$.next(true);
            // this.notifier.showSuccess('Handshake complete');
          },
        },
        closeObserver: {
          next: (closeEvent: CloseEvent) => {
            this.callerCleanUp();
            this.socketIsOpen$.next(false);
            this.isCamera$.next(false);
            const code = closeEvent.code;
            switch (code) {
              case 1006:
                console.log(closeEvent);
                // this.notifier.showError('Unauthorized to access socket');
                break;
              default:
                console.log(closeEvent);
                // this.notifier.showError('Error code: ' + code);
                break;
            }
          },
        },
        closingObserver: {
          next: () => {
            this.socketIsOpen$.next(false);
          },
        },
      });

      // This subscription is mainly done to force the main connection since the
      // subject needs at least one subscription to make a connection
      this.rtcSocket$.subscribe(
        (payload: IRtcPayload) => {
          const action = payload.action;
          console.log(payload);

          if (action === Actions.fetchRooms) {
            this.updateRooms().catch((error) =>
              this.notifier.showError(errorMessage(error))
            );
          }
        },
        (error) => {},
        () => {}
      );
    } catch (error) {
      console.log(error);
    }
  }

  async createRoom(data: IVideoTarget) {
    try {
      if (!this.store.isOnline) {
        throw Error('Your are offline');
      }
      this.spinner.show();
      if (!this.socketIsOpen) {
        await this.performHandShake();
        window.setTimeout(async () => {
          if (this.socketIsOpen) {
            await this.makeRoom(data);
          } else {
            this.notifier.showError(
              'Failed to connect in time. Please try again'
            );
          }
        }, 2000);
      } else {
        await this.makeRoom(data);
      }
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.notifier.showError(errorMessage(error));
      this.errorLogger.logError(errorMessage(error));
    }
  }

  async updateRooms() {
    try {
      if (!this.authService.isLoggedIn) return;

      const rooms = await this.getRooms().pipe(take(1)).toPromise();
      this.availableRooms$.next(rooms);
      console.log(rooms);
    } catch (error) {
      this.notifier.showError(errorMessage(error));
    }
  }

  deleteRoom() {
    this.callerCleanUp();
    this.callerRoomSub.unsubscribe();
  }

  leaveRoom() {
    this.calleeCleanUp();
    this.calleeRoomSub.unsubscribe();
    this.calleeRoom$ = undefined;
  }

  getTicket(): Observable<ITicket> {
    return this.http.get<ITicket>(`${this.trackingApiRoot}/rtc/tickets/get`);
  }

  async joinRoom(room: IRoom) {
    let answer: IAnswer;
    let payload: IRtcPayload;
    try {
      if (room) {
        console.log('Got room:', room._id);
        // if (this.remoteStream.getTracks().length) return;

        console.log(
          'Create PeerConnection with configuration: ',
          this.rtcConfig
        );

        this.calleePeerConnection = new RTCPeerConnection(this.rtcConfig);
        this.registerPeerConnectionListeners(this.calleePeerConnection);

        //? We don't need local stream when joining room. It's a view only configuration

        this.calleePeerConnection.addEventListener('track', (event) => {
          console.log('Got remote track:', event.streams[0]);
          event.streams[0].getTracks().forEach((track) => {
            console.log('Add a track to the remoteStream:', track);
            this.remoteStream.addTrack(track);
          });
        });

        // Construct and set SDP answer
        const offer = room.offer;
        await this.calleePeerConnection.setRemoteDescription(offer);
        const localAnswer = await this.calleePeerConnection.createAnswer();
        await this.calleePeerConnection.setLocalDescription(localAnswer);

        this.calleePeerConnection.onicecandidate = (event) => {
          const candidate = event.candidate;
          if (candidate) {
            console.log('Callee icecandidate fired');
            // if (candidate.candidate.indexOf('typ relay') == -1) return; // Force TURN. Comment out in production
            const newICE = event.candidate.toJSON();
            const payload: IRtcPayload = {
              channel: RtcChannel.room,
              action: Actions.update,
              type: RtcMessageType.newCalleeIceCandidate,
              newICE,
              roomId: room._id,
            };
            this.send(payload);
          }
        };

        answer = {
          type: localAnswer.type,
          sdp: localAnswer.sdp,
        };
        payload = {
          channel: RtcChannel.room,
          roomId: room._id,
          targetIp: room.ip,
          action: undefined,
        };
      } else {
        throw Error('No remote video found');
      }
    } catch (error) {
      this.notifier.showError(errorMessage(error));
      this.errorLogger.logError(errorMessage(error));
      return;
    }

    if (!this.calleeRoom$) {
      this.calleeRoom$ = this.rtcSocket$.multiplex(
        () => {
          const subMsg: IRtcPayload = Object.assign({}, payload, {
            action: Actions.join,
            type: RtcMessageType.answer,
            answer,
          });
          console.log(subMsg);

          return subMsg;
        },
        () => {
          this.calleeCleanUp();
          const unsubMsg: IRtcPayload = Object.assign({}, payload, {
            action: Actions.leave,
          });
          return unsubMsg;
        },
        (payload: IRtcPayload) => {
          const result = payload.result;
          const type = payload.type;
          const action = payload.action;

          if (action === Actions.fetchRooms) {
            this.updateRooms().catch((error) =>
              this.notifier.showError(errorMessage(error))
            );
          }

          if (result === Results.joined) {
            room.callerICE.forEach((ice) => {
              // Add the initial remote callerICE that have been stored when room was formed
              const candidate = new RTCIceCandidate(ice);
              this.calleePeerConnection.addIceCandidate(candidate);
            });
          }

          if (type === RtcMessageType.newCallerIceCandidate) {
            // Add remote callerICE candidate as they happen
            const ice = payload.newICE;
            const candidate = new RTCIceCandidate(ice);
            this.calleePeerConnection.addIceCandidate(candidate);
          }
          return payload.channel === RtcChannel.room && !payload.answer;
        }
      );
    }
    this.calleeRoomSub = this.calleeRoom$.subscribe(
      (msg) => console.log(msg),
      (error) => {
        const type = error.type;
        switch (type) {
          case 'error':
            console.log(error);
            break;
          case 'close':
            if (error.code === 1006) {
              console.log('Unauthorized');
            } else {
              console.log(error);
            }
            break;
          default:
            console.log(error);
            break;
        }
      },
      () => console.log('Done')
    );
  }

  private callerCleanUp() {
    this.localStream
      ? this.localStream.getTracks().forEach((track) => track.stop())
      : null;
    this.localStreamClone
      ? this.localStreamClone.getTracks().forEach((track) => track.stop())
      : null;
    if (this.callerPeerConnection) {
      this.callerPeerConnection.close();
    }
    this.isCamera$.next(false);
  }

  private calleeCleanUp() {
    this.remoteStream
      ? this.remoteStream.getTracks().forEach((track) => track.stop())
      : null;
    if (this.calleePeerConnection) {
      this.calleePeerConnection.close();
    }
  }

  private async makeRoom(data: IVideoTarget) {
    let offer: IOffer;
    try {
      await this.setupRoom(data).pipe(take(1)).toPromise();
      await this.openUserMedia();
      this.callerPeerConnection = new RTCPeerConnection(this.rtcConfig);
      this.registerPeerConnectionListeners(this.callerPeerConnection);
      this.localStream.getTracks().forEach((track) => {
        this.callerPeerConnection.addTrack(track, this.localStream);
      });

      const rawOffer = this.callerPeerConnection
        ? await this.callerPeerConnection.createOffer({ iceRestart: true })
        : await this.callerPeerConnection.createOffer();
      console.log(rawOffer);
      await this.callerPeerConnection.setLocalDescription(rawOffer);

      offer = {
        type: rawOffer.type,
        sdp: rawOffer.sdp,
      };

      this.callerPeerConnection.onicecandidate = (event) => {
        const candidate = event.candidate;
        if (candidate) {
          console.log('icecandidate fired');
          // if (candidate.candidate.indexOf('typ relay') == -1) return; // Force TURN, comment out in production
          const cand = candidate.toJSON();
          this.sendICE(cand);
          console.log(cand);
        }
      };

      //? N.B. We don't need remote stream when the device is acting as a camera.
      //? Its purpose is to stream out, it is the broadcaster.
    } catch (error) {
      this.notifier.showError(errorMessage(error));
      this.errorLogger.logError(errorMessage(error));
      return;
    }

    if (!this.callerRoom$) {
      this.callerRoom$ = this.rtcSocket$.multiplex(
        () => {
          const subMsg: IRtcPayload = {
            channel: RtcChannel.room,
            action: Actions.create,
            offer,
          };
          return subMsg;
        },
        () => {
          this.callerCleanUp();
          const unsubMsg: IRtcPayload = {
            channel: RtcChannel.room,
            action: Actions.delete,
          };
          return unsubMsg;
        },
        (payload: IRtcPayload) => {
          const result = payload.result;
          const type = payload.type;
          if (result === Results.created) {
            this.isCamera$.next(this.store.isOnline && true);
            this.updateRooms();
          } else if (result === Results.deleted) {
            this.isCamera$.next(false);
            this.updateRooms();
          }

          if (type === RtcMessageType.answer) {
            // Set remote session description
            const remoteAnswer = payload.answer;
            if (
              !this.callerPeerConnection.currentRemoteDescription &&
              remoteAnswer
            ) {
              console.log('Set remote description: ', remoteAnswer);
              const answer = new RTCSessionDescription(remoteAnswer);
              this.callerPeerConnection
                .setRemoteDescription(answer)
                .catch((error) => this.notifier.showError(errorMessage(error)));
            }
          } else if (type === RtcMessageType.newCalleeIceCandidate) {
            // Add remote ICE candidate
            const ice = payload.newICE;
            const candidate = new RTCIceCandidate(ice);
            this.callerPeerConnection.addIceCandidate(candidate);
          }
          return (
            payload.channel === RtcChannel.room &&
            payload.result !== Results.joined
          );
        }
      );
    }
    this.callerRoomSub = this.callerRoom$.subscribe(
      (msg) => console.log(msg),
      (error) => {
        const type = error.type;
        switch (type) {
          case 'error':
            console.log(error);
            break;
          case 'close':
            if (error.code === 1006) {
              console.log('Unauthorized');
            } else {
              console.log(error);
            }
            break;
          default:
            console.log(error);
            break;
        }
      },
      () => console.log('Done')
    );

    // this.interval = window.setInterval(() => {
    //   const payload = { message: 'Tracker Group hooked' };
    //   this.rtcSocket$.next(payload);
    // }, 5000);
  }

  private sendICE(newICE: RTCIceCandidateInit) {
    const payload: IRtcPayload = {
      channel: RtcChannel.room,
      action: Actions.update,
      type: RtcMessageType.newCallerIceCandidate,
      newICE,
    };
    this.send(payload);
  }

  private async openUserMedia() {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });

    this.localStream = stream;
  }

  private prepareToReceiveRemoteStream() {
    this.remoteStream = new MediaStream();
  }

  private registerPeerConnectionListeners(peerConnection: RTCPeerConnection) {
    peerConnection.addEventListener('icegatheringstatechange', () => {
      console.log(
        `ICE gathering state changed: ${peerConnection.iceGatheringState}`
      );
    });

    peerConnection.addEventListener('connectionstatechange', () => {
      console.log(`Connection state change: ${peerConnection.connectionState}`);
    });

    peerConnection.addEventListener('signalingstatechange', () => {
      console.log(`Signaling state change: ${peerConnection.signalingState}`);
    });

    peerConnection.addEventListener('iceconnectionstatechange ', () => {
      console.log(
        `ICE connection state change: ${peerConnection.iceConnectionState}`
      );
    });
  }

  private setupRoom(data: IVideoTarget): Observable<ITicket> {
    return this.http.post<ITicket>(
      `${this.trackingApiRoot}/rtc/rooms/create`,
      data
    );
  }

  private getRooms(): Observable<IRoom[]> {
    return this.http.get<IRoom[]>(`${this.trackingApiRoot}/rtc/rooms`);
  }

  private getRoom(roomId: string): Observable<IRoom> {
    return this.http.get<IRoom>(`${this.trackingApiRoot}/rtc/rooms/${roomId}`);
  }
}
