import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbDialogService,
  NbMediaBreakpointsService,
  NbThemeService,
  NbTrigger,
} from '@nebular/theme';

import { map, takeUntil, tap } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { ProfileService } from '../../../@core/utils/services/profile.service';
import { AuthService } from '../../../core/services/auth.service';
import { NbAuthJWTToken } from '@nebular/auth';
import { Router } from '@angular/router';
import { PreferencesComponent } from '../../../@core/components/popup/preferences/preferences.component';

@Component({
  selector: 'ngx-header-basic',
  styleUrls: ['./header-basic.component.scss'],
  templateUrl: './header-basic.component.html',
})
export class HeaderBasicComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  private token: NbAuthJWTToken | undefined;
  private isOnline!: boolean;
  NbTrigger = NbTrigger;
  userPictureOnly = false;
  institutionAbr: string;
  subscriptions = new Subscription();
  sidebarExpanded = false;
  temperedWith = false;
  touches = 0;
  user: any;
  userName = '';
  userTitle = '';

  get picture() {
    return this.user ? '' : '/assets/images/user.png';
  }

  constructor(
    private router: Router,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    private profileService: ProfileService,
    private ref: ChangeDetectorRef,
    private dialogService: NbDialogService,
    public authService: AuthService
  ) {
    this.institutionAbr = this.profileService.institutionAbreviation;
  }

  ngOnInit() {
    this.authService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
      if (user) {
        const profile = this.authService.getUserProfile();
        this.userName = `${user.nickname}`.toUpperCase();
        this.userTitle = profile.role?.toUpperCase();
      }
      this.ref.markForCheck();
    });

    const { xl } = this.breakpointService.getBreakpointsMap();

    window.innerWidth >= xl ? (this.sidebarExpanded = true) : null;

    this.themeService
      .onMediaQueryChange()
      .pipe(
        tap(([, currentBreakpoint]) => {
          this.touches += 1;
          this.touches > 1 &&
          (currentBreakpoint.name === 'xl' || currentBreakpoint.name === 'lg')
            ? (this.temperedWith = true)
            : null;
        }),
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscriptions.unsubscribe();
  }

  themes() {
    this.dialogService.open(PreferencesComponent);
  }

  login() {
    this.authService.login();
  }
  logout() {
    this.authService.logout();
  }
  signup() {
    this.router.navigateByUrl('/signup');
  }
}
