import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { faTrashAlt, faUserEdit } from '@fortawesome/free-solid-svg-icons';

import { SpinnerService } from '../../core/services/spinner.service';
import { ITeamMember } from '../../global-models/team-member';
import { AutoUnsubscribe } from '../../helpers/observable-auto-unsubscribe';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss'],
})
@AutoUnsubscribe()
export class MemberComponent implements OnInit, OnDestroy {
  @Input() member: ITeamMember;
  @Input() index: any;
  @Output() formData: EventEmitter<any>;

  faUserEdit = faUserEdit;
  faTrashAlt = faTrashAlt;

  formErrors: any;
  memberNameEditForm: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private router: Router
  ) {
    this.formData = new EventEmitter();
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  deleteMember(formData: any, index: number) {
    this.formData.emit({ formData, index });
  }

  goToMemberEdit() {
    this.spinner.show();
    this.router.navigate(['.', this.member._id, 'edit'], {
      relativeTo: this.route,
    });
    this.spinner.hide();
  }
}
