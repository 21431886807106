import { Injectable } from '@angular/core';

@Injectable()
export class ErrorLoggingService {
  logError(message: string, stack?: string) {
    message = message ? message : 'An error occurred';
    // TODO: Send errors to server here
    console.log('ErrorLoggingService: ' + message);
  }
}
