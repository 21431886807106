import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import Dexie from 'dexie';

import { ITrackableIndexedDb } from '../../tracking/interfaces-enums/trackable';
import { IParamValueIndexedDB } from '../../tracking/interfaces-enums/parameter-value';
import { IndexedDBTables } from '../../tracking/interfaces-enums/indexed-db-tables.enum';
import { IMutesIndexedDb } from '../../tracking/interfaces-enums/muted-broadcast';
import { IAccountDetailsIndexedDb } from '../../tracking/interfaces-enums/account-details';
import { IBundleIndexedDb } from '../../global-models/bundle.interface';
import { IExchangeRateIndexedDb } from '../../tracking/interfaces-enums/exchange-rate';
import { IBearerTokenIndexedDb } from '../../tracking/interfaces-enums/bearer-token';
import { ITeamMemberIndexedDb } from '../../global-models/team-member';

@Injectable()
export class IndexDbService extends Dexie {
  trackables: Dexie.Table<ITrackableIndexedDb, number>;
  team: Dexie.Table<ITeamMemberIndexedDb, number>;
  activeBundle: Dexie.Table<IBundleIndexedDb, number>;
  bundles: Dexie.Table<IBundleIndexedDb, number>;
  mutedBroadcasts: Dexie.Table<IMutesIndexedDb, number>;
  accountDetails: Dexie.Table<IAccountDetailsIndexedDb, number>;
  parameterValues: Dexie.Table<IParamValueIndexedDB, number>;
  exchangeRates: Dexie.Table<IExchangeRateIndexedDb, number>;
  tokens: Dexie.Table<IBearerTokenIndexedDb, number>;
  pushMessages: Dexie.Table<any, number>;
  dbVersion = 5;

  dbReady$ = new BehaviorSubject<boolean>(false);
  private storage: Event;

  constructor() {
    super('TrekaptDatabase');
    this.initialiseDatabase();
    this.storage = this.createDatabaseStorageEvent('changed');
    this.dbReady$.next(true);
  }

  /**
   * Adds data to a database table
   *
   * @param data Data to be added. This can either be an object or an array of objects
   * @param tableName The table to add the ```data``` to
   */
  async addData(data: any | any[], tableName: string): Promise<void> {
    await this.transaction('rw', this[`${tableName}`], async () => {
      data instanceof Array ? await this[`${tableName}`].bulkAdd(data) : await this[`${tableName}`].add(data);
    });
    window.dispatchEvent(this.storage);
  }

  /**
   * Clears all data in a database table
   *
   * @param tableName The table to clear data
   */
  async clearData(tableName: string): Promise<void> {
    await this.transaction('rw', this[`${tableName}`], async () => {
      await this[`${tableName}`].clear();
      setTimeout(() => {
        tableName !== 'tokens' ? window.dispatchEvent(this.createDatabaseStorageEvent('cleared')) : null;
      }, 4000);
    });
  }

  /**
   * Deletes records from a database table
   *
   * @param tableName The table to delete records from
   * @param ids An array of record IDs to be deleted
   */
  async deleteRecords(tableName: string, ids: (number | string)[]): Promise<void> {
    await this.transaction('rw', this[`${tableName}`], async () => {
      await this[`${tableName}`].bulkDelete(ids);
      setTimeout(() => {
        window.dispatchEvent(this.createDatabaseStorageEvent('deleted'));
      }, 1500);
    });
  }

  /**
   * Gets all the records in a table
   *
   * @param tableName The table to get the records from
   */
  async getAll(tableName: string): Promise<any[]> {
    return await this.transaction('r', this[`${tableName}`], async () => {
      return await this[`${tableName}`].toArray();
    });
  }

  /**
   * Checks if a table has records
   *
   * @param tableName The table to check for records
   */
  async hasData(tableName: string): Promise<boolean> {
    return (await this.getAll(tableName)).length > 0;
  }

  private initialiseDatabase() {
    this.version(this.dbVersion).stores({
      trackables: '++id,account,owner,app,user,_id',
    });
    this.trackables = this.table(IndexedDBTables.trackables);

    this.version(this.dbVersion).stores({
      team: '++id,account,owner,app,_id',
    });
    this.team = this.table(IndexedDBTables.team);

    this.version(this.dbVersion).stores({
      mutedBroadcasts: '++id,account,owner',
    });
    this.mutedBroadcasts = this.table(IndexedDBTables.mutedBroadcasts);

    this.version(this.dbVersion).stores({
      accountDetails: '++id,account,owner',
    });
    this.accountDetails = this.table(IndexedDBTables.accountDetails);

    this.version(this.dbVersion).stores({
      activeBundle: '++id,account,owner',
    });
    this.activeBundle = this.table(IndexedDBTables.activeBundle);

    this.version(this.dbVersion).stores({
      bundles: '++id,account,owner',
    });
    this.bundles = this.table(IndexedDBTables.bundles);

    this.version(this.dbVersion).stores({
      parameterValues: '++id,syncTag',
    });
    this.parameterValues = this.table(IndexedDBTables.parameterValues);

    this.version(this.dbVersion).stores({
      tokens: '++id,token,account,owner',
    });
    this.tokens = this.table(IndexedDBTables.tokens);

    this.version(this.dbVersion).stores({
      exchangeRates: '++id,rate,account,owner',
    });
    this.exchangeRates = this.table(IndexedDBTables.exchangeRates);

    this.version(this.dbVersion).stores({
      pushMessages: '++id,syncTag',
    });
    this.pushMessages = this.table(IndexedDBTables.pushMessages);
  }

  private createDatabaseStorageEvent(detail?: string): Event {
    return new CustomEvent('dbStorage', { detail });
  }
}
