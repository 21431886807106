<nb-card>
  <nb-card-header>
    <span class="d-flex justify-content-between">
      <span class="d-none d-sm-block">{{ tableHeader }}</span>

      <nb-actions class="ms-auto" size="small">
        <nb-action
          *ngIf="importable"
          class="control-item"
          [nbTooltip]="'Import ' + collection | titlecase"
          nbTooltipIcon="file-add-outline"
          [nbTooltipTrigger]="tooltipTrigger"
        >
          <p-fileUpload
            [maxFileSize]="1000000"
            [auto]="true"
            [url]="fileUploadUrl"
            [customUpload]="true"
            chooseIcon="pi pi-file-import"
            mode="basic"
            accept=".xlsx,.xls"
            class="p-0"
            (uploadHandler)="fileLoader.clear(); onUpload($event)"
            #fileLoader
          ></p-fileUpload>
        </nb-action>
        <nb-action
          *ngIf="refreshable"
          (click)="onRefreshRequest()"
          class="control-item"
          icon="refresh-outline"
          [nbTooltip]="'Refresh ' + collection | titlecase"
          nbTooltipIcon="refresh-outline"
          [nbTooltipTrigger]="tooltipTrigger"
        ></nb-action>
        <nb-action
          *ngIf="enrollable"
          (click)="onEnrollRequest()"
          class="control-item"
          icon="person-done-outline"
          [nbTooltip]="'Bulk Enroll ' + collection | titlecase"
          nbTooltipIcon="person-done-outline"
          [nbTooltipTrigger]="tooltipTrigger"
        ></nb-action>
        <nb-action
          *ngIf="printable"
          (click)="onPrintRequest()"
          class="control-item"
          icon="printer-outline"
          [nbTooltip]="'Print ' + collection | titlecase"
          nbTooltipIcon="printer-outline"
          [nbTooltipTrigger]="tooltipTrigger"
        ></nb-action>
        <nb-action
          *ngIf="donable"
          (click)="onDone()"
          class="control-item"
          icon="checkmark-circle-2-outline"
          nbTooltip="Done"
          nbTooltipIcon="checkmark-circle-2-outline"
          [nbTooltipTrigger]="tooltipTrigger"
        ></nb-action>
      </nb-actions>
    </span>
  </nb-card-header>

  <nb-card-body class="overflow-visible">
    <p-toast></p-toast>
    <p-toolbar styleClass="mb-4 gap-2">
      <ng-template pTemplate="right">
        <div class="d-flex flex-wrap justify-content-between">
          <p-multiSelect
            class="flex-fill"
            *ngIf="actionColumns.length"
            [options]="actionColumns"
            [(ngModel)]="selectedActionColumns"
            optionLabel="header"
            selectedItemsLabel="{0} Actions"
            [style]="{ 'min-width': '10rem' }"
            pTooltip="Select Actions To Display"
            tooltipPosition="top"
            placeholder="Choose Actions"
            #actionsSelector
          ></p-multiSelect>

          <p-multiSelect
            class="flex-fill"
            [options]="columns"
            [(ngModel)]="selectedColumns"
            optionLabel="header"
            selectedItemsLabel="{0} Columns"
            [style]="{ 'min-width': '10rem' }"
            pTooltip="Select Columns To Display"
            tooltipPosition="top"
            placeholder="Choose Columns"
            inputId="data-columns"
          ></p-multiSelect>
        </div>
      </ng-template>

      <ng-template pTemplate="left">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="onSearch($event)"
            [placeholder]="'Search ' + collection | titlecase"
            style="max-width: 65vw"
          />
        </span>
      </ng-template>
      <ng-template pTemplate="center">
        <div class="d-flex flex-wrap justify-content-between">
          <p-button
            *ngIf="!exportable && creatable"
            label="New"
            icon="pi pi-plus"
            (onClick)="onCreate()"
            [pTooltip]="'Add New ' + itemName | titlecase"
            tooltipPosition="top"
          >
          </p-button>
          <p-splitButton
            *ngIf="exportable"
            icon="pi pi-plus"
            (onClick)="onCreate()"
            [model]="fileMenuItems"
            [pTooltip]="'Create New ' + itemName | titlecase"
            tooltipPosition="top"
            styleClass="p-button-raised p-button-lg p-button-success"
          ></p-splitButton>
        </div>
      </ng-template>
    </p-toolbar>
    <p-table
      [columns]="selectedColumns"
      [value]="value"
      selectionMode="single"
      [tableStyle]="tableStyle"
      [paginator]="showPaginator"
      [rows]="10"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10, 20, 30]"
      [dataKey]="dataKey"
      (onRowSelect)="onRowSelect($event)"
      (onRowUnselect)="onRowUnselect($event)"
      (selectAllChange)="onSelectAllChange($event)"
      [(selection)]="selectedItems"
      [lazy]="lazyLoad"
      (onLazyLoad)="loadItems($event)"
      [scrollable]="scrollable"
      [scrollHeight]="scrollHeight"
      [resizableColumns]="true"
      columnResizeMode="expand"
      [stateStorage]="stateStorage"
      [stateKey]="stateKey"
      #dataTable
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 4rem !important; max-width: 4rem !important">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th
            *ngIf="customActions && displayCrudActions"
            pResizableColumn
            pTooltip="Read, Update or Delete Items"
            tooltipPosition="top"
          >
            RUD
          </th>
          <th
            *ngIf="paymentActions && displayPaymentsActions"
            pResizableColumn
            pTooltip="Manage Payments"
            tooltipPosition="top"
          >
            Payments
          </th>
          <th
            *ngIf="testActions && displayTestsActions"
            pResizableColumn
            pTooltip="Manage Tests"
            tooltipPosition="top"
          >
            Tests
          </th>
          <th
            *ngIf="achievementActions && displayAchievementsActions"
            pResizableColumn
            pTooltip="Manage Achievements"
            tooltipPosition="top"
          >
            Achievements
          </th>
          <th
            pResizableColumn
            pSortableColumn="{{ col.field }}"
            *ngFor="let col of columns"
          >
            <span class="d-flex">
              <span>{{ col.header }}</span>
              <p-sortIcon field="{{ col.field }}"></p-sortIcon>
            </span>
          </th>
        </tr>
        <tr *ngIf="columnFilters">
          <th *ngFor="let col of columns">
            <p-columnFilter
              type="text"
              field="{{ col.field }}"
            ></p-columnFilter>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td style="width: 4rem !important; max-width: 4rem !important">
            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
          </td>
          <td
            *ngIf="customActions && displayCrudActions"
            style="overflow: visible"
          >
            <p-splitButton
              icon="pi pi-file-edit"
              (onClick)="onEditItemRequest(rowData)"
              (onDropdownClick)="setActiveRow(rowData)"
              [model]="itemActionsMenuItems"
              pTooltip="Edit"
              tooltipPosition="top"
              styleClass="p-button-sm p-button-raised p-button-success"
            ></p-splitButton>
          </td>
          <td
            *ngIf="paymentActions && displayPaymentsActions"
            style="overflow: visible"
          >
            <p-splitButton
              icon="pi pi-money-bill"
              (onClick)="onMakePaymentRequest(rowData)"
              (onDropdownClick)="setActiveRow(rowData)"
              [model]="paymentActionsMenuItems"
              pTooltip="Make payment"
              tooltipPosition="top"
              styleClass="p-button-sm p-button-raised p-button-success"
            ></p-splitButton>
          </td>
          <td
            *ngIf="testActions && displayTestsActions"
            style="overflow: visible"
          >
            <p-splitButton
              icon="pi pi-list"
              (onClick)="onViewTestsRequest(rowData)"
              (onDropdownClick)="setActiveRow(rowData)"
              [model]="testsActionsMenuItems"
              pTooltip="View tests"
              tooltipPosition="top"
              styleClass="p-button-sm p-button-raised p-button-success"
            ></p-splitButton>
          </td>

          <td
            *ngIf="achievementActions && displayAchievementsActions"
            style="overflow: visible"
          >
            <p-splitButton
              icon="pi pi-star"
              (onClick)="award.emit(rowData)"
              (onDropdownClick)="setActiveRow(rowData)"
              pTooltip="Add Award"
              tooltipPosition="top"
              [model]="achievementActionsMenuItems"
              styleClass="p-button-sm p-button-raised p-button-success"
            ></p-splitButton>
          </td>
          <td *ngFor="let col of columns" (click)="onRowClicked(rowData)">
            {{ rowData[col.field] }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </nb-card-body>
</nb-card>
