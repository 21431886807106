/**
 * Gets a random integer between and inclusive of the the supplied ```min``` and ```max```
 *
 * @param min Minimum integer
 * @param max Maximum integer
 * @returns Random integer
 */
export const getRandomInt = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * Constructs a random string of any length
 *
 * @param len Required length of the random string
 */
export const uid = (len: number): string => {
  const buf = [];
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charlen = chars.length;
  for (let i = 0; i < len; ++i) {
    buf.push(chars[getRandomInt(0, charlen - 1)]);
  }
  return buf.join('');
};
