import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, ActivatedRoute, RouterStateSnapshot } from '@angular/router';

import { Observable, of, EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { SpinnerService } from '../core/services/spinner.service';
import { NotificationService } from '../core/services/notification.service';
import { StoreService } from '../core/services/store.service';
import { BundlesService } from '../core/services/bundles.service';
import { IBundle } from '../global-models/bundle.interface';
import { errorMessage } from '../helpers/error-message';

@Injectable()
export class ActiveBundleResolverService  {
  private previousData: IBundle;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: SpinnerService,
    private notifier: NotificationService,
    private store: StoreService,
    private bundlesService: BundlesService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (this.refresh()) {
      this.store.activeBundleRefreshNeeded = false;
      return this.fetchData(state.url);
    }
    this.spinner.hide();
    return of(this.previousData);
  }

  private fetchData(caller: string): Observable<IBundle> {
    this.spinner.show();
    return this.bundlesService.getActiveBundle().pipe(
      tap(bundle => {
        this.previousData = bundle;
        this.spinner.hide();
      }),
      catchError(error => {
        this.spinner.hide();
        this.router.navigate([''], { relativeTo: this.route });
        this.notifier.showError(errorMessage(error));
        throw error;
      }),
    );
  }

  private refresh(): boolean {
    return this.store.activeBundleRefreshNeeded || !this.previousData;
  }
}
