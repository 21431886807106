import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, BehaviorSubject } from 'rxjs';
import { retry, take } from 'rxjs/operators';

import { UserManager, User, WebStorageStateStore } from 'oidc-client';

import { AuthContext } from '../../global-models/auth-context';
import { Constants } from '../../constants';
import { NotificationService } from './notification.service';
import { environment } from '../../../environments/environment';
import { devUserProfile } from '../../helpers/dev-user-profile';
import { IUserProfile } from '../../global-models/user-profile.interface';
import { errorMessage } from '../../helpers/error-message';

@Injectable()
export class AuthService {
  /**App expiry in Unix Time (seconds) */
  appExpiry: number;
  authContext: AuthContext;
  user$ = new BehaviorSubject(undefined);
  token$ = new BehaviorSubject(undefined);
  userManager$ = new BehaviorSubject(undefined);
  private userProfile: any;
  private trackingApiRoot = Constants.trackingApiRoot;
  private usersPath = Constants.usersPath;
  private user: User;
  private userManager: UserManager;
  private devLoginStatus = true;
  private appAccount: string;

  get account(): string {
    return this.appAccount;
  }

  set account(account: string) {
    this.appAccount = account;
  }

  get isAdmin(): boolean {
    return this.isAdminUser();
  }

  get isLoggedIn(): boolean {
    return this.isUserLoggedIn();
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private notifier: NotificationService
  ) {
    const stsSettings = {
      authority: Constants.stsAuthority,
      client_id: Constants.clientId,
      // redirect_uri: `${Constants.clientRoot}/assets/oidc-login-redirect.html`,
      redirect_uri: `${Constants.clientRoot}/?postLogin=true`,
      post_logout_redirect_uri: `${Constants.clientRoot}/?postLogout=true`,
      scope: 'openid profile email user_metadata app_metadata picture',
      response_type: 'code',
      automaticSilentRenew: true,
      silent_redirect_uri: `${Constants.clientRoot}/assets/silent-callback.html`,
      filterProtocolClaims: true,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      metadata: {
        issuer: `${Constants.stsAuthority}/`,
        authorization_endpoint: `${Constants.stsAuthority}/authorize?audience=nirgel-api`,
        userinfo_endpoint: `${Constants.stsAuthority}/userinfo`,
        end_session_endpoint: `${
          Constants.stsAuthority
        }/v2/logout?returnTo=${encodeURI(Constants.clientRoot)}&client_id=${
          Constants.clientId
        }`,
        jwks_uri: `${Constants.stsAuthority}/.well-known/jwks.json`,
        token_endpoint: `${Constants.stsAuthority}/oauth/token`,
      },
    };

    this.userManager = new UserManager(stsSettings);
    this.userManager$.next(this.userManager);

    this.userManager.events.addAccessTokenExpiring(async () => {
      try {
        const user = await this.userManager.signinSilent();
        this.setUser(user);
      } catch (error) {
        console.error(error);
      }
    });

    this.userManager.getUser().then((user) => {
      if (user) {
        this.setUser(user);
      } else {
        this.devLoginStatus = false;
      }
    });
  }

  signup(credentials: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.trackingApiRoot}/${this.usersPath}/signup`,
        credentials
      )
      .pipe(retry(2));
  }

  setUser(user: User) {
    if (user) {
      this.userProfile = user.profile;
    } else {
      this.userProfile = undefined;
    }
    this.user = user;
    this.user$.next(this.userProfile);
    this.token$.next(user?.access_token);
  }

  /**Removes the currently authenticated user's data from storage and logout */
  async removeUser() {
    await this.userManager.removeUser();
    await this.logout();
  }

  async login(forceLogin = false): Promise<void> {
    await this.userManager.signinRedirect();
    return;
  }

  async postLogin() {
    try {
      const user = await this.userManager.signinRedirectCallback();
      this.setUser(user);
      const url: string = this.router.url.substring(
        0,
        this.router.url.indexOf('?')
      );
      this.router.navigateByUrl(url);
    } catch (error) {
      this.notifier.showError(errorMessage(error));
    }
  }

  logout(isOffline = false): Promise<void> {
    const onDev = !environment.deployed;
    if (isOffline || onDev) {
      return this.userManager
        .removeUser()
        .then(() => {
          // For dev purposes
          onDev ? (this.devLoginStatus = false) : null;
        })
        .then(() => {
          this.setUser(undefined);
        })
        .catch((error) => this.notifier.showError(errorMessage(error)))
        .finally(() => {
          this.router.navigateByUrl('/home');
        });
    }

    return this.userManager.signoutRedirect();
  }

  getToken(): string {
    return this.user ? this.user.access_token : '';
  }

  signoutRedirectCallback(): Promise<any> {
    return this.userManager.signoutRedirectCallback();
  }

  loadSecurityContext() {
    this.http
      .get<AuthContext>(`${this.trackingApiRoot}Projects/AuthContext`)
      .subscribe({
        next: (context) => {
          this.authContext = new AuthContext();
          this.authContext.claims = context.claims;
          this.authContext.userProfile = context.userProfile;
        },
        error: (error) => console.error(error),
      });
  }

  getUserProfile(): IUserProfile {
    if (this.userProfile) {
      const roles: string[] = this.userProfile[Constants.userRoles];
      const role = roles.includes('admin') ? 'admin' : roles[roles.length - 1];
      const userMetadata = this.userProfile[Constants.userMetadata];

      // TODO: Oidc client used to return userMetadata (from userInfo endpoint) as an object but now is returning an array. Check if is bug or not
      // Below is a work-around to cover for the two scenarios for now
      const account: string =
        userMetadata instanceof Array
          ? userMetadata[0].account
          : userMetadata.account;

      const {
        sub,
        nickname,
        name,
        email_verified: emailVerified,
        email: userEmail,
      } = this.userProfile;
      return {
        userEmail,
        role,
        roles,
        account,
        sub,
        nickname,
        name,
        emailVerified,
      };
    }

    // For dev purposes across devices on LAN
    if (environment.trackingApiRoot.includes('jaspermaposa')) {
      return devUserProfile;
    }
  }

  async getUser() {
    const user = await this.userManager.getUser();
    this.setUser(user);
    return user;
  }

  private isUserLoggedIn(): boolean {
    // For dev purposes
    if (!environment.deployed) {
      return !!(this.user && this.user.access_token);
    }

    return this.user && this.user.access_token && !this.user.expired;
  }

  private isAdminUser(): boolean {
    return this.getUserProfile().role === 'admin';
  }
}
