import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

/**
 * Forces revalidation of all FormControls on a given FormGroup
 *
 * The revalidation only happens if the form in question is invalid.
 * This guards against submitting an invalid form.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorForceCheckService {
  /**
   * Forces revalidation of every FormControl in an invalid FormGroup
   *
   * @returns true if the form is invalid
   * @returns false if the form is valid
   *
   * @param form A FormGroup instance to be revalidated
   */
  recheck(form: UntypedFormGroup): boolean {
    if (!form.valid) {
      Object.keys(form.controls).forEach((key) => {
        const c: AbstractControl | null = form.get(key);
        c?.markAsTouched();
        c?.markAsDirty();
        c?.updateValueAndValidity();
        if ((c as UntypedFormGroup).controls) {
          this.recheck(c as UntypedFormGroup);
        }
      });
      return true;
    }
    return false;
  }
}
