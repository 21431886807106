import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';

import { Subscription } from 'rxjs';
import { errorMessage } from '../../helpers/error-message';

import { ValidationErrorCheckService } from '../../core/services/validation-error-check.service';
import { trimValue } from '../../core/validators/trim-value.validator';
import { SpinnerService } from '../../core/services/spinner.service';

/**
 * A customisable modal popup
 *
 * It uses bootstrap classes and needs to have a triggering element such as a button
 * click. The triggering element must have ```data-toggle="modal"``` and ```data-target="#modal-custom-id"```
 * attributes. The ```modal-custom-id``` must match the id of the modal pop-up and is passed through
 * the ```indentifier``` or optionally in combination with the ```index``` inputs. When used as a form, it
 * consists of two input fields; one is hidden with name of ```_id``` and the other is named ```name```.
 * When the confirmation button on the pop-up is clicked, it emits whatever is in these two
 * fields through the ```formData``` output. The modal pop-up will always appear centred on the
 * screen nomatter where you place its tags in the template body element.
 *
 * @input ```title``` The title you wish to give to the modal pop-up. Defaults to an empty string.
 * @input ```identifier``` The id you wish to give to the modal pop-up. It is required.
 * @input ```index``` The suffix you wish to add to the ```identifier```. Defaults to an empty string.
 * @input ```label``` The text to appear as the input field label. Defaults to 'New Name'.
 * @input ```buttonText``` The text to appear on the confirm buttom. Defaults to 'Save'.
 * @input ```isForm``` Flag to set the pop-up as a form or just a normal dialog. Defaults to ```true```.
 * @input ```bodyText``` The body text you wish to display. Defaults to an empty string.
 * @input ```placeholder``` The value for the input ```placeholder``` attribute. Defaults to ```text```.
 * @input ```inputType``` The value for the ```type``` attribute of the ```input``` field. Defaults to 'New Name'.
 * @input ```target``` The object you want the modal pop-up to be based on. It must have at least a
 *        ```name``` and an ```_id``` field. Defaults to ```null```.
 * @emits formData { name: "input-by-the-user", id: "id-of-the-target"}
 *
 * @usage
 * ```html
 * <div *ngFor="let obj in objects; let i=index">
 *  <button data-toggle="modal"
 *          attr.data-target="{{ '#edit-name' + index }}">Edit Name</button>
 *  <app-modal-popup
 *    (formData)="handleData($event)" [target]="obj"
 *    identifier="edit-name" [index]="i"
 *    title="Edit My Object" label="Name"></app-modal-popup>
 * </div>
 * ```
 */
@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss'],
})
export class ModalPopupComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() bodyText: string;
  @Input() label: string;
  @Input() identifier: string;
  @Input() index: any;
  @Input() buttonText: string;
  @Input() isForm: boolean;
  @Input() placeholder: string;
  @Input() inputType: string;
  @Input() target: Partial<{ name: string; _id: string }>;
  @Output() formData: EventEmitter<{ id: string; name: string }>;

  formErrors: any;
  nameEditForm: UntypedFormGroup;

  private subscriptions = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private errorCheck: ValidationErrorCheckService,
    public spinner: SpinnerService
  ) {
    this.formData = new EventEmitter();
  }
  ngOnDestroy(): void {
    // Weird, but had to do this because of Karma complaining of not seeing 'this' in tests
    // this ? this.backdrop.hide() : '';
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.initialiseForm();
    this.listenForValidationErrors();
    this.patchBaseInformation();
    this.applyDefaults();
  }

  emitFormData() {
    this.formData.emit(this.nameEditForm.value);
  }

  private applyDefaults() {
    if (this.title === undefined) {
      this.title = '';
    }
    if (this.label === undefined) {
      this.label = 'New Name';
    }
    if (this.placeholder === undefined) {
      this.placeholder = 'New Name';
    }
    if (this.bodyText === undefined) {
      this.bodyText = '';
    }
    if (this.buttonText === undefined) {
      this.buttonText = 'Save';
    }
    if (this.index === undefined) {
      this.index = '';
    }
    if (this.inputType === undefined) {
      this.inputType = 'text';
    }
    if (this.isForm === undefined) {
      this.isForm = true;
    } else {
      // Remove validators on the now hidden ```name``` field so as to enable the confirm button
      const name = this.nameEditForm.get('name');
      name.clearValidators();
      name.updateValueAndValidity();
    }
  }

  private initialiseForm() {
    this.nameEditForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(15), trimValue]],
      id: '',
    });
  }

  private listenForValidationErrors(): void {
    const sub = this.errorCheck
      .errorCheck(this.nameEditForm)
      .subscribe({
        next: (errors) => (this.formErrors = errors),
        error: (error) => {
          throw Error(errorMessage(error));
        },
      })
      .unsubscribe();
    this.subscriptions.add(sub);
  }

  private patchBaseInformation() {
    if (!this.target) {
      return;
    }

    const id = this.target._id || '';
    const name = this.target.name || '';
    this.nameEditForm.patchValue({ id, name });
  }
}
