import { Component, OnInit, OnDestroy } from '@angular/core';
import { AutoUnsubscribe } from '../helpers/observable-auto-unsubscribe';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
@AutoUnsubscribe()
export class AccountComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit(): void {}
  ngOnDestroy(): void {}
}
