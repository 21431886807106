<mat-card
  appearance="outlined"
  *ngIf="!isSideNav && hasHeaderTop"
  class="container-fluid rounded-0 py-2 py-sm-0 px-0 m-0 d-flex flex-column navbar-transparent"
  id="header-top"
>
  <nav class="navbar navbar-expand-sm navbar-light navbar-inverse px-3 py-1">
    <span class="navbar-brand">
      <a href="mailto:{{ mainContactEmail }}" class="d-flex" title="Email us">
        <mat-icon aria-hidden="true" svgIcon="email"></mat-icon>
        <span class="spacer" aria-hidden="true"></span>
        <span class="align-self-center word">
          <span *ngIf="vw !== 'xsm'">
            {{ mainContactEmail }}
          </span>
          <span *ngIf="vw === 'xsm'">Email</span>
        </span>
      </a>
    </span>
    <span class="navbar-brand" id="last-navbar-brand">
      <a
        href="tel:{{
          mainContactNumber ? (mainContactNumber | phone) : ('' | phone)
        }}"
        class="d-flex"
        title="Call us"
      >
        <mat-icon aria-hidden="true" svgIcon="phone"></mat-icon>
        <span class="spacer" aria-hidden="true"></span>
        <span class="align-self-center word">
          <span *ngIf="vw !== 'xsm'">
            {{ mainContactNumber }}
          </span>
          <span *ngIf="vw === 'xsm'">Call</span>
        </span>
      </a>
    </span>

    <div class="collapse navbar-collapse justify-content-end me-3">
      <smx-social
        [socialProfile]="socialProfile"
        iconColor="gold"
        [index]="1"
      ></smx-social>
    </div>
  </nav>
</mat-card>

<mat-card
  appearance="outlined"
  *ngIf="!isSideNav"
  [ngClass]="{
    'container-fluid rounded-0 p-0 sticky-top': true
  }"
  id="nav-wrapper"
>
  <span *ngIf="progressBarPosition === 'bottom'; else progBar"></span>
  <nav class="navbar navbar-expand-md navbar-light navbar-inverse px-3">
    <span class="navbar-brand">
      <span>
        <img
          [ngClass]="{
            'extra-small-screen': vw === 'xsm',
            'small-screen': vw === 'sm',
            'large-screen':
              vw === 'md' || vw === 'lg' || vw === 'xl' || vw === 'xxl'
          }"
          src="{{ institutionFullLogo }}"
          alt="{{ institutionAbreviation + ' logo' }}"
        />
      </span>
    </span>
    <button
      [ngClass]="{
        'navbar-toggler': true,
        'extra-small-screen': vw === 'xsm'
      }"
      type="button"
      id="hamburger"
      data-bs-toggle="collapse"
      data-bs-target="#collapsible"
      aria-controls="collapsible"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div
      class="collapse navbar-collapse justify-content-end me-3"
      id="collapsible"
    >
      <div class="dropdown-divider"></div>
      <smx-navbar *ngIf="!hideNavLinks" [navLinks]="navLinks"></smx-navbar>
      <smx-navbar
        *ngIf="showAuxNavLinks"
        [navLinks]="auxNavLinks ? auxNavLinks : []"
      ></smx-navbar>
      <smx-user-profile
        *ngIf="showUserProfile"
        [user]="user"
      ></smx-user-profile>
    </div>
  </nav>
  <span *ngIf="progressBarPosition === 'top'; else progBar"></span>
</mat-card>

<ng-template #progBar>
  <div class="progress rounded-0" *ngIf="hasProgressBar">
    <div
      class="progress-bar transparent"
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax="100"
      attr.aria-valuenow="{{ scrollPercent }}"
      aria-valuemin="0"
      aria-valuemax="100"
      title="Page scroll progress"
      [style]="'width: ' + scrollPercent + '%'"
    ></div>
  </div>
</ng-template>

<smx-side-nav *ngIf="isSideNav" [navLinks]="navLinks"></smx-side-nav>

<div class="social" *ngIf="hasFloatingSocial">
  <smx-social
    *ngIf="navFixed && !vw.includes('s')"
    [socialProfile]="socialProfile"
    orientation="vertical"
    [iconColor]="socialIconColor"
  ></smx-social>
</div>
