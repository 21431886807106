import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { Constants } from '../../constants';
import { environment } from '../../../environments/environment';
import { IBundle } from '../../global-models/bundle.interface';
import { AuthService } from './auth.service';
import { ITrackable } from '../../tracking/interfaces-enums/trackable';
import { IndexDbService } from './index-db.service';
import { IMutesIndexedDb } from '../../tracking/interfaces-enums/muted-broadcast';
import { NotificationService } from './notification.service';
import { errorMessage } from '../../helpers/error-message';
import { IAccountDetails } from '../../tracking/interfaces-enums/account-details';
import { Router } from '@angular/router';
import { ITeamMember } from '../../global-models/team-member';
import { IMonitoredParameterLive } from '../../tracking/interfaces-enums/monitored-parameter-live';

@Injectable()
export class StoreService {
  /**Flag indicating whether the ActiveBundle resolver is stale and in need of a network refresh */
  activeBundleRefreshNeeded = false;

  /**Unix date for app expiry */
  appExpiry: number;

  /**Flag indicating whether the Bundles resolver is stale and in need of a network refresh */
  bundlesRefreshNeeded = false;

  /**The external url requested by the app to navigate to */
  externalUrl: string;

  /**Flag indicating whether the MutedBroadcasts resolver is stale and in need of a network refresh */
  mutedBroadcastsRefreshNeeded = false;

  /**Flag indicating whether the Team resolver is stale and in need of a network refresh */
  teamRefreshNeeded = false;

  /**Flag indicating whether the Trackables resolver is stale and in need of a network refresh */
  trackablesRefreshNeeded = false;

  /**Flag indicating whether a Trackable has just been created */
  justCreatedTrackable: boolean;

  isOnline$ = new BehaviorSubject<boolean>(false);
  user$ = new BehaviorSubject<string>(undefined);
  account$ = new BehaviorSubject<string>(undefined);
  trackables$ = new BehaviorSubject<ITrackable[]>(undefined);
  mutedBroadcasts$ = new BehaviorSubject<IMutesIndexedDb>(undefined);
  accountDetails$ = new BehaviorSubject<IAccountDetails>(undefined);
  activeBundle$ = new BehaviorSubject<IBundle>(undefined);
  bundles$ = new BehaviorSubject<IBundle[]>(undefined);
  exchangeRate$ = new BehaviorSubject<number>(undefined);
  team$ = new BehaviorSubject<ITeamMember[]>(undefined);

  private ZWD2USD: number;
  private myAccount: IAccountDetails;
  private myMutes: IMutesIndexedDb;
  private myTrackables: ITrackable[];
  private myTeam: ITeamMember[];
  private user: string;
  private account: string;
  private currentBundle: any;
  private currentRoute: string;
  private onlineStatus: boolean;
  private previousRoute: string;
  private allBundles: IBundle[];
  private _trackerLive = {};

  /**User's Active Bundle */
  get activeBundle(): IBundle {
    return this.currentBundle;
  }

  /**User's Active Bundle */
  set activeBundle(bundle: IBundle) {
    this.currentBundle = bundle;
    this.activeBundle$.next(bundle);
  }

  get appExpired(): boolean {
    return this.appIsExpired();
  }

  /**All Nirgel bundles */
  get bundles(): IBundle[] {
    !this.allBundles ? (this.bundlesRefreshNeeded = true) : null;
    return this.allBundles;
  }

  /**All Nirgel bundles */
  set bundles(allBundles: IBundle[]) {
    this.allBundles = allBundles;
    this.bundles$.next(allBundles);
  }

  /**App current route */
  get currentUrl() {
    return (
      this.currentRoute ||
      localStorage.getItem(
        `current-url:${this.user}:${this.account}:${Constants.appNamespace}`
      )
    );
  }

  /**App current route */
  set currentUrl(url: string) {
    if (!this.user || !this.account || url === '/external-page') return;

    const sanitisedUrl = url === '/' ? '/home' : url;
    this.currentRoute = sanitisedUrl;
    localStorage.setItem(
      `current-url:${this.user}:${this.account}:${Constants.appNamespace}`,
      sanitisedUrl
    );
  }

  /**App previous route */
  get previousUrl() {
    return (
      this.previousRoute ||
      localStorage.getItem(
        `previous-url:${this.user}:${this.account}:${Constants.appNamespace}`
      ) ||
      '/home'
    );
  }

  /**App previous route */
  set previousUrl(url: string) {
    if (!this.user || !this.account || url === '/external-page') return;

    const sanitisedUrl = url === '/' ? '/home' : url;
    this.previousRoute = sanitisedUrl;
    localStorage.setItem(
      `previous-url:${this.user}:${this.account}:${Constants.appNamespace}`,
      sanitisedUrl
    );
  }

  /**Flag for app online status */
  get isOnline(): boolean {
    return this.onlineStatus;
  }

  /**Setting the app online status */
  set isOnline(status: boolean) {
    this.onlineStatus = status;
    this.isOnline$.next(status);
  }

  /**User's Trackables */
  get trackables() {
    return this.myTrackables;
  }

  /**User's Trackables */
  set trackables(trackables: ITrackable[]) {
    this.myTrackables = trackables;
    this.trackables$.next(trackables);
  }

  /**User's Team */
  get team() {
    return this.myTeam;
  }

  /**User's Team */
  set team(team: ITeamMember[]) {
    this.myTeam = team;
    this.team$.next(team);
  }

  /**User's MutedBroadcasts */
  get mutedBroadcasts() {
    return this.myMutes;
  }

  /**User's MutedBroadcasts */
  set mutedBroadcasts(mutes: IMutesIndexedDb) {
    this.myMutes = mutes;
    this.mutedBroadcasts$.next(mutes);
  }

  /**Trackables' trackerLiveData. ```trackableId``` is the key */
  get trackerLive() {
    if (this._trackerLive && Object.entries(this._trackerLive).length)
      return this._trackerLive;
    let tLive: any;
    const tLiveRaw = localStorage.getItem(
      `trackerLive:${this.user}:${this.account}:${Constants.appNamespace}`
    );
    if (tLiveRaw) {
      return JSON.parse(tLiveRaw);
    }
    return {};
  }

  /**User's MutedBroadcasts */
  get accountDetails() {
    return this.myAccount;
  }

  /**User's MutedBroadcasts */
  set accountDetails(details: IAccountDetails) {
    this.myAccount = details;
    this.accountDetails$.next(details);
  }

  /**ZWD to USD exchange rate */
  get rate(): number {
    return this.ZWD2USD;
  }

  /**ZWD to USD exchange rate */
  set rate(rate: number) {
    this.ZWD2USD = rate;
    this.exchangeRate$.next(rate);
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private db: IndexDbService,
    private notifier: NotificationService
  ) {
    // TODO: Find a better way to have AuthService fully loaded when StoreService loads
    //! As it stands, getUserProfile returns undefined when StoreService first loads
    //! and as work-around I created an observable that listens when userProfile is
    //! available on AuthService and then updates the StoreService user & account variables
    this.authService.user$.subscribe((profile) => {
      if (profile) {
        const { sub, account } = this.authService.getUserProfile();
        this.account = account;
        this.user = sub;

        this.user$.next(this.user);
        this.account$.next(this.account);

        this.loadDataFromIndexedDb();
      } else {
        this.user$.next(undefined);
        this.account$.next(undefined);
      }
    });
  }

  /**
   * Updates the persisted TrackerLive object
   *
   * @param trackableId Trackable ID
   * @param trackerLiveData TrackerLiveData to be updated
   */
  setTrackerLive(
    trackableId: string,
    trackerLiveData: IMonitoredParameterLive[]
  ) {
    if (!this._trackerLive) {
      this._trackerLive = {};
    }
    this._trackerLive[trackableId] = trackerLiveData;
    localStorage.setItem(
      `trackerLive:${this.user}:${this.account}:${Constants.appNamespace}`,
      JSON.stringify(this._trackerLive)
    );
  }

  private loadDataFromIndexedDb() {
    this.db.dbReady$.subscribe(async (isReady) => {
      try {
        if (isReady) {
          this.trackables = await this.db.trackables
            .where('owner')
            .equals(this.user)
            .and((mb) => mb.account === this.account)
            .toArray();

          this.team = await this.db.team
            .where('owner')
            .equals(this.user)
            .and((tm) => tm.account === this.account)
            .toArray();

          this.mutedBroadcasts = await this.db.mutedBroadcasts
            .where('owner')
            .equals(this.user)
            .and((mb) => mb.account === this.account)
            .first();

          this.accountDetails = await this.db.accountDetails
            .where('owner')
            .equals(this.user)
            .and((mb) => mb.account === this.account)
            .first();

          this.activeBundle = await this.db.activeBundle
            .where('owner')
            .equals(this.user)
            .and((bndl) => bndl.account === this.account)
            .first();

          const dbRate = await this.db.exchangeRates
            .where('owner')
            .equals(this.user)
            .and((er) => er.account === this.account)
            .first();
          dbRate ? (this.rate = dbRate.rate) : null;

          this.bundles = await this.db.bundles
            .where('owner')
            .equals(this.user)
            .and((bndl) => bndl.account === this.account)
            .sortBy('weight');
        }
      } catch (error) {
        this.notifier.showError(errorMessage(error));
      }
    });
  }

  private appIsExpired(): boolean {
    const appExpired = () => {
      const expiryUnix = this.appExpiry
        ? this.appExpiry
        : JSON.parse(
            localStorage.getItem(
              `app-expiry:${this.user}:${this.account}:${Constants.appNamespace}`
            )
          );
      if (expiryUnix) return expiryUnix < Date.now() / 1000;
      return false;
    };

    //? This must not be commented if app is subscription based
    // if (appExpired()) {
    //   this.notifier.showError(
    //     'Sorry your subscription has expired. Please top up to enjoy full app features.'
    //   );
    //   this.router.navigate(['/admin/account/make-payment']);
    //   return true;
    // }
    return false;
  }
}
