import { Injectable } from '@angular/core';

/**
 * Provides an object of key & values pairs depicting validation name and validation error message
 */
@Injectable()
export class ValidationMessagesService {
  validationMessages: any = {
    // Custom errors
    integer: 'Please enter an integer',
    numeric: 'Please enter a numeric value',
    selection: 'Please select a single choice',
    dateOrder: 'End date must be greater than start date',
    specialCharacter: 'Should contain at least one special character',
    digit: 'Should contain at least one digit',
    lowercase: 'Should contain at least one lowercased character',
    uppercase: 'Should contain at least one uppercased character',
    passwordMatch: 'Passwords not matching',
    letter: 'Should contain at least one letter',
    letterFirst: 'Should begin with a letter',
    trimValue: 'No leading or trailing spaces allowed',

    // Inbuilt errors
    email: 'Please enter a valid email',
    required: 'Please enter a valid value for this field',
    min: 'Value is below the accepted limit',
    minlength: 'Value is shorter than the accepted limit',
    maxlength: 'Value is longer than the accepted limit',

    max: 'Value is over the accepted limit',
  };

  appendMessages(messages: Record<string, string>) {
    this.validationMessages = Object.assign(
      {},
      this.validationMessages,
      messages
    );
  }

  getMessages() {
    return this.validationMessages;
  }
}
