import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';

import { errorMessage } from '../../helpers/error-message';
import { Constants } from '../../constants';
import { IMutedBroadcast, IMutes, IMutesIndexedDb } from '../../tracking/interfaces-enums/muted-broadcast';
import { StoreService } from './store.service';
import { IndexDbService } from './index-db.service';
import { NotificationService } from './notification.service';

@Injectable()
export class BroadcastsService {
  private trackingApiRoot = Constants.trackingApiRoot;
  private broadcastsPath = Constants.broadcastsPath;
  private account: string;
  private owner: string;
  private dbMutedBroadcasts: IMutesIndexedDb;

  constructor(
    private http: HttpClient,
    private store: StoreService,
    private db: IndexDbService,
    private notifier: NotificationService,
  ) {
    this.store.account$.subscribe(account => {
      if (account) {
        this.account = account;
      }
    });

    this.store.user$.subscribe(user => {
      if (user) {
        this.owner = user;
      }
    });

    this.store.mutedBroadcasts$.subscribe(mutedBroadcasts => {
      if (mutedBroadcasts) {
        this.dbMutedBroadcasts = mutedBroadcasts;
      }
    });
  }

  muteBroadcasts(data: { mutes: IMutedBroadcast[] }): Observable<any> {
    return this.http.post<any>(`${this.trackingApiRoot}/${this.broadcastsPath}/mute`, data);
  }

  unmuteBroadcasts(data: { unmutes: string[] }): Observable<any> {
    return this.http.post<any>(`${this.trackingApiRoot}/${this.broadcastsPath}/unmute`, data).pipe();
  }

  getMutedBroadcasts(): Observable<IMutes> {
    let fromIndexedDb = false;
    return this.http.get<IMutes>(`${this.trackingApiRoot}/${this.broadcastsPath}/get`).pipe(
      retry(!this.dbMutedBroadcasts && !this.store.isOnline ? 50 : 2),
      catchError(error => {
        if (
          this.dbMutedBroadcasts &&
          error instanceof HttpErrorResponse &&
          (error.status === 0 || error.status === 504)
        ) {
          fromIndexedDb = true;
          return of(this.dbMutedBroadcasts);
        }
        throw error;
      }),
      tap<IMutes>(data => {
        if (!fromIndexedDb && this.account && this.owner) {
          if (data.mutesObjects) {
            const mutesObj = Object.assign({}, data, { account: this.account, owner: this.owner });
            this.db.mutedBroadcasts
              .where('account')
              .equals(this.account)
              .and(mute => mute.owner === this.owner)
              .delete()
              .then(() => {
                this.db.mutedBroadcasts
                  .add(mutesObj)
                  .then(_ => (this.store.mutedBroadcasts = mutesObj))
                  .catch(error => {
                    this.notifier.showError(errorMessage(error));
                  });
              })
              .catch(error => {
                this.notifier.showError(errorMessage(error));
              });
          }
        }
        fromIndexedDb = false;
      }),
    );
  }
}
