<mat-sidenav-container class="sidenav-container example-container">
  <mat-sidenav
    class="sidenav"
    mode="side"
    #sideNav
    [attr.role]="isHandset ? 'dialogue' : 'navigation'"
    [mode]="(isHandset | async)!.matches ? 'over' : 'side'"
    [(opened)]="opened"
    (openedChange)="openedChangeHandler()"
  >
    <mat-nav-list>
      <smx-mat-menu-wrapper
        *ngFor="let lnk of navLinks"
        [matMenuItem]="lnk"
      ></smx-mat-menu-wrapper>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <main>
      <ng-content></ng-content>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
