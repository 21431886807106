<ul class="navbar-nav">
  <li
    *ngFor="let lnk of navLinks"
    class="{{ lnk.listItemClass || '' }}"
    [ngClass]="{ 'nav-item': true, dropdown: lnk.isDropdown }"
  >
    <smx-nav-link
      [link]="lnk.link"
      [label]="lnk.label"
      [role]="lnk.role || ''"
      [id]="lnk.id || ''"
      [raised]="lnk.raised"
      [linkIsExternal]="lnk.linkIsExternal"
      [isDropdown]="lnk.isDropdown"
      [listItemClass]="lnk.listItemClass || ''"
      [linkClass]="lnk.linkClass || ''"
      [dropdownItems]="lnk.dropdownItems"
      [title]="lnk.title || ''"
      [hasPopover]="lnk.hasPopover"
      [popoverTriggers]="lnk.popoverTriggers"
      [popoverContent]="lnk.popoverContent"
      [color]="lnk.color"
      [preIcon]="lnk.preIcon"
      [postIcon]="lnk.postIcon"
    ></smx-nav-link>
  </li>
</ul>
