import { AbstractControl } from '@angular/forms';

export const lowercase = (c: AbstractControl): { [key: string]: boolean } | null => {
  const value = c.value;
  const pattern = /[a-z]+/;
  if (!pattern.test(value)) {
    return { lowercase: true };
  }
  return null;
};
