import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormGroup, UntypedFormArray } from '@angular/forms';

import { BehaviorSubject, Subscription } from 'rxjs';

// import { ValidationMessagesService } from './validation-messages.service';
// import { errorMessage } from '../../helpers/error-message';

/**
 * Checks for FormControl validation errors on a ReactiveForm instance
 *
 * It constructs an object with key names that mirrors the hirerachial FormControl names in the form.
 * Each hirerachy is separated from the next by a dash ('-'). Therefore dashes are not allowed
 * in the names of any AbstractControl in the form.
 * The value of each key is a string of validation error messages, if any, of the
 * respective FormControl instance, otherwise it is an empty string.
 *
 * @example
 *
 * ```typescript
 * // In component
 * import { ValidationErrorCheckService } from 'path-to/validation-error-check.service';
 *
 * export class MyComponent implements OnInit {
 *    myForm: FormGroup;
 *    formErrors: any;  // Errors container
 *
 *    constructor(private fb: FormBuilder, private errorCheck: ValidationErrorCheckService) {}
 *
 *    ngOnInit() {
 *      this.myForm = this.fb.group({
 *        myEmail: ['', [Validators.required, Validators.email]],
 *        level1SubForm: this.fb.group({
 *          input1: [ '', [Validators.required]],
 *          level2SubForm: this.fb.group({
 *            input2: [ '', [Validators.required]]
 *          }, { validator: someValidator })
 *        }, { validator: someValidator }),
 *        myFormArray: this.fb.array([
 *          this.fb.group({
 *            input3: [ '', [Validators.required]]
 *          }, { validator: someValidator }),
 *          this.fb.group({
 *            input4: [ '', [Validators.required]]
 *          }, { validator: someValidator })
 *        ])
 *      });
 *
 *   this.errorCheck.errorCheck(this.myForm)     // Pass in form instance
 *     .subscribe(errorObject => this.formErrors = errorObject);  // Subscribe and pass the errorObject to your error container instance
 * }
 * ```
 * @example
 *
 * ```html
 * <!--In template-->
 *
 * <input type="email" id="email" formControlName="myEmail" [ngClass]="{'is-invalid': formErrors['myEmail']}">
 * <span>{{ formErrors['myEmail'] }}</span>
 *
 * <div formGroupName="level1SubForm" [ngClass]="{'is-invalid': formErrors['level1SubForm']}">
 *  <input type="text" formControlName="input1" [ngClass]="{'is-invalid': formErrors['level1SubForm-input1']}">
 *  <span>{{ formErrors['level1SubForm-input1'] }}</span>
 *  <div formGroupName="level2SubForm" [ngClass]="{'is-invalid': formErrors['level1SubForm-level2SubForm']}">
 *    <input type="text" formControlName="input2" [ngClass]="{'is-invalid': formErrors['level1SubForm-level2SubForm-input2']}">
 *    <span>{{ formErrors['level1SubForm-level2SubForm-input2'] }}</span>
 *  </div>
 *  <span>{{ formErrors['level1SubForm-level2SubForm'] }}</span>
 * </div>
 * <span>{{ formErrors['level1SubForm'] }}</span>
 *
 * <div formArrayName="myFormArray" [ngClass]="{'is-invalid': formErrors['myFormArray']}"> *
 *    <div formGroupName="0" [ngClass]="{'is-invalid': formErrors['myFormArray-0']}">
 *      <input type="text" formControlName="input3" [ngClass]="{'is-invalid': formErrors['myFormArray-0-input3']}">
 *      <span>{{ formErrors['myFormArray-0-input3'] }}</span>
 *    </div>
 *    <span>{{ formErrors['myFormArray-0'] }}</span>
 *    <div formGroupName="1" [ngClass]="{'is-invalid': formErrors['myFormArray-1']}">
 *      <input type="text" formControlName="input4" [ngClass]="{'is-invalid': formErrors['myFormArray-1-input4']}">
 *      <span>{{ formErrors['myFormArray-1-input4'] }}</span>
 *    </div>
 *    <span>{{ formErrors['myFormArray-1'] }}</span>
 * </div>
 * <span>{{ formErrors['myFormArray'] }}</span>
 * ```
 */
@Injectable({
  providedIn: 'root',
})
export class ValidationErrorCheckService implements OnDestroy {
  private subscriptions = new Subscription();
  private updatedErrorDic$ = new BehaviorSubject({});

  validationMessages: Record<string, string> = {
    // Custom errors
    integer: 'Please enter an integer',
    numeric: 'Please enter a numeric value',
    selection: 'Please select a single choice',
    dateOrder: 'End date must be greater than start date',
    specialCharacter: 'Should contain a special character',
    digit: 'Should contain a digit',
    lowercase: 'Should contain a lowercased character',
    uppercase: 'Should contain an uppercased character',
    passwordMatch: 'Passwords not matching',
    letter: 'Should contain a letter',
    letterFirst: 'Should begin with a letter',
    trimValue: 'No leading/trailing spaces allowed',
    year: `Please enter a 4-digit year, e.g. 2022`,

    // Inbuilt errors
    email: 'Please enter a valid email',
    required: 'Please enter a value',
    min: 'Number is below limit',
    max: 'Number is over limit',
    minlength: 'Value length is too short',
    maxlength: 'Value length is too long',
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Append an extra error messages dictionary
   *
   * @param messages An object containing key-value pairs of validation name and the corresponding error message to be displayed
   */
  appendMessages(messages: Record<string, string>) {
    this.validationMessages = Object.assign(
      {},
      this.validationMessages,
      messages
    );
  }

  /**
   * Listens for value changes on each FormControl and reports any validation errors found
   *
   * @param form FormGroup instance whose controls are to be validated
   *
   * @emits errorMessages object
   */
  errorCheck(form: UntypedFormGroup): BehaviorSubject<Record<string, string>> {
    const errorMessages = {};
    this.recursor(form, errorMessages, undefined);

    return this.updatedErrorDic$;
  }

  /**
   * Recursively analyses a FormGroup or a FormArray instance
   *
   * @param form FormGroup instance to be analysed
   * @param errorMessages The literal object instance to hold the FormGroup instance's errors
   * @param identifier Dash separated string identifying the FormGroup instance hirerachy
   */
  private recursor(
    form: UntypedFormGroup | UntypedFormArray,
    errorMessages: Record<string, string>,
    identifier: string | undefined
  ) {
    Object.keys(form.controls).forEach((key) => {
      const fc = form.get(key);

      ({ identifier, errorMessages } = this.initialiseErrorMessagesObject(
        form,
        errorMessages,
        identifier,
        key
      ));

      const name = identifier ? identifier : key;
      const sub = fc?.valueChanges.subscribe({
        next: (_) => {
          this.setMessage(fc, name, errorMessages);
        },
        error: (error) => {
          console.error(error);
        },
      });

      // Add subscription for mass unsubcription when service is destroyed
      this.subscriptions.add(sub);

      if ((fc as UntypedFormGroup).controls) {
        this.recursor(fc as UntypedFormGroup, errorMessages, name);
      }
    });
  }

  private initialiseErrorMessagesObject(
    form: UntypedFormGroup | UntypedFormArray,
    errorMessages: any,
    identifier: string | undefined,
    currentKey: string
  ): any {
    if (identifier !== undefined) {
      const prefixes = identifier.split('-');
      if (prefixes.length > 1) {
        const previousControlLabel = prefixes[prefixes.length - 1];
        const previousControl = form.get(previousControlLabel);
        if (previousControl && previousControl.parent === form) {
          // These are siblings and no parent-child relationship exists between the two
          // therefore the current control must not include the previous control's name in its
          // name hierarchy
          prefixes.pop();
        }
        identifier = prefixes.join('-');
      }
      identifier += `-${currentKey}`;
      errorMessages[identifier] = '';
    } else {
      errorMessages[currentKey] = '';
    }
    return { identifier, errorMessages };
  }

  private setMessage(
    c: AbstractControl,
    controlName: string,
    errorDic: any
  ): void {
    errorDic[controlName] = '';

    if ((c.touched || c.dirty) && c.errors) {
      try {
        errorDic[controlName] = Object.keys(c.errors)
          .map((key) => {
            return this.getMessages()[key];
          })
          .join('. ');
        this.updatedErrorDic$.next(errorDic);
      } catch (error: any) {
        if (error.name && !error.name.includes('TypeError')) {
          console.error(error);
        }
      }
    }
  }

  private getMessages() {
    return this.validationMessages;
  }
}
