import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { BackdropManagerService } from '../services/backdrop-manager.service';
import { Constants } from '../../constants';
import { digit } from '../validators/digit.validator';
import { emailDot } from '../validators/email.validator';
import { lowercase } from '../validators/lowercase.validator';
import { specialCharacter } from '../validators/special-character.validator';
import { SpinnerService } from '../services/spinner.service';
import { uppercase } from '../validators/uppercase.validator';
import { ValidationErrorCheckService } from '../services/validation-error-check.service';
import { AutoUnsubscribe } from '../../helpers/observable-auto-unsubscribe';
import { errorMessage } from '../../helpers/error-message';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
@AutoUnsubscribe()
export class LoginComponent implements OnInit, OnDestroy {
  formErrors: any;
  isLoading = false;
  loginForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private errorCheck: ValidationErrorCheckService,
    private router: Router,
    private spinner: SpinnerService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: [
        '',
        [
          Validators.required,
          // Validators.minLength(8),
          // specialCharacter,
          // digit,
          // lowercase,
          // uppercase
        ],
      ],
    });

    this.errorCheck.errorCheck(this.loginForm).subscribe(
      (errors) => (this.formErrors = errors),
      (error) => {
        throw Error(errorMessage(error));
      }
    );
  }

  ngOnDestroy(): void {}

  login(loginType: string) {
    // const formData = this.loginForm.value;
    // this.authService.login(formData, loginType)
    //   .subscribe(credentials => {
    //     console.log(credentials);
    //     this.storeToken(credentials.token);
    //     setTimeout(() => {
    //       this.router.navigate(['/subscribed/tracking/trackables']);
    //     }, 10);
    //   },
    //   error => { throw Error(errorMessage(error)); });
    this.authService.login();
  }
}
