<div class="trackable" style="color: #000">
  <table class="table caption-top">
    <caption class="pt-3">
      {{
        title
      }}
    </caption>
    <thead>
      <tr>
        <th scope="col"></th>
        <th
          scope="col"
          [hidden]="
            summary.updateInterval === 'daily' ||
            summary.updateInterval === 'weekly'
          "
        >
          DATE
        </th>
        <th scope="col">{{ summary.updateInterval | label | uppercase }}</th>
        <th scope="col">{{ summary.parameterUnits | uppercase }}</th>
        <th scope="col">COMMENTS</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let pv of parameterValues; let i = index">
        <th scope="row">{{ 1 + i }}</th>
        <td
          [hidden]="
            summary.updateInterval === 'daily' ||
            summary.updateInterval === 'weekly'
          "
        >
          {{ pv.reportDate }}
        </td>
        <td>{{ pv.reportSlot }}</td>
        <td>{{ pv.paramValue }}</td>
        <td>{{ pv.comments }}</td>
      </tr>
    </tbody>
  </table>
</div>
