<div class="header-container">
  <div class="logo-container">
    <img class="logo" src="/assets/images/logo-with-name.png" alt="logo" />
    <a class="logo" href="#">{{ institutionAbr }}</a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action
      *ngIf="user"
      class="control-item"
      icon="color-palette-outline"
      (click)="themes()"
      nbTooltip="Themes"
      nbTooltipIcon="color-palette-outline"
      [nbTooltipTrigger]="NbTrigger.HINT"
    ></nb-action>
    <nb-action class="user-action">
      <nb-user
        [nbPopover]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="userName"
        [picture]="picture"
        [showInitials]="true"
        [title]="userTitle"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>

<ng-template #userMenu>
  <nb-list class="menu">
    <nb-list-item
      *ngIf="authService.user$ | async"
      [routerLink]="['/subscribed/tracking/trackables']"
      style="cursor: pointer"
    >
      <nb-icon icon="radio-outline" class="me-2"></nb-icon>
      <span>Tracked Items</span>
    </nb-list-item>
    <nb-list-item
      *ngIf="(authService.user$ | async) === undefined"
      (click)="login()"
      style="cursor: pointer"
    >
      <nb-icon icon="log-in-outline" class="me-2"></nb-icon>
      <span>Login</span>
    </nb-list-item>
    <nb-list-item *ngIf="user" (click)="themes()" style="cursor: pointer">
      <nb-icon icon="color-palette-outline" class="me-2"></nb-icon>
      <span>Themes</span>
    </nb-list-item>
    <nb-list-item
      *ngIf="authService.user$ | async"
      (click)="logout()"
      style="cursor: pointer"
    >
      <nb-icon icon="log-out-outline" class="me-2"></nb-icon>
      <span>Logout</span>
    </nb-list-item>
    <!-- <nb-list-item (click)="signup()" style="cursor: pointer">
      <nb-icon icon="log-out-outline" class="me-2"></nb-icon>
      <span>Sign Up</span>
    </nb-list-item> -->
  </nb-list>
</ng-template>
