import { Component, OnInit } from '@angular/core';

/**
 * This is a dummy component which will never be displayed
 *
 * It is being used as an external navigation getway. It has a CanActivate guard that
 * will always return false. Within the guard is where routing to the external
 * links is done. This way, we are able to make use of router guards even when
 * navigating to external urls.
 */
@Component({
  template: '<div></div>',
})
export class ExternalPagesDummyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
