import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrintService } from '../../../utils/services/print.service';
import { IParameterValuesSummary } from '../../../../tracking/interfaces-enums/param-values-summary';
import { IParameterValue } from '../../../../tracking/interfaces-enums/parameter-value';
import { DateService } from '../../../../core/services/date.service';
import { LabelMakerPipe } from '../../../../tracking/pipes/label-maker.pipe';

@Component({
  templateUrl: './parameter-values-print.component.html',
  styleUrls: ['./parameter-values-print.component.scss'],
  standalone: true,
  imports: [CommonModule, LabelMakerPipe],
})
export class ParameterValuesPrintComponent {
  hideDate: boolean;
  parameterValues: IParameterValue[] = [];
  summary: Partial<IParameterValuesSummary> = {};
  title = 'Values';

  constructor(private printService: PrintService, private date: DateService) {
    const printData: IParameterValuesSummary = this.printService.printData;
    if (printData) {
      this.summary = printData;
      this.parameterValues = printData.parameterValues.map((pv) =>
        Object.assign({}, pv, {
          reportDate: this.date.fromUnix(
            pv.reportDate as number,
            'MMM D, YYYY'
          ),
        })
      );
      this.title = `${this.summary.trackerName} ${
        this.summary.updateInterval
      } ${this.summary.parameterName} (${this.date.fromUnix(
        this.date.toUnix(this.summary.startDate),
        'MMM D, YYYY'
      )} - ${this.date.fromUnix(
        this.date.toUnix(this.summary.endDate),
        'MMM D, YYYY'
      )})`;
    }
  }
}
