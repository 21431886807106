import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { StoreService } from '../services/store.service';

@Injectable()
export class ExternalPagesGuard  {
  constructor(private store: StoreService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    window.open(this.store.externalUrl);
    this.store.externalUrl = undefined;
    return false;
  }
}
