import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**Bootstrap based device widths */
export type DeviceWidth = 'xsm' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

/**Bootstrap based viewport widths */
@Injectable({
  providedIn: 'root',
})
export class DeviceWidthService {
  /**Emits device width on window resize */
  deviceWidth$ = new BehaviorSubject<DeviceWidth>('sm');

  get isMobile() {
    return /iPad|iPhone|iPod|Android/.test(navigator.userAgent);
  }

  constructor() {
    window.addEventListener('resize', () => {
      this.deviceWidth$.next(this.getWidth());
    });
    /**Get the initial width */
    this.deviceWidth$.next(this.getWidth());
  }

  private getWidth(): DeviceWidth {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );

    let width: DeviceWidth;
    if (vw < 576) {
      width = 'xsm';
    } else if (vw < 768) {
      width = 'sm';
    } else if (vw < 992) {
      width = 'md';
    } else if (vw < 1200) {
      width = 'lg';
    } else if (vw < 1400) {
      width = 'xl';
    } else {
      width = 'xxl';
    }

    return width;
  }
}
