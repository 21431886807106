/**
 * Gleans or constructs an error message from an Error object
 *
 * @param error Error object
 * @returns The gleaned error message
 */
export const errorMessage = (error: any): string => {
  console.trace(error);

  let message: string;
  if (error.error) {
    if (error.error.error) {
      message = JSON.stringify(error.error.error);
    } else if (error.error instanceof ProgressEvent) {
      message = 'Server unreachable. Check your connection';
    } else if (error.error.message) {
      message = error.error.message;
    } else {
      message = JSON.stringify(error.error);
    }
  } else if (error.message) {
    message = error.message;
  } else if (error.statusText) {
    message = error.statusText;
  } else if (error.status) {
    if (error.status === 0 || error.status === 504) {
      message = 'Server unreachable. Check your connection';
    } else {
      message = `Error: Status ${error.status}`;
    }
  } else {
    message = JSON.stringify(error);
  }

  if (message.toLowerCase().includes('failed to fetch') || message.toLowerCase().includes('jwt expired')) {
    message = `Your session has expired. Please login again.`;
  }

  if (message.toLowerCase().includes('timeout')) {
    message = 'Server unreachable. Check your connection';
  }

  return message;
};
