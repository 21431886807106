import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { errorMessage } from '../../helpers/error-message';

import { AuthService } from './auth.service';
import { NotificationService } from './notification.service';
import { StoreService } from './store.service';

@Injectable()
export class AuthGuardService  {
  private _user: any;
  constructor(private authService: AuthService, private store: StoreService, private notifier: NotificationService) {
    this.store.user$.subscribe(
      user => {
        this._user = user;
      },
      error => console.error(errorMessage(error)),
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn || (!this.store.isOnline && this._user)) {
      return true;
    }
    this.notifier.showError('Login required');
    this.store.currentUrl = state.url;
    this.authService.login();
    return false;
  }
}
