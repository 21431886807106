<span *ngIf="!isDropdown">
  <span *ngIf="!raised">
    <a
      *ngIf="!linkIsExternal; else isExternal"
      class="{{ linkClass || '' }}"
      [id]="id || ''"
      [ngClass]="{ 'nav-link': true, 'dropdown-item': dropdownSubmenu }"
      [routerLink]="[link]"
      routerLinkActive="active"
      [title]="title || label"
      [attr.data-bs-toggle]="hasPopover ? 'popover' : ''"
      [attr.data-bs-trigger]="popoverTriggers"
      [attr.data-bs-content]="popoverContent"
    >
      {{ label }}
    </a>
  </span>
  <span *ngIf="raised">
    <a
      *ngIf="!linkIsExternal; else isExternalAndRaised"
      class="{{ linkClass || '' }}"
      [id]="id || ''"
      [ngClass]="{ 'nav-link': true, 'dropdown-item': dropdownSubmenu }"
      [routerLink]="[link]"
      routerLinkActive="active"
      [title]="title || label"
      [attr.data-bs-toggle]="hasPopover ? 'popover' : ''"
      [attr.data-bs-trigger]="popoverTriggers"
      [attr.data-bs-content]="popoverContent"
      [color]="color"
      mat-raised-button
    >
      {{ label }}
    </a>
  </span>

  <ng-template #isExternal>
    <a
      class="{{ linkClass || '' }}"
      [id]="id || ''"
      [ngClass]="{ 'nav-link': true }"
      href="{{ link }}"
      target="_blank"
      [title]="title || label"
      [attr.data-bs-toggle]="hasPopover ? 'popover' : ''"
      [attr.data-bs-trigger]="popoverTriggers"
      [attr.data-bs-content]="popoverContent"
      noreferrer
    >
      {{ label }}
    </a>
  </ng-template>
  <ng-template #isExternalAndRaised>
    <a
      class="{{ linkClass || '' }}"
      [id]="id || ''"
      [ngClass]="{ 'nav-link': true }"
      href="{{ link }}"
      target="_blank"
      [title]="title || label"
      [attr.data-bs-toggle]="hasPopover ? 'popover' : ''"
      [attr.data-bs-trigger]="popoverTriggers"
      [attr.data-bs-content]="popoverContent"
      [color]="color"
      mat-raised-button
      noreferrer
    >
      {{ label }}
    </a>
  </ng-template>
</span>
<button
  *ngIf="isDropdown && !dropdownSubmenu"
  class="nav-link dropdown-toggle"
  data-bs-toggle="dropdown"
  aria-expanded="false"
>
  {{ label }}
</button>
<a *ngIf="isDropdown && dropdownSubmenu" href="#" class="nav-link">
  {{ label }} >>
  <!-- <mat-icon class="material-icons">arrow_right</mat-icon> -->
</a>

<ul
  *ngIf="isDropdown"
  [ngClass]="{ 'dropdown-menu': true, 'submenu submenu-left': dropdownSubmenu }"
>
  <li
    *ngFor="let item of dropdownItems"
    class="{{ item.listItemClass || '' }}"
    [ngClass]="{ 'dropdown-item': true, dropdown: item.isDropdown }"
  >
    <smx-nav-link
      [link]="item.link"
      [label]="item.label"
      [role]="item.role || ''"
      [id]="item.id || ''"
      [raised]="item.raised"
      [linkIsExternal]="item.linkIsExternal"
      [isDropdown]="item.isDropdown"
      [dropdownSubmenu]="item.isDropdown"
      [listItemClass]="item.listItemClass || ''"
      [linkClass]="item.linkClass || ''"
      [dropdownItems]="item.dropdownItems"
      [title]="item.title || ''"
      [hasPopover]="item.hasPopover"
      [popoverTriggers]="item.popoverTriggers"
      [popoverContent]="item.popoverContent"
      [color]="item.color"
      [preIcon]="item.preIcon"
      [postIcon]="item.postIcon"
    ></smx-nav-link>
  </li>
</ul>
