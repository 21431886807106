<nb-card>
  <nb-card-header class="d-flex">
    <h1 class="page-heading">Contact Support</h1>
    <nb-actions class="ms-auto">
      <nb-action
        id="back"
        class="control-item"
        (click)="back()"
        icon="arrow-back-outline"
        nbTooltip="Previous page"
        nbTooltipIcon="arrow-back-outline"
        [nbTooltipTrigger]="NbTrigger.HINT"
      ></nb-action>
      <nb-action
        id="sendMail"
        class="control-item"
        (click)="sendMessage()"
        icon="paper-plane-outline"
        nbTooltip="Send Mail"
        nbTooltipIcon="paper-plane-outline"
        [nbTooltipTrigger]="NbTrigger.HINT"
        [disabled]="!contactForm.valid"
      ></nb-action>
    </nb-actions>
  </nb-card-header>

  <button
    type="button"
    id="leave-page-btn"
    data-bs-toggle="modal"
    data-bs-target="#leave-page"
    hidden
  ></button>
  <app-modal-popup
    (formData)="leavePage($event)"
    identifier="leave-page"
    bodyText="You didn't send your latest changes on your message. Leave anyway?"
    buttonText="Leave"
    [isForm]="false"
  >
  </app-modal-popup>

  <nb-card-body class="container row mx-auto mb-3 pt-4 form_wrapper">
    <form novalidate [formGroup]="contactForm">
      <div class="form-group mb-2 row">
        <label for="email" class="col-sm-4 col-form-label">Email</label>
        <div class="col-sm-8">
          <input
            nbInput
            fullWidth
            type="email"
            id="email"
            placeholder="Your email"
            formControlName="email"
            [ngClass]="{ 'is-invalid': formErrors.email }"
          />
          <span class="invalid-feedback" aria-live="assertive">{{
            formErrors.email
          }}</span>
        </div>
      </div>
      <div class="form-group mb-2 row">
        <label for="phone" class="col-sm-4 col-form-label">Phone</label>
        <div class="col-sm-8">
          <input
            nbInput
            fullWidth
            type="tel"
            id="phone"
            placeholder="Mobile or Phone (optional)"
            formControlName="phone"
            [ngClass]="{ 'is-invalid': formErrors.phone }"
          />
          <span class="invalid-feedback" aria-live="assertive">{{
            formErrors.phone
          }}</span>
        </div>
      </div>
      <div class="form-group mb-2 row">
        <label for="subject" class="col-sm-4 col-form-label">Subject</label>
        <div class="col-sm-8">
          <nb-select
            fullWidth
            id="subject"
            formControlName="subject"
            aria-label="Message subject"
          >
            <nb-option value="general" selected>General</nb-option>
            <!-- <nb-option value="billing">Billing</nb-option> -->
            <nb-option value="bugs">Report Bug</nb-option>
            <nb-option value="technical">Technical</nb-option>
          </nb-select>
        </div>
      </div>
      <div class="form-group row">
        <label for="message" class="col-sm-4 col-form-label">Message</label>
        <div class="col-sm-8">
          <textarea
            nbInput
            fullWidth
            rows="4"
            id="message"
            placeholder="Your message"
            formControlName="message"
            [ngClass]="{ 'is-invalid': formErrors.message }"
            #message
          ></textarea>
          <span class="invalid-feedback" aria-live="assertive">{{
            formErrors.message
          }}</span>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
