import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { faPlus, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { NotificationService } from '../../core/services/notification.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { ITeamMember } from '../../global-models/team-member';
import { TeamService } from '../../core/services/team.service';
import { removeDeletedObjectFromUI } from '../../helpers/remove-deleted-object-from-ui';
import { AutoUnsubscribe } from '../../helpers/observable-auto-unsubscribe';
import { ErrorLoggingService } from '../../core/services/logging.service';
import { errorMessage } from '../../helpers/error-message';
import { StoreService } from '../../core/services/store.service';
import { IndexDbService } from '../../core/services/index-db.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
})
@AutoUnsubscribe()
export class TeamComponent implements OnInit, OnDestroy {
  faPlus = faPlus;
  faArrowLeft = faArrowLeft;
  removeDeletedMember = removeDeletedObjectFromUI;
  team: ITeamMember[] = [];
  private owner: string;

  constructor(
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private teamService: TeamService,
    private notifier: NotificationService,
    private errorLogger: ErrorLoggingService,
    private store: StoreService,
    private db: IndexDbService
  ) {
    this.store.user$.subscribe((user) => {
      if (user) {
        this.owner = user;
      }
    });
  }

  ngOnInit() {
    this.getTeam();
  }

  ngOnDestroy(): void {}

  async deleteMember(data: any) {
    try {
      const index = data.index;
      this.spinner.show();
      const memberId = data.formData.id;
      await firstValueFrom(this.teamService.deleteMember(memberId));
      this.spinner.hide();
      this.removeDeletedMember(index, this.team);
      this.db.team
        .where('_id')
        .equals(memberId)
        .and((tm) => tm.owner === this.owner)
        .delete();
      this.store.teamRefreshNeeded = true;
      this.notifier.showSuccess('Deletion successful');
    } catch (error) {
      this.spinner.hide();
      this.notifier.showError(errorMessage(error));
      this.errorLogger.logError(errorMessage(error));
    }
  }

  goToManage() {
    this.router.navigate(['/admin/account/manage-account']);
  }

  private getTeam() {
    try {
      this.spinner.hide();
      const payload = this.route.parent.snapshot.data.team;
      if (payload) {
        this.team = payload;
      } else {
        this.notifier.showError('No Team Members found');
      }
    } catch (error) {
      this.spinner.hide();
      this.notifier.showError(errorMessage(error));
      this.errorLogger.logError(errorMessage(error));
    }
  }
}
