import {
  CUSTOM_ELEMENTS_SCHEMA,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { SocialComponent } from './social/social.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { QuoteComponent } from './quote/quote.component';
import { PictureComponent } from './picture/picture.component';
import { CardsComponent } from './cards/cards.component';
import { ServicesModule } from './services/services.module';
import { WindowScrollService } from './services/window-scroll.service';
import { DeviceWidthService } from './services/device-width.service';
import { NavbarComponent } from './navbar/navbar.component';
import { SlideShowComponent } from './slide-show/slide-show.component';
import { StoryLineComponent } from './story-line/story-line.component';
import { IconRegistryService } from './services/icon-registry.service';
import { SideNavComponent } from './side-nav/side-nav.component';
import { MatMenuWrapperComponent } from './mat-menu-wrapper/mat-menu-wrapper.component';
import { NavLinkComponent } from './nav-link/nav-link.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { SmxSmartTableComponent } from './smart-table/smart-table.component';
import { DummyValidatorsComponent } from './dummy-validators/dummy-validators.component';
import { DummyHelpersComponent } from './dummy-helpers/dummy-helpers.component';
import { ValidationErrorCheckService } from './services/validation-error-check.service';
import { ErrorForceCheckService } from './services/validation-error-force-check.service';
import { ValidationMessagesService } from './services/validation-messages.service';
import { NotificationService } from './services/notification.service';
import { BackdropManagerService } from './services/backdrop-manager.service';
import { SpinnerService } from './services/spinner.service';
import { ErrorLoggingService } from './services/error-logging.service';
import { NbGlobalHttpInterceptor } from './services/nb-global-http.interceptor';
import { NbNotFoundComponent } from './nb-page-not-found/not-found.component';
import { NbConfirmDialogComponent } from './nb-dialog/nb-dialog.component';

/**Seamminex custom components library */
@NgModule({
  imports: [CommonModule, SharedModule, ServicesModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    SocialComponent,
    HeaderComponent,
    FooterComponent,
    QuoteComponent,
    PictureComponent,
    CardsComponent,
    NavbarComponent,
    SlideShowComponent,
    StoryLineComponent,
    SideNavComponent,
    MatMenuWrapperComponent,
    NavLinkComponent,
    UserProfileComponent,
    SmxSmartTableComponent,
    DummyValidatorsComponent,
    DummyHelpersComponent,
    NbNotFoundComponent,
    NbConfirmDialogComponent,
  ],
  exports: [
    SocialComponent,
    HeaderComponent,
    FooterComponent,
    QuoteComponent,
    PictureComponent,
    CardsComponent,
    NavbarComponent,
    SlideShowComponent,
    StoryLineComponent,
    SideNavComponent,
    MatMenuWrapperComponent,
    NavLinkComponent,
    ServicesModule,
    UserProfileComponent,
    SmxSmartTableComponent,
    DummyValidatorsComponent,
    DummyHelpersComponent,
    NbNotFoundComponent,
    NbConfirmDialogComponent,
    SharedModule,
    ServicesModule,
  ],
})
export class NgxSmxComponentsModule {
  static forRoot(): ModuleWithProviders<NgxSmxComponentsModule> {
    return {
      ngModule: NgxSmxComponentsModule,
      providers: [
        WindowScrollService,
        DeviceWidthService,
        IconRegistryService,
        ValidationErrorCheckService,
        ErrorForceCheckService,
        ValidationMessagesService,
        NotificationService,
        SpinnerService,
        BackdropManagerService,
        ErrorLoggingService,
        NbGlobalHttpInterceptor,
      ],
    };
  }
}
