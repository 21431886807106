import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  ActivatedRoute,
} from '@angular/router';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SpinnerService } from '../../core/services/spinner.service';
import { NotificationService } from '../../core/services/notification.service';
import { errorMessage } from '../../helpers/error-message';
import { IAccountDetails } from '../../tracking/interfaces-enums/account-details';
import { DataService } from '../../core/services/data.service';

@Injectable()
export class AccountDetailsResolverService {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: SpinnerService,
    private notifier: NotificationService,
    private dataService: DataService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IAccountDetails> {
    this.spinner.show();
    return this.dataService.getAccountDetails().pipe(
      catchError((error) => {
        this.spinner.hide();
        this.router.navigate(['./'], { relativeTo: this.route });
        this.notifier.showError(errorMessage(error));
        throw error;
      })
    );
  }
}
