import { NavigationEnd, Router } from '@angular/router';
import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';

import { BehaviorSubject, filter, Subscription } from 'rxjs';

import { bottomEdgeInViewport } from '../../shared/helpers';
import {
  ICard,
  IChronologicalEvents,
  IFooter,
  IPicture,
  ISlideShow,
  ISocial,
  INavLink,
} from '../..';

import {
  DeviceWidth,
  DeviceWidthService,
} from '../services/device-width.service';
import { WindowScrollService } from '../services/window-scroll.service';
import { SocialComponentToken } from '../social/social.component';
import { PopoversService } from '../services/popovers.service';

/**
 * A highly opinionated web-page
 *
 * It is based on Bootstrap 5 and Angular Material for styling, AOS Library for scroll animations, and Animate.css library for static
 * animations.
 */
export interface IWebPage {
  /**Page title, for SEO purposes. Usually displayed in browser tab */
  title: string;
  /**Page short description, for SEO purposes. Shows as the description on search results. Ideally should be less than 160 characters */
  description: string;
  /**Main heading, usually displayed in an ```h1``` tag */
  heading: string;
  /**Picture settings for page banner */
  banner?: IPicture;
  /**Page slide-show */
  slideShow?: ISlideShow;
  /**Various sets of complex cards to display */
  cards?: { [name: string]: ICard[] };
  /**Various simple cards to display */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  auxiliary?: { [name: string]: any };
  /**
   * Various chronological or similar ordered content to display
   */
  chronological?: IChronologicalEvents;
}

/**
 * Interface that guides the creation of the main site profile
 *
 * The ideal intention of the profile is that it should hold all the information/content
 * of the site as much as possible. Ideally developer should be able to change the contents
 * of the profile and the whole site is changed without having to go in pages templates and
 * components
 */
export interface ISiteProfile {
  /**Name of the site */
  institutionFullName?: string;
  /**Site alias. Maybe the short form? */
  institutionAlias?: string;
  /**Site name abbreviation */
  institutionAbreviation?: string;
  /**Preferred display name */
  institutionDisplayName?: string;
  /**Site full logo. Contains the logo emblem and the name of institution if applicable and other additives where applicable */
  institutionFullLogo?: string;
  /**Site aux logo. Variant of the main logo. The stripped-down version of the logo, usually the emblem only */
  institutionMiniLogo?: string;
  /**Address segments */
  streetAddress?: string[];
  /**
   * Main contact number
   *
   * Should only contain numbers, brackets and dashes e.g ```(+263) 774-819-391```
   */
  mainContactNumber?: string;
  /**List of contact numbers */
  contactNumbers?: string[];
  /**Main contact email */
  mainContactEmail?: string;
  /**List of contact emails */
  contactEmails?: string[];
  /**Website address */
  websiteUrl?: string;
  /**Related application address */
  appUrl?: string;
  /**Social links mappings */
  socialProfile?: ISocial;
  /**Header design settings */
  headerDesign: IHeader;
  /**Footer design settings */
  footerDesign: IFooter;
  /**Site pages */
  pages: { [name: string]: IWebPage };
  /**Developer details */
  developerDetails: {
    designerUrl: string;
    designerLogoUrl: string;
    designerName: string;
  };
  /**Mappings to legal URLs */
  legal?: {
    tcsUrl: string;
    privacyUrl: string;
    eulaUrl: string;
    cookiesUrl: string;
    disclaimerUrl: string;
  };
}

/**
 * Interface that guides the site header design
 */
export interface IHeader {
  /**Enumerates ```main nav-links``` */
  navLinks: INavLink[];
  /**
   * Flag whether to hide ```main nav-links``` or not
   *
   * ```NB:``` This only hides the links of a horizontal navbar. Vertical navs are always shown
   */
  hideNavLinks?: boolean;
  /**Enumerates ```auxillary nav-links``` */
  auxNavLinks?: INavLink[];
  /**Flag to whether to show ```auxillary nav-links``` or not */
  showAuxNavLinks?: boolean;
  /**The main color of the main/base header
   *
   * This houses the site navigation bar. The value should any valid HTML color name
   */
  headerBaseColor?: string;
  /**Flag whether to display a user widget */
  showUserProfile?: boolean;
  /**The link colors for the main/base header
   *
   * This houses the site navigation bar. The value should any valid HTML color name
   */
  headerBaseLinkColor?: string;
  /**Url/path to the logo to be displayed in the main nav-bar */
  headerLogoUrl?: string;
  /**
   * Flag whether to include a header top
   *
   * This is usually used to display quick contacts links just above the main nav-bar
   */
  hasHeaderTop?: boolean;
  /**
   * HeaderTop color
   *
   * Only necessary when ```hasHeaderTop = true```. The value should any valid HTML color name
   */
  headerTopColor?: string;
  /**
   * HeaderTop links color
   *
   * Only necessary when ```hasHeaderTop = true```. The value should any valid HTML color name
   */
  headerTopLinkColor?: string;
  /**Flag whether to include a scroll progress bar */
  hasProgressBar?: boolean;
  /**
   * Progress bar color
   *
   * Only necessary if ```hasProgressBar = true```. The value should any valid HTML color name
   */
  progressBarColor?: string;
  /**
   * Position of progress bar relative to the main navigation bar
   *
   * Only necessary if ```hasProgressBar = true```
   */
  progressBarPosition?: 'top' | 'bottom';
  /**Flag whether to initially have a transparent header and color it on scroll */
  hasTransparentHeader?: boolean;
  /**
   * Scroll height (in pixels) at which to apply color to the header
   *
   * Only necessary if ```hasTransparentHeader = true```
   */
  colorOnScrollHeight?: number;
  /**Social icons color */
  socialIconColor?: string;
  /**Flag whether to include floating social links */
  hasFloatingSocial?: boolean;
  /**Flag whether the main navigation is situated on the side of the screen as opposed to being on top */
  isSideNav?: boolean;
}

@Component({
  selector: 'smx-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() headerData!: ISiteProfile;
  @Input() user!: any;
  @Output() deviceWidth = new EventEmitter<DeviceWidth>();
  @Output() isMobile = new EventEmitter<boolean>();
  @ContentChild(SocialComponentToken) social: SocialComponentToken | null =
    null;

  hostUrl = `${window.location.protocol}//${window.location.host}`;

  socialIconColor: string | undefined;
  hasFloatingSocial: boolean | undefined;
  hasHeaderTop: boolean | undefined;
  isSideNav: boolean | undefined;
  navLinks!: INavLink[];
  hideNavLinks!: boolean | undefined;
  auxNavLinks!: INavLink[] | undefined;
  showAuxNavLinks!: boolean | undefined;
  showUserProfile!: boolean | undefined;
  scrollPercent!: number;
  institutionFullName: string | undefined;
  institutionAbreviation: string | undefined;
  institutionFullLogo: string | undefined;
  mainContactNumber: string | undefined;
  mainContactEmail: string | undefined;
  socialProfile: ISocial | undefined;
  navFixed!: boolean;
  navFixed$ = new BehaviorSubject(false);
  vw!: DeviceWidth;

  hasProgressBar: boolean | undefined;
  progressBarPosition: string | undefined;

  private rootNativeElement!: HTMLElement;
  private headerTop!: HTMLElement;
  private headerBase!: HTMLElement | null;
  private headerBaseColor!: string | undefined;
  private hasTransparentHeader: boolean | undefined;
  private isTransparent: boolean | undefined;
  private colorOnScroll: number | undefined = 0;
  private subscriptions: Subscription = new Subscription();
  private listener: unknown;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private scroller: WindowScrollService,
    private deviceWidthService: DeviceWidthService,
    private popovers: PopoversService
  ) {
    this.listener = this.renderer.listen('window', 'scroll', () => {
      this.scroller.updateScrollY(this.scroller.getYPosition());
      this.hasTransparentHeader ? this.manageTransparency() : null;
    });

    const navSub = this.navFixed$.subscribe((status) => {
      this.navFixed = status;
    });

    const vwSub = this.deviceWidthService.deviceWidth$.subscribe((size) => {
      this.vw = size;
      this.deviceWidth.emit(size);
    });

    this.subscriptions.add(navSub);
    this.subscriptions.add(vwSub);
  }

  ngOnInit(): void {
    this.institutionFullLogo = this.headerData.headerDesign.headerLogoUrl;
    this.headerBaseColor = this.headerData.headerDesign.headerBaseColor;
    this.hasTransparentHeader =
      this.headerData.headerDesign.hasTransparentHeader;
    this.hasHeaderTop = this.headerData.headerDesign.hasHeaderTop;
    this.hasProgressBar = this.headerData.headerDesign.hasProgressBar;
    this.progressBarPosition =
      this.headerData.headerDesign.progressBarPosition || 'top';
    this.colorOnScroll = this.headerData.headerDesign.colorOnScrollHeight;
    this.navLinks = this.headerData.headerDesign.navLinks;
    this.socialIconColor = this.headerData.headerDesign.socialIconColor;
    this.auxNavLinks = this.headerData.headerDesign.auxNavLinks;
    this.showAuxNavLinks = this.headerData.headerDesign.showAuxNavLinks;
    this.showUserProfile = this.headerData.headerDesign.showUserProfile;
    this.hideNavLinks = this.headerData.headerDesign.hideNavLinks;
    this.hasFloatingSocial = this.headerData.headerDesign.hasFloatingSocial;
    this.isSideNav = this.headerData.headerDesign.isSideNav;

    this.institutionFullName = this.headerData.institutionFullName;
    this.institutionAbreviation = this.headerData.institutionAbreviation;
    this.mainContactNumber = this.headerData.mainContactNumber;
    this.mainContactEmail = this.headerData.mainContactEmail;

    this.socialProfile = this.headerData.socialProfile;

    this.isMobile.emit(this.deviceWidthService.isMobile);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // TODO: Would prefer to use Renderer instead of ElementRf. But had to do this in order to solve the header sticky issue
      this.rootNativeElement = this.el.nativeElement as HTMLElement;
      const rootElem = this.renderer.selectRootElement(
        this.rootNativeElement,
        true
      ) as HTMLElement;

      this.headerTop = rootElem.querySelector('#header-top') as HTMLElement;
      this.headerBase = rootElem.querySelector('#nav-wrapper') as HTMLElement;

      this.manageHeaderTop();
      this.manageHeaderBase();
      this.collapseNavbar();
      this.monitorRoutes();

      const sub = this.scroller.scrollPercent$.subscribe((y) => {
        this.scrollPercent = y;
        this.isNavFixed();
      });

      this.subscriptions.add(sub);
    });

    setTimeout(() => {
      this.popovers.initialisePopovers();
    }, 1000);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    (this.listener as typeof Function)();
  }

  private manageHeaderTop() {
    if (this.headerTop) {
      this.renderer.setStyle(
        this.headerTop,
        'backgroundColor',
        this.headerData.headerDesign.headerTopColor || 'initial'
      );

      const linksPack = [
        this.headerTop.querySelectorAll('a'),
        this.headerTop.querySelectorAll('button'),
      ];
      linksPack.forEach((links) => {
        links.forEach((link) => {
          this.renderer.setStyle(
            link,
            'color',
            this.headerData.headerDesign.headerTopLinkColor || 'initial'
          );
        });
      });
    }
  }

  private manageHeaderBase() {
    if (this.headerBase) {
      if (this.hasTransparentHeader) {
        this.initialiseTransparency();
        this.renderer.setStyle(this.rootNativeElement, 'position', 'fixed');
        this.renderer.setStyle(this.rootNativeElement, 'top', '0');
        this.renderer.setStyle(this.rootNativeElement, 'zIndex', '500');
        this.renderer.setStyle(this.rootNativeElement, 'minWidth', '100%');
      } else {
        this.renderer.setStyle(
          this.headerBase,
          'backgroundColor',
          this.headerBaseColor || 'initial'
        );
      }

      const progressElem = this.headerBase.querySelector('.progress');
      if (progressElem) {
        this.renderer.setStyle(progressElem, 'backgroundColor', 'transparent');

        const progressBar = progressElem.querySelector('.progress-bar');
        progressBar
          ? this.renderer.setStyle(
              progressBar,
              'backgroundColor',
              this.headerData.headerDesign.progressBarColor || 'initial'
            )
          : null;

        const dropdownMenu =
          this.headerBase.querySelector('nav .dropdown-menu');
        dropdownMenu
          ? this.renderer.setStyle(
              dropdownMenu,
              'backgroundColor',
              this.headerBaseColor || 'initial'
            )
          : null;
      }

      const linksPack = [
        this.headerBase.querySelectorAll('a.nav-link'),
        this.headerBase.querySelectorAll('button.nav-link'),
      ];
      linksPack.forEach((links) => {
        links.forEach((link) => {
          this.renderer.setStyle(
            link,
            'color',
            this.headerData.headerDesign.headerBaseLinkColor || 'initial'
          );
        });
      });
    }
  }

  private initialiseTransparency() {
    if (
      this.hasTransparentHeader &&
      this.colorOnScroll !== undefined &&
      this.headerBase
    ) {
      this.renderer.setAttribute(
        this.headerBase,
        'color-on-scroll',
        this.colorOnScroll.toString()
      );
      this.transparentizeHeader();
    }
  }

  private manageTransparency() {
    if (!this.headerBase) return;

    if (
      this.scroller.getYPosition() >
      (this.colorOnScroll ? +this.colorOnScroll : 0)
    ) {
      if (this.isTransparent) {
        this.renderer.setStyle(
          this.headerBase,
          'backgroundColor',
          this.headerBaseColor
        );
        this.renderer.setStyle(
          this.headerBase,
          'boxShadow',
          '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
        );
        this.isTransparent = false;
      }
    } else {
      if (!this.isTransparent) {
        this.transparentizeHeader();
      }
    }
  }

  private transparentizeHeader() {
    if (!this.headerBase) return;

    this.renderer.setStyle(this.headerBase, 'backgroundColor', 'transparent');
    this.renderer.setStyle(this.headerBase, '-webkitBoxShadow', 'none');
    this.renderer.setStyle(this.headerBase, 'boxShadow', 'none');
    this.isTransparent = true;
  }

  private isNavFixed() {
    if (this.headerTop) {
      return this.navFixed$.next(!bottomEdgeInViewport(this.headerTop));
    }
    return this.navFixed$.next(true);
  }

  private collapseNavbar() {
    addEventListener('click', (e) => {
      const target = e.target as unknown as HTMLElement;
      const dropdownClicked = target?.classList.contains('dropdown-toggle');
      const collapsible = document.querySelector('#collapsible');
      const isExtended = collapsible
        ? collapsible.classList.contains('show')
        : false;
      const toggler = document.querySelector('#hamburger');
      if (isExtended && !dropdownClicked) {
        collapsible ? collapsible.classList.remove('show') : null;
        toggler ? toggler.setAttribute('aria-expanded', 'false') : null;
        toggler ? toggler.classList.add('collapsed') : null;
      }
    });
  }

  private monitorRoutes() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const body = this.renderer.selectRootElement(
          'body',
          true
        ) as HTMLElement;
        const h1 = body?.querySelector('.jarallax h1') as HTMLElement;

        if (h1) {
          h1.style.setProperty('--paddingTopOnTransparent', '8rem');
          // console.log(h1);
        }
      });
  }
}
