<div class="modal" id="{{ identifier + index }}" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered is-live" role="document">
    <nb-card class="modal-content">
      <nb-card-header *ngIf="title">
        <h2 class="modal-title">{{ title }}</h2>
      </nb-card-header>
      <nb-card-body>
        <p
          id="{{ 'heading' + index }}"
          class="pt-3 px-3 text-center heading"
          *ngIf="!!bodyText"
        >
          {{ bodyText }}
        </p>
        <form [formGroup]="nameEditForm" *ngIf="isForm">
          <input type="text" formControlName="id" hidden />
          <div class="form-group row">
            <label
              attr.for="{{ 'name' + index }}"
              class="col-sm-6 col-form-label"
              >{{ label }}</label
            >
            <div class="col-sm-6">
              <input
                type="{{ inputType }}"
                class="form-control"
                id="{{ 'name' + index }}"
                placeholder="{{ placeholder }}"
                formControlName="name"
                [ngClass]="{ 'is-invalid': formErrors['name'] }"
              />
              <span class="invalid-feedback" aria-live="assertive">{{
                formErrors['name']
              }}</span>
            </div>
          </div>
        </form>
      </nb-card-body>
      <nb-card-footer>
        <div class="row btn_wrapper">
          <div class="col-sm-6 p-2">
            <button
              type="button"
              class="btn btn-danger cancel"
              data-bs-dismiss="modal"
              (click)="spinner.hide()"
            >
              Cancel
            </button>
          </div>
          <div class="col-sm-6 p-2">
            <button
              type="button"
              class="btn btn-success confirm"
              data-bs-dismiss="modal"
              [disabled]="!nameEditForm.valid"
              (click)="emitFormData()"
            >
              {{ buttonText }}
            </button>
          </div>
        </div>
      </nb-card-footer>
    </nb-card>
  </div>
</div>
