import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { catchError, retry, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { ITeamMember } from '../../../global-models/team-member';
import { TeamService } from '../../../core/services/team.service';
import { SpinnerService } from '../../../core/services/spinner.service';
import { NotificationService } from '../../../core/services/notification.service';
import { errorMessage } from '../../../helpers/error-message';
import { StoreService } from '../../../core/services/store.service';
import { IndexDbService } from '../../../core/services/index-db.service';

@Injectable({
  providedIn: 'root',
})
export class TeamResolverService  {
  private dbTeam: ITeamMember[];
  private previousData: ITeamMember[];
  private account: string;
  private owner: string;
  private previousUrl: string;

  constructor(
    private router: Router,
    private notifier: NotificationService,
    private spinner: SpinnerService,
    private teamService: TeamService,
    private store: StoreService,
    private db: IndexDbService,
  ) {
    this.store.account$.subscribe(account => {
      if (account) {
        this.account = account;
      }
    });

    this.store.user$.subscribe(user => {
      if (user) {
        this.owner = user;
      }
    });

    this.store.team$.subscribe(team => {
      if (team) {
        this.dbTeam = team;
      }
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ITeamMember[]> | Observable<any> {
    if (this.refresh(state.url)) {
      this.previousUrl = state.url;
      this.store.teamRefreshNeeded = false;
      return this.fetchData();
    }
    this.previousUrl = state.url;
    this.store.teamRefreshNeeded = false;
    this.spinner.hide();
    return of(this.previousData);
  }

  private fetchData() {
    let fromIndexedDb = false;
    this.spinner.show();
    return this.teamService.getTeam().pipe(
      retry(!this.dbTeam && !this.store.isOnline ? 50 : 2),
      catchError(error => {
        if (this.dbTeam && error instanceof HttpErrorResponse && (error.status === 0 || error.status === 504)) {
          fromIndexedDb = true;
          return of(this.dbTeam);
        }
        this.notifier.showError(errorMessage(error));
        this.router.navigate([this.store.previousUrl]);
        throw error;
      }),
      tap<ITeamMember[]>(async trackables => {
        this.previousData = trackables;
        if (!fromIndexedDb && trackables instanceof Array && this.account && this.owner) {
          const teamMembers = trackables.map(bndl =>
            Object.assign({}, bndl, { account: this.account, owner: this.owner }),
          );
          if (trackables instanceof Array) {
            this.db.team
              .where('account')
              .equals(this.account)
              .and(tm => tm.owner === this.owner)
              .delete()
              .then(() => {
                this.db.team
                  .bulkAdd(teamMembers)
                  .then(value => (this.store.team = teamMembers))
                  .catch(error => {
                    this.notifier.showError(errorMessage(error));
                  });
              })
              .catch(error => {
                this.notifier.showError(errorMessage(error));
              });
          }
        }
        fromIndexedDb = false;
        this.spinner.hide();
      }),
      catchError(error => {
        this.notifier.showError(errorMessage(error));
        this.router.navigate([this.store.previousUrl]);
        this.spinner.hide();
        throw error;
      }),
    );
  }

  private refresh(currentUrl: string): boolean {
    return this.store.teamRefreshNeeded || !this.previousUrl || this.previousUrl === currentUrl;
  }
}
