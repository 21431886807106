export enum IndexedDBTables {
  parameterValues = 'parameterValues',
  pushMessages = 'pushMessages',
  tokens = 'tokens',
  trackables = 'trackables',
  mutedBroadcasts = 'mutedBroadcasts',
  exchangeRate = 'exchangeRate',
  accountDetails = 'accountDetails',
  activeBundle = 'activeBundle',
  bundles = 'bundles',
  exchangeRates = 'exchangeRates',
  team = 'team',
}
