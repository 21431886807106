import {
  AfterViewInit,
  Component,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NbButtonModule, NbCardModule } from '@nebular/theme';
import { PopupService } from '../../../utils/services/popup.service';

@Component({
  selector: 'ngx-popup-layout',
  standalone: true,
  imports: [CommonModule, RouterModule, NbButtonModule, NbCardModule],
  templateUrl: './popup-layout.component.html',
  styleUrls: ['./popup-layout.component.scss'],
})
export class PopupLayoutComponent implements AfterViewInit {
  @ViewChild('popup') popup!: TemplateRef<any>;
  activeUrl: string;
  constructor(private popupService: PopupService, private location: Location) {
    this.activeUrl = this.location.path();
  }

  ngAfterViewInit(): void {
    this.popupService.openPopup(this.popup, this.popupHeader());
  }

  onActivate(e: any) {
    console.log(e);
  }
  onDeactivate(e: any) {
    console.log(e);
  }
  onAttach(e: any) {
    console.log(e);
  }
  onDetach(e: any) {
    console.log(e);
  }

  private popupHeader() {
    let header = 'Window';
    if (this.activeUrl.includes('preferences')) {
      header = 'Preferences';
    }
    return header;
  }
}
