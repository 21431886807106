import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { CustomError } from '../../../global-models/custom-error';
import { ITeamMember } from '../../../global-models/team-member';
import { TeamService } from '../../../core/services/team.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class MemberResolverService  {
  constructor(private teamService: TeamService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ITeamMember> | Observable<any> {
    const id = route.paramMap.get('id');
    if (id === '0') {
      return null;
    }
    return this.teamService.getMember(id).pipe(
      retry(2),
      catchError(error => {
        if (error.name === 'CastError') {
          throw new HttpErrorResponse({ status: 404, statusText: `Member with id '${id}' not found` });
        }
        throw error;
      }),
    );
  }
}
