/* eslint-disable @angular-eslint/component-selector */
import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ProfileService } from '../../utils/services/profile.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  private page = 'home';
  private content = this.profileService.pages[this.page].description;
  private pageTitle = this.profileService.pages[this.page].title;

  institutionDisplayName = this.profileService.institutionDisplayName;
  heading = this.profileService.pages[this.page].heading;
  banner = this.profileService.pages[this.page].banner;

  constructor(
    private meta: Meta,
    private title: Title,
    private profileService: ProfileService
  ) {
    this.meta.updateTag({
      name: 'description',
      content: `${this.content}`,
    });

    this.title.setTitle(`${this.pageTitle}`);
  }
}
