import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Constants } from '../../constants';
import { map, retry } from 'rxjs/operators';

@Injectable()
export class PushNotificationService {
  private trackingApiRoot = Constants.trackingApiRoot;
  private subscriptions = Constants.pushNotificationsSubscriptionsPath;

  constructor(private http: HttpClient) {}

  addPushSubscriber(sub: PushSubscription): Observable<any> {
    return this.http.post(`${this.trackingApiRoot}/${this.subscriptions}/subscribe`, sub).pipe(retry(2));
  }

  removePushSubscriber(subPayload: any): Observable<any> {
    return this.http.post(`${this.trackingApiRoot}/${this.subscriptions}/unsubscribe`, subPayload).pipe(retry(2));
  }

  send(data: any): Observable<any> {
    return this.http.post(`${this.trackingApiRoot}/${this.subscriptions}/push`, data).pipe(retry(2));
  }
}
