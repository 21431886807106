<nb-card>
  <nb-card-header class="d-flex">
    <h1 class="page-heading">{{ title }}</h1>
    <nb-actions class="ms-auto">
      <nb-action
        icon="save-outline"
        nbTooltipIcon="save-outline"
        nbTooltip="Save Member Details"
        (click)="processMember()"
        [disabled]="!teamMemberForm.valid"
      ></nb-action>
      <nb-action
        icon="close-outline"
        nbTooltipIcon="close-outline"
        nbTooltip="Cancel"
        [routerLink]="['/admin/team']"
        id="cancel"
      ></nb-action>
    </nb-actions>
  </nb-card-header>

  <button
    type="button"
    id="leave-page-btn"
    data-bs-toggle="modal"
    data-bs-target="#leave-page"
    hidden
  ></button>
  <app-modal-popup
    (formData)="leavePage()"
    [target]="member"
    identifier="leave-page"
    [bodyText]="
      (member ? (member.firstName | titlecase) : 'This Team Member') +
      ' has unsaved changes. Leave anyway?'
    "
    buttonText="Leave"
    [isForm]="false"
  >
  </app-modal-popup>

  <nb-card-body class="container row mx-auto mt-3 mb-3 pt-4 form_wrapper">
    <form novalidate [formGroup]="teamMemberForm">
      <input
        nbInput
        fullWidth
        type="text"
        formControlName="_id"
        hidden
        *ngIf="!isNewMember"
      />
      <div class="form-group row">
        <label for="firstName" class="col-sm-4 col-form-label">Name</label>
        <div class="col-sm-8">
          <input
            nbInput
            fullWidth
            type="text"
            class="mb-2"
            id="firstName"
            placeholder="Team member first name"
            formControlName="firstName"
            [ngClass]="{ 'is-invalid': formErrors.firstName }"
          />
          <span class="invalid-feedback" aria-live="assertive">{{
            formErrors.firstName
          }}</span>
        </div>
      </div>
      <div class="form-group row">
        <label for="lastName" class="col-sm-4 col-form-label">Surname</label>
        <div class="col-sm-8">
          <input
            nbInput
            fullWidth
            type="text"
            class="mb-2"
            id="lastName"
            placeholder="Team member last name"
            formControlName="lastName"
            [ngClass]="{ 'is-invalid': formErrors.lastName }"
          />
          <span class="invalid-feedback" aria-live="assertive">{{
            formErrors.lastName
          }}</span>
        </div>
      </div>
      <div class="form-group row" *ngIf="isNewMember">
        <label for="username" class="col-sm-4 col-form-label">Username</label>
        <div class="col-sm-8">
          <input
            nbInput
            fullWidth
            type="text"
            class="mb-2"
            id="username"
            placeholder="Username"
            formControlName="username"
            [ngClass]="{ 'is-invalid': formErrors.username }"
          />
          <span class="invalid-feedback" aria-live="assertive">{{
            formErrors.username
          }}</span>
        </div>
      </div>
      <div class="form-group row">
        <label for="email" class="col-sm-4 col-form-label">Email</label>
        <div class="col-sm-8">
          <input
            nbInput
            fullWidth
            type="email"
            class="mb-2"
            id="email"
            placeholder="Email"
            formControlName="email"
            [ngClass]="{ 'is-invalid': formErrors.email }"
          />
          <span class="invalid-feedback" aria-live="assertive">{{
            formErrors.email
          }}</span>
        </div>
      </div>
      <div class="form-group row" *ngIf="!isNewMember">
        <label for="clearance" class="col-sm-4 col-form-label">Clearance</label>
        <div class="col-sm-8">
          <nb-select
            fullWidth
            class="mb-2"
            id="clearance"
            formControlName="clearance"
          >
            <nb-option value="level-1">Level 1</nb-option>
            <nb-option value="level-2">Level 2</nb-option>
            <nb-option value="level-3">Level 3</nb-option>
            <nb-option value="level-4">Level 4</nb-option>
            <nb-option value="level-5">Level 5</nb-option>
            <nb-option value="level-6">Level 6</nb-option>
            <nb-option value="admin">Admin</nb-option>
          </nb-select>
        </div>
      </div>
      <div class="form-group row mb-4" *ngIf="isNewMember">
        <label for="password" class="col-sm-4 col-form-label">Password</label>
        <div class="col-sm-8">
          <input
            nbInput
            fullWidth
            type="password"
            class="mb-2"
            id="password"
            placeholder="Password"
            formControlName="password"
            [ngClass]="{ 'is-invalid': formErrors.password }"
          />
          <span class="invalid-feedback" aria-live="assertive">{{
            formErrors.password
          }}</span>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
