import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

import { Constants } from '../../constants';

@Injectable()
export class MailingService {
  trackingApiRoot = Constants.trackingApiRoot;
  mailingPath = Constants.mailingPath;

  constructor(private http: HttpClient) {}

  sendMail(data: any): Observable<any> {
    return this.http.post<any>(`${this.trackingApiRoot}/${this.mailingPath}/send`, data).pipe(retry(2));
  }
}
