import { Component, OnInit, OnDestroy } from '@angular/core';
import { AutoUnsubscribe } from '../helpers/observable-auto-unsubscribe';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
@AutoUnsubscribe()
export class AdminComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit() {}
  ngOnDestroy(): void {}
}
