import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { EMPTY, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IExchangeRate } from '../../tracking/interfaces-enums/exchange-rate';
import { PayService } from '../../core/services/pay.service';
import { NotificationService } from '../../core/services/notification.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { errorMessage } from '../../helpers/error-message';

@Injectable({
  providedIn: 'root',
})
export class ExchangeRateResolver  {
  constructor(private payService: PayService, private notifier: NotificationService, private spinner: SpinnerService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IExchangeRate> {
    return this.payService.getExchangeRate().pipe(
      catchError(error => {
        this.spinner.hide();
        this.notifier.showError(errorMessage(error));
        return of(undefined);
      }),
    );
  }
}
