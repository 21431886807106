<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>{{ data.header }}</nb-card-header>
    <nb-card-body>{{ data.body }}</nb-card-body>
    <nb-card-footer class="d-flex flex-wrap justify-content-between">
      <button
        class="m-2 flex-fill"
        nbButton
        status="danger"
        (click)="cancel(); ref.close()"
      >
        {{ data.cancelBtnLabel }}
      </button>
      <button
        class="m-2 flex-fill"
        nbButton
        status="success"
        (click)="submit(); ref.close()"
      >
        {{ data.submitBtnLabel }}
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>
