import { Constants } from '../constants';

/**
 * Creates a general custom DOM event
 *
 * @param name Name of the event
 * @param detail Data to be attached to the event
 */
export function createEvent(name: string, detail?: { detail: any }): Event {
  return new CustomEvent(name, detail);
}

/**
 * Dipatches a Nirgel-namespaced custom DOM event
 *
 * N.B. The event will be prefixed with the app namespace automatically to safeguard
 * against name collisions from other sources.
 *
 * @param name Name of the event
 * @param detail Data to be attached to the event
 *
 * @usage Say we want to emit an event named ```my-event```, we have to listen to it as follows:
 * ```javascript
 * addEventListener('nirgel-spa:my-event', (e) => {})
 * ```
 */
export function emitTrekaptEvent(name: string, detail?: { detail: any }) {
  dispatchEvent(createEvent(`${Constants.appNamespace}:${name}`, detail));
}
