import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../core/services/auth.service';

@Pipe({
  name: 'restrict',
})
export class RestrictPipe implements PipeTransform {
  private roles: string[];
  constructor(private auth: AuthService) {
    this.getRoles();
  }

  transform(value: string): boolean {
    return this.roles ? this.roles.includes(value) : false;
  }

  private getRoles() {
    this.roles = this.auth.getUserProfile() ? this.auth.getUserProfile().roles : [];
  }
}
