import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { routesAdmin } from './admin/admin-routing.module';
import { LoginComponent } from './core/login/login.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { SignupComponent } from './core/signup/signup.component';
import { FaqComponent } from './core/faq/faq.component';
import { ExternalPagesDummyComponent } from './core/external-pages-dummy/external-pages-dummy.component';
import { ExternalPagesGuard } from './core/external-pages-dummy/external-pages.guard';
import { PopupLayoutComponent } from './@core/components/popup/popup-layout/popup-layout.component';
import { PreferencesComponent } from './@core/components/popup/preferences/preferences.component';
import { HomeComponent } from './@core/components/home/home.component';
import { PrintLayoutComponent } from './@core/components/printing/print-layout/print-layout.component';
import { TrackablesPrintComponent } from './@core/components/printing/trackables-print/trackables-print.component';
import { ParameterValuesPrintComponent } from './@core/components/printing/parameter-values-print/parameter-values-print.component';

export const appRoutes: Routes = [
  { path: 'home', component: HomeComponent },
  // { path: 'signup', component: SignupComponent },
  { path: 'login', component: LoginComponent },
  // { path: 'faq', component: FaqComponent },
  {
    path: 'external-page',
    component: ExternalPagesDummyComponent,
    canActivate: [ExternalPagesGuard],
  },
  ...routesAdmin,
  {
    path: 'subscribed',
    loadChildren: () =>
      import('./subscribed/subscribed.module').then((m) => m.SubscribedModule),
  },

  {
    path: 'popup',
    outlet: 'popup',
    component: PopupLayoutComponent,
    children: [
      {
        path: 'preferences',
        component: PreferencesComponent,
        data: { header: 'Preferences' },
      },
    ],
  },
  {
    path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      {
        path: 'trackables',
        component: TrackablesPrintComponent,
      },
      {
        path: 'parameter-values',
        component: ParameterValuesPrintComponent,
      },
    ],
  },
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top',
      enableTracing: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
