<section>
    <header class="my-4">
        <div class="eclipse black">
            <h1 class="page-heading" id="page-title">Login</h1>
        </div>
    </header>
    <div class="container row mx-auto mt-5 mb-3 pt-4 form_wrapper">
        <form novalidate [formGroup]="loginForm">
            <div class="form-group row">
                <label for="username" class="col-sm-4 col-form-label">Username</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" id="username" placeholder="username"
                        formControlName="username" [ngClass]="{'is-invalid': formErrors.username}">
                    <span class="invalid-feedback" aria-live="assertive">{{ formErrors.username }}</span>
                </div>
            </div>
            <div class="form-group row mb-4">
                <label for="password" class="col-sm-4 col-form-label">Password</label>
                <div class="col-sm-8">
                    <input type="password" class="form-control" id="password" placeholder="Password"
                        formControlName="password" [ngClass]="{'is-invalid': formErrors.password}">
                    <span class="invalid-feedback" aria-live="assertive">{{ formErrors.password }}</span>
                </div>
            </div>
            <div class="form-group row buttons_wrapper">
                <div class="col-sm-4 my-2">
                    <button type="button" class="btn btn-success green" (click)="login('general')"
                        [disabled]="!loginForm.valid">General Login</button>
                </div>
                <div class="col-sm-4 my-2">
                    <button type="button" class="btn btn-success green" (click)="login('admin')"
                        [disabled]="!loginForm.valid">Admin Login</button>
                </div>
                <div class="col-sm-4 my-2">
                    <button [routerLink]="['/home']" type="button" class="btn btn-danger red">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</section>