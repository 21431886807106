import { Injectable } from '@angular/core';

// import {
//   ISiteProfile,
//   IWebPage,
//   IPicture,
//   IFooter,
//   IHeader,
// } from '@smx/components';
import { AuthService } from '../../../core/services/auth.service';
// TODO: Investigate why importing from '@smx/components' is not working
import {
  IFooter,
  IHeader,
  ISiteProfile,
  IWebPage,
} from '../../../../../../../libs/ngx-smx-components/src';

export interface ISubjects {
  juniorLevel?: string[];
  oLevel?: string[];
  aLevel?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService implements ISiteProfile {
  // Constants
  defaultAosDuration = 500;
  developerDetails = {
    designerUrl: 'https://jasper.trackapt.co.zw',
    designerLogoUrl: '/assets/images/jasper-logo.png',
    designerName: 'Jasper Devs',
  };

  // General
  websiteUrl = `https://nirgel.com`;
  appUrl = window.location.origin;
  institutionFullName = 'Nirgel Trucking & Plant Hire';
  institutionAlias = 'Nirgel';
  institutionAbreviation = '';
  institutionMainLogo = '/assets/images/logo-with-name.png';
  institutionDisplayName = `${
    this.institutionFullName.length > 20
      ? this.institutionAlias.length > 20
        ? this.institutionAbreviation
        : this.institutionAlias
      : this.institutionFullName
  }`;
  streetAddress = [`11 Barnes Avenue, The Grange, Harare`];

  mainContactNumber = '(+263) 774-402-292';
  contactNumbers = [this.mainContactNumber];
  mainContactEmail = 'admin@nirgel.com';
  contactEmails = [this.mainContactEmail, `admin@info@nirgel.com`];

  // Social
  socialProfile = {
    facebookUrl: 'https://www.facebook.com/profile.php?id=61558711649636',
    // twitterUrl: 'https://twitter.com',
    // messengerUrl: 'https://m.facebook.com',
    // instagramUrl: 'https://instagram.com',
  };

  footerDesign: Partial<IFooter> = {
    footerBaseColor: '#1f1c1a',
    hideSocial: true,
  };

  headerDesign: IHeader = {
    // hasHeaderTop: true,
    // headerTopColor: '#1f1c1a',
    // headerTopLinkColor: 'gold',
    // isSideNav: true,
    // hasProgressBar: true,
    // progressBarColor: 'red',
    // progressBarPosition: 'bottom',
    headerBaseLinkColor: 'white',
    headerBaseColor: 'gold',
    // hasFloatingSocial: true,
    headerLogoUrl:
      this.institutionMainLogo || 'assets/images/logo-with-name.png',
    showAuxNavLinks: true,
    showUserProfile: false,
    auxNavLinks: [
      { link: 'subscribed/tracking/trackables', label: 'Tracked Groups' },
      {
        link: this.user ? 'auth/logout' : 'auth/login',
        label: this.user ? 'Logout' : 'Login',
      },
    ],
    hideNavLinks: true,
    navLinks: [
      { preIcon: 'home', link: '/home', label: 'Home' },
      { preIcon: 'dashboard', link: 'dashboard', label: 'Dashboard' },
      {
        preIcon: 'people',
        label: 'Students',
        postIcon: 'arrow_right',
        link: '',
        isDropdown: true,
        dropdownItems: [
          {
            preIcon: 'visibility',
            label: 'View Students',
            // postIcon: '',
            link: 'students',
          },
          {
            preIcon: 'person_add',
            label: 'Add New Student',
            // postIcon: 'arrow_right',
            link: 'students/add',
          },
        ],
      },
      {
        preIcon: 'school',
        label: 'Staff',
        postIcon: 'arrow_right',
        link: '',
        isDropdown: true,
        dropdownItems: [
          {
            preIcon: 'visibility',
            label: 'View Staff',
            // postIcon: '',
            link: 'staff',
          },
          {
            preIcon: 'person_add',
            label: 'Add New Staff',
            // postIcon: 'arrow_right',
            link: 'staff/add',
          },
        ],
      },
      {
        preIcon: 'point_of_sale',
        label: 'Finance',
        postIcon: 'arrow_right',
        link: '',
        isDropdown: true,
        dropdownItems: [
          {
            preIcon: 'add_circle',
            label: 'Record Income',
            // postIcon: '',
            link: 'income',
          },
          {
            preIcon: 'remove_circle',
            label: 'Record Expenditure',
            // postIcon: 'arrow_right',
            link: 'expenditure',
          },
        ],
      },
    ],
  };

  // Legal
  legal = {
    tcsUrl: '',
    privacyUrl: '',
    eulaUrl: '',
    cookiesUrl: '',
    disclaimerUrl: '',
  };

  pages: { [name: string]: IWebPage } = {
    home: {
      heading: '',
      title: 'Home',
      description: `${this.institutionFullName} is a leader in contract mining`,
      banner: {
        small: '/assets/images/logo.png',
        medium: '/assets/images/logo.png',
        large: '/assets/images/logo.png',
        alt: 'Logo',
      },
    },
  };

  private _user: any;
  get user() {
    return this._user;
  }
  constructor(private authService: AuthService) {
    this.authService.user$.subscribe((user) => {
      this._user = user;
    });
  }
}
