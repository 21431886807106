<nb-card>
  <nb-card-header class="d-flex">
    <h1 class="page-heading">Manage Account</h1>
    <nb-actions class="ms-auto">
      <nb-action
        (click)="back()"
        icon="arrow-back-outline"
        nbTooltip="Go to previous page"
        nbTooltipIcon="arrow-back-outline"
        [nbTooltipTrigger]="NbTrigger.HINT"
      ></nb-action>
    </nb-actions>
  </nb-card-header>
  <nb-card-body class="container row mx-auto mt-3 mb-3 py-2 form_wrapper">
    <nb-card class="mx-3 my-3 group">
      <nb-card-header class="d-flex">Profile</nb-card-header>
      <nb-card-body class="arranger">
        <!-- <div class="input-group my-2 mx-auto">
          <div class="input-group-prepend">
            <span class="input-group-text">Pack</span>
          </div>
          <input
            nbInput
            fullWith
            type="text"
            id="package"
            value="{{ activeBundle ? activeBundle.name : '' }}"
            class="form-control profile-data"
            aria-label="Package"
            readonly
          />
          <div class="input-group-append">
            <button
              nbButton
              class="btn btn-success"
              type="button"
              id="upgrade-btn"
              data-bs-toggle="modal"
              data-bs-target="#upgradeModal"
              (click)="setCurrentBundle()"
              aria-label="Up or downgrade package"
              aria-describedby="upgrade-description"
              [disabled]="!('admin' | restrict)"
            >
              Upgrade
            </button>
          </div>
        </div>
        <div class="visually-hidden" id="upgrade-description">
          Please note that you will not be asked to pay anything when you up or
          down grade via this button. Your remaining credit is used to buy the
          required package. Consequently, your account expiry will increase when
          downgrading and decrease when upgrading
        </div>
        <div class="input-group my-2 mx-auto">
          <div class="input-group-prepend">
            <span class="input-group-text">Expiry</span>
          </div>
          <input
            nbInput
            fullWith
            type="text"
            value="{{ expiry }}"
            class="form-control profile-data"
            aria-label="Account expiry date"
            id="expiry"
            readonly
          />
          <div class="input-group-append">
            <button
              nbButton
              class="btn btn-success"
              type="button"
              id="make-payment"
              aria-label="Extend account expiry date"
              [routerLink]="['/admin/account/make-payment']"
            >
              Extend
            </button>
          </div>
        </div> -->
        <div class="input-group my-2 mx-auto" *ngIf="isLoggedIn">
          <div class="input-group-prepend">
            <span class="input-group-text">Email</span>
          </div>
          <input
            nbInput
            fullWith
            type="text"
            value="{{ userProfile.userEmail }}"
            class="form-control profile-data"
            aria-label="Logged in user email"
            id="userEmail"
            readonly
          />
        </div>
        <div class="input-group my-2 mx-auto" *ngIf="isLoggedIn">
          <div class="input-group-prepend">
            <span class="input-group-text">Level</span>
          </div>
          <input
            nbInput
            fullWith
            type="text"
            value="{{ userProfile.role }}"
            class="form-control profile-data"
            aria-label="Logged in user Clearance Level"
            id="user-role"
            readonly
          />
        </div>
      </nb-card-body>
    </nb-card>

    <nb-card
      class="mx-3 my-3 group"
      *ngIf="isOnline || (!('level-1' | restrict) && !isOnline)"
    >
      <nb-card-header class="d-flex">Actions</nb-card-header>
      <nb-card-body class="arranger">
        <!-- <button
          nbButton
          fullWith
          type="button"
          id="manage-team"
          class="flex-fill mx-2 my-2"
          [routerLink]="['/admin/team']"
          *ngIf="'level-2' | restrict"
        >
          Manage Team
        </button> -->
        <button
          nbButton
          type="button"
          id="change-password"
          class="flex-fill mx-2 my-2"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
          *ngIf="isOnline"
        >
          Change Password
        </button>
        <button
          nbButton
          class="flex-fill mx-2 my-2"
          title="Clear cache"
          data-bs-toggle="modal"
          data-bs-target="#clear-data"
        >
          Clear Cache
        </button>
        <!-- <button
          nbButton
          class="flex-fill mx-2 my-2"
          title="Delete Account"
          data-bs-toggle="modal"
          data-bs-target="#delete_account"
          *ngIf="isOnline && isAdmin"
        >
          Delete Account
        </button> -->
      </nb-card-body>
    </nb-card>

    <!-- Password Change Modal -->
    <div
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <nb-card class="modal-content">
          <nb-card-header class="">
            <h2 class="modal-title" id="staticBackdropLabel">
              <b>CHANGE PASSWORD</b>
            </h2>
          </nb-card-header>
          <nb-card-body class="">
            <form id="changePasswordForm" [formGroup]="changePasswordForm">
              <div
                formGroupName="passwords"
                [ngClass]="{ 'is-invalid': formErrors.passwords }"
              >
                <div class="form-group row mb-4">
                  <label for="password" class="col-sm-4 col-form-label"
                    >New Password</label
                  >
                  <div class="col-sm-8">
                    <input
                      nbInput
                      fullWith
                      type="password"
                      class="form-control"
                      id="password"
                      placeholder="Password"
                      formControlName="password"
                      [ngClass]="{
                        'is-invalid': formErrors['passwords-password']
                      }"
                    />
                    <span class="invalid-feedback" aria-live="assertive">{{
                      formErrors['passwords-password']
                    }}</span>
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="confirmPassword" class="col-sm-4 col-form-label"
                    >Confirm Password</label
                  >
                  <div class="col-sm-8">
                    <input
                      nbInput
                      fullWith
                      type="password"
                      class="form-control"
                      id="confirmPassword"
                      placeholder="Password"
                      formControlName="confirmPassword"
                    />
                  </div>
                </div>
              </div>
              <span class="invalid-feedback" aria-live="assertive">{{
                formErrors.passwords
              }}</span>
            </form>
          </nb-card-body>
          <nb-card-footer class="arranger">
            <button
              nbButton
              fullWidth
              form="changePasswordForm"
              type="submit"
              class="mb-2"
              data-bs-dismiss="modal"
              [disabled]="!changePasswordForm.valid"
              (click)="changePassword(changePasswordForm.value)"
            >
              Reset Password
            </button>
            <button
              nbButton
              fullWidth
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </nb-card-footer>
        </nb-card>
      </div>
    </div>

    <!-- Account upgrade Modal -->
    <div
      class="modal fade"
      id="upgradeModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="upgradeLabel"
      aria-describedby="table-summary"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <nb-card class="modal-content">
          <nb-card-header class="d-flex">
            <h2 class="modal-title d-inline-block" id="upgradeLabel">
              ACCOUNT UPGRADE
            </h2>
            <nb-actions class="ms-auto">
              <nb-action
                data-bs-dismiss="modal"
                aria-label="Close Account Upgrade window"
                icon="close-outline"
                nbTooltipIcon="close-outline"
                nbTooltip="Close"
              ></nb-action>
            </nb-actions>
          </nb-card-header>
          <nb-card-body>
            <div
              class="d-flex flex-column flex-md-row justify-content-around justify-content-md-between"
            >
              <button
                nbButton
                [ngClass]="{
                  'btn col col-md-2 mx-1 my-1 bundle': true,
                  option: activeBundle ? activeBundle._id !== bundle._id : true,
                  selected: activeBundle
                    ? activeBundle._id === bundle._id
                    : false
                }"
                *ngFor="let bundle of bundles; let i = index"
                (click)="accountUpgrade(bundle.name)"
                attr.aria-label="{{
                  activeBundle
                    ? (bundle.weight > activeBundle.weight
                        ? 'Upgrade to '
                        : bundle.weight === activeBundle.weight
                        ? 'Your active package, '
                        : 'Downgrade to ') + bundle.name
                    : 'Change bundle'
                }}"
              >
                <span *ngIf="actions[bundle.name]">
                  {{
                    activeBundle
                      ? bundle.weight > activeBundle.weight
                        ? 'Upgrading...'
                        : bundle.weight === activeBundle.weight
                        ? 'Processing...'
                        : 'Downgrading...'
                      : 'Working...'
                  }}
                </span>
                <span *ngIf="!actions.hasOwnProperty(bundle.name)">
                  {{ bundle.name | titlecase }}
                </span>
              </button>
            </div>
          </nb-card-body>

          <nb-card-footer class="modal-footer">
            <div class="table-responsive">
              <table
                tabindex="0"
                class="table table-sm table-bordered table-striped"
                aria-describedby="table-summary"
              >
                <caption>
                  Nirgel packages
                </caption>
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="left-align"
                      aria-label="Package name"
                    >
                      Package
                    </th>
                    <th
                      scope="col"
                      class="center-align"
                      aria-label="Maximum Team Members"
                    >
                      Members
                    </th>
                    <th
                      scope="col"
                      class="center-align"
                      aria-label="Maximum Tracker Groups"
                    >
                      Tracker Groups
                    </th>
                    <th
                      scope="col"
                      class="center-align"
                      aria-label="Maximum Tracked Items"
                    >
                      Tracked Items
                    </th>
                    <th
                      scope="col"
                      class="center-align"
                      aria-label="Maximum Tracked Parameters"
                    >
                      Tracked Parameters
                    </th>
                    <th
                      scope="col"
                      class="center-align"
                      aria-label="US Dollars per month"
                    >
                      USD/Month
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let bundle of bundles; let i = index">
                    <td scope="row" tabindex="0" class="left-align">
                      {{ bundle.name | titlecase }}
                    </td>
                    <td tabindex="0" class="center-align">
                      {{
                        bundle.name.toLowerCase() === 'enterprise'
                          ? 'Unlimited'
                          : bundle.maxMembers
                      }}
                    </td>
                    <td tabindex="0" class="center-align">
                      {{
                        bundle.name.toLowerCase() === 'enterprise'
                          ? 'Unlimited'
                          : bundle.maxTrackables
                      }}
                    </td>
                    <td tabindex="0" class="center-align">
                      {{
                        bundle.name.toLowerCase() === 'enterprise'
                          ? 'Unlimited'
                          : bundle.maxTrackables * 100
                      }}
                    </td>
                    <td tabindex="0" class="center-align">Unlimited</td>
                    <td tabindex="0" class="center-align">
                      {{ bundle.price | currency }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <span class="visually-hidden" id="table-summary">{{
                srBundlesSummary
              }}</span>
            </div>
          </nb-card-footer>
        </nb-card>
      </div>
    </div>

    <!-- Clear local data Modal -->
    <div
      class="modal fade"
      id="clear-data"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="clear-data-bs-label"
      aria-describedby=""
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <nb-card class="modal-content">
          <nb-card-header class="d-flex">
            <h2 class="d-inline-block" id="clear-data-bs-label">
              CLEAR THIS DEVICE'S CACHE
            </h2>
            <nb-actions class="ms-auto">
              <nb-action
                data-bs-dismiss="modal"
                aria-label="Close clear cache window"
                icon="close-outline"
                nbTooltip="Close"
                nbTooltipIcon="close-outline"
              ></nb-action>
            </nb-actions>
          </nb-card-header>
          <nb-card-body>
            <div class="d-flex">
              <form id="clearLocalDataForm" [formGroup]="clearLocalDataForm">
                <div class="options mb-5">
                  <div
                    class="btn-group d-flex flex-column flex-sm-row flex-wrap justify-content-sm-between"
                    data-bs-toggle="buttons"
                  >
                    <label class="btn my-2 option">
                      <input
                        type="radio"
                        name="depth"
                        id="user"
                        value="user"
                        formControlName="depth"
                        aria-checked="true"
                        checked
                      />
                      User
                    </label>
                    <label class="btn my-2 option">
                      <input
                        type="radio"
                        name="depth"
                        id="account"
                        value="account"
                        formControlName="depth"
                      />
                      Account
                    </label>
                    <label class="btn my-2 option">
                      <input
                        type="radio"
                        name="depth"
                        id="application"
                        value="application"
                        formControlName="depth"
                      />
                      Application
                    </label>
                  </div>
                </div>
              </form>
            </div>
            <div class="dropdown-divider"></div>
            <div class="info">
              <div *ngIf="clearLocalDataForm.get('depth').value === 'user'">
                <p>
                  Clears cache (local data stored on this device) and settings
                  relating to the logged in user
                </p>
              </div>
              <div *ngIf="clearLocalDataForm.get('depth').value === 'account'">
                <p>
                  Clears cache (local data stored on this device) and settings
                  relating to the entire account
                </p>
                <p>
                  <nb-icon
                    class="icon exclamation-triangle"
                    icon="alert-triangle-outline"
                  ></nb-icon>
                  This also clears other Team Members' local data and settings
                  within the same organisation if you are sharing this device
                  with them
                </p>
              </div>
              <div
                *ngIf="clearLocalDataForm.get('depth').value === 'application'"
              >
                <p>
                  Clears cache (local data stored on this device) and settings
                  relating to the entire application
                </p>
                <p>
                  <nb-icon
                    class="icon exclamation-triangle"
                    icon="alert-triangle-outline"
                  ></nb-icon>
                  This also clears other Team Members' local data and settings
                  across organisation-level accounts if you are sharing this
                  device with them
                </p>
              </div>
            </div>
          </nb-card-body>

          <nb-card-footer class="d-flex">
            <button
              nbButton
              form="clearLocalDataForm"
              type="submit"
              class="flex-fill"
              data-bs-dismiss="modal"
              [disabled]="!clearLocalDataForm.valid"
              (click)="clearLocalData(clearLocalDataForm.get('depth').value)"
            >
              Clear Cache & Restart App
            </button>
          </nb-card-footer>
        </nb-card>
      </div>
    </div>

    <app-modal-popup
      (formData)="deleteAccount()"
      identifier="delete_account"
      bodyText="Are you sure you want to DELETE your Account? This is irreversible; all data will be lost."
      buttonText="Yes"
      [isForm]="false"
    ></app-modal-popup>
  </nb-card-body>
</nb-card>
