import { getRandomInt, uid } from './uid-generator';

/**
 * Transforms an array into a string of grammatically correct phrase
 *
 * @param array Array to be transformed into a phrase
 * @returns Grammatically correct phrase
 */
export const arrayToPhrase = (array: any[]): string => {
  const ending = () => {
    const endings = ['lastly', 'finally'];
    return array.length < 4 ? ' and ' : ` and ${endings[getRandomInt(0, endings.length - 1)]}, `;
  };
  if (!array.length) return '';
  const andMarker = uid(50);
  array && array.length > 1 ? array.splice(array.length - 1, 0, andMarker) : null;
  return array.join(', ').replace(`, ${andMarker},`, ending());
};
