import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
  NbMenuItem,
  NbTrigger,
  NbDialogService,
} from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { ProfileService } from '../../../@core/utils/services/profile.service';

import { OnlineStatusService } from '../../../@core/utils/services/online-status.service';
import { AuthService } from '../../../core/services/auth.service';
import { DeviceService } from '../../../core/services/device-status.service';
import { PreferencesComponent } from '../../../@core/components/popup/preferences/preferences.component';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() hideLogo = false;
  private destroy$: Subject<void> = new Subject<void>();
  private isOfflinable!: boolean;
  private elementOfInterest!: HTMLElement;
  viewportSize!: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  isXSmall = false;
  isSmall = false;
  isMedium = false;
  isLarge = false;
  isXLarge = false;
  NbTrigger = NbTrigger;
  userPictureOnly = false;
  institutionAbr: string;
  sidebarExpanded = false;
  temperedWith = false;
  touches = 0;
  userName = '';
  userTitle = '';
  user: any;
  // | (IUser & {
  //     userType: UserType;
  //     scope: UserType | StaffType;
  //     picture: string;
  //   } & (IStaff | IStudent | IGuardian))
  // | undefined;

  get userMenu() {
    const activeCollection =
      this.user?.userType === 'student'
        ? 'students'
        : this.user?.userType === 'guardian'
        ? 'guardians'
        : 'staff';
    return [
      {
        title: 'Profile',
        link: `/sms/students-staff/${activeCollection}/edit/${
          this.user?.azp || ''
        }`,
        queryParams: { viewMode: 'profile' },
      },
      {
        title: 'Log Out',
        link: '/auth/logout',
      },
    ] as NbMenuItem[];
  }

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private profileService: ProfileService,
    private authService: AuthService,
    private router: Router,
    private renderer: Renderer2,
    private onlineStatus: OnlineStatusService,
    private dialogService: NbDialogService,
    public deviceService: DeviceService
  ) {
    this.institutionAbr = this.profileService.institutionAbreviation;

    this.onlineStatus.isOfflinable$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => (this.isOfflinable = status));
  }

  ngOnInit() {
    this.authService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
      if (user) {
        const profile = this.authService.getUserProfile();
        this.userName = `${user.nickname}`.toUpperCase();
        this.userTitle = profile.role?.toUpperCase();
      } else {
        if (!this.isOfflinable) {
          // this.router.navigateByUrl('/home');
        }
      }
    });

    this.deviceService.viewportSize$
      .pipe(takeUntil(this.destroy$))
      .subscribe((size) => (this.viewportSize = size));
  }

  ngAfterViewInit(): void {
    const { xl } = this.breakpointService.getBreakpointsMap();
    setTimeout(() => {
      this.elementOfInterest = this.renderer.selectRootElement(
        '.logo',
        true
      ) as HTMLElement;

      this.viewportSize === 'xl' ? (this.sidebarExpanded = true) : null;

      this.themeService
        .onMediaQueryChange()
        .pipe(
          tap(([, currentBreakpoint]) => {
            setTimeout(() => {
              this.renderer?.setAttribute(
                this.elementOfInterest,
                'src',
                this.viewportSize === 'xs'
                  ? '/assets/images/logo-letter.png'
                  : '/assets/images/logo-with-name.png'
              );

              this.renderer.setStyle(
                this.elementOfInterest,
                'height',
                this.viewportSize === 'xs' ? '1.8rem' : '3.5rem'
              );
            });
            const currentWidth = currentBreakpoint.width;
            this.setScreenSize(currentWidth);

            this.touches += 1;
            this.touches > 1 &&
            (currentBreakpoint.name === 'xl' || currentBreakpoint.name === 'lg')
              ? (this.temperedWith = true)
              : null;
          }),
          map(([, currentBreakpoint]) => {
            return currentBreakpoint.width < xl;
          }),
          takeUntil(this.destroy$)
        )
        .subscribe((isLessThanXl: boolean) => {
          this.userPictureOnly = isLessThanXl;
        });

      this.listenForSidebarStatus();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout() {
    this.authService.logout();
  }

  profile() {
    this.router.navigateByUrl(`/admin/account/manage-account`);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  themes() {
    // this.router.navigate([
    //   '/',
    //   {
    //     outlets: {
    //       popup: ['popup', 'preferences'],
    //     },
    //   },
    // ]);

    this.dialogService.open(PreferencesComponent);
  }

  private listenForSidebarStatus() {
    this.sidebarService
      .onToggle()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.sidebarExpanded = !this.sidebarExpanded;
      });
  }

  private setScreenSize(currentWidth) {
    const { sm, md, lg, xl } = this.breakpointService.getBreakpointsMap();
    this.isXSmall = false;
    this.isSmall = false;
    this.isMedium = false;
    this.isLarge = false;
    this.isXLarge = false;

    if (currentWidth <= sm) {
      this.isXSmall = true;
    } else if (currentWidth <= md) {
      this.isSmall = true;
    } else if (currentWidth <= lg) {
      this.isMedium = true;
    } else if (currentWidth <= xl) {
      this.isLarge = true;
    } else {
      this.isXLarge = true;
    }
  }
}
