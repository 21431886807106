/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerService } from '@smx/components';
import { NotificationService } from '../../../core/services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  isPrinting = false;
  printData!: any[] | any;

  constructor(
    private router: Router,
    private spinner: SpinnerService,
    private notifier: NotificationService
  ) {}

  printDocument(
    documentRoute: 'trackables' | 'trackers' | 'members' | 'parameter-values',
    documentData: any[] | any
  ) {
    this.printData =
      documentData instanceof Array ? new Array(...documentData) : documentData;
    if (!this.printData) {
      return this.notifier.showError(
        'Print data not found. Contact Support if the problem persists'
      );
    }
    this.isPrinting = true;
    this.router
      .navigate([
        '/',
        {
          outlets: {
            print: ['print', documentRoute],
          },
        },
      ])
      .then(() => this.onDataReady())
      .catch((error) => console.error(error));
  }

  cancelPrinting() {
    this.isPrinting = false;
    this.router.navigate([{ outlets: { print: null } }]);
  }

  private onDataReady() {
    this.spinner.show();
    // Gives a bit of time for the page (especially images) to load before requesting print.
    // Chromium based browsers, especially Chrome were not displaying images on print preview on first try
    setTimeout(() => {
      this.spinner.hide();
      window.print();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null } }]);
    }, 3000);
  }
}
