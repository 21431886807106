import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AddMemberComponent } from './team/add-member.component';
import { AdminComponent } from './admin.component';
import { TeamComponent } from './team/team.component';
import { TeamResolverService } from './team/resolvers/team-resolver.service';
import { AuthGuardService } from '../core/services/auth-guard.service';
import { routesAccount } from '../account/account-routing.module';
import { CanDeactivateAddMember } from './team/can-deactivate-add-member';

export const routesAdmin: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuardService],
    resolve: { team: TeamResolverService },
    runGuardsAndResolvers: 'always',
    children: [
      { path: 'team', component: TeamComponent },
      {
        path: 'team/:id/edit',
        component: AddMemberComponent,
        canDeactivate: [CanDeactivateAddMember],
      },
      ...routesAccount,
      { path: '', pathMatch: 'full', redirectTo: 'team' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routesAdmin)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
