import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountComponent } from './account.component';
import { MakePaymentComponent } from './make-payment/make-payment.component';
import { ActiveBundleResolverService } from './active-bundle-resolver.service';
import { PayGuardService } from './make-payment/pay-guard.service';
import { ContactSupportComponent } from './contact-support/contact-support.component';
import { ManageAccountComponent } from './manage/manage-account.component';
import { AccountDetailsResolverService } from './manage/account-details-resolver.service';
import { ContactSupportGuard } from './contact-support/contact-support.guard';
import { BundlesResolverService } from './bundles-resolver.service';
import { ExchangeRateResolver } from './make-payment/exchange-rate.resolver';

export const routesAccount: Routes = [
  {
    path: 'account',
    component: AccountComponent,
    resolve: { activeBundle: ActiveBundleResolverService, bundles: BundlesResolverService },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'make-payment',
        component: MakePaymentComponent,
        resolve: { exchangeRate: ExchangeRateResolver },
      },
      {
        path: 'contact-support',
        component: ContactSupportComponent,
        canDeactivate: [ContactSupportGuard],
      },
      {
        path: 'manage-account',
        component: ManageAccountComponent,
        resolve: { details: AccountDetailsResolverService },
      },
      { path: '', pathMatch: 'full', redirectTo: 'manage-account' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routesAccount)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
