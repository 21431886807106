import { ISimpleClaim } from './simple-claim';
import { ITeamMember } from './team-member';

export class AuthContext {
  claims: ISimpleClaim[];
  userProfile: ITeamMember;

  get isAdmin() {
    return !!this.claims && !!this.claims.find(c => c.type === 'role' && c.value === 'Admin');
  }
}
