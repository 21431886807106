import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { MatMenuItem, MatMenuTrigger } from '@angular/material/menu';

import { INavLink } from '../..';
import { MenuTriggersService } from '../services/menu-triggers.service';

@Component({
  selector: 'smx-mat-menu-wrapper',
  templateUrl: './mat-menu-wrapper.component.html',
  styleUrls: ['./mat-menu-wrapper.component.scss'],
})
export class MatMenuWrapperComponent implements AfterViewInit {
  @Input()
  matMenuItem!: INavLink;
  @Input()
  timedOutCloser!: any;
  @ViewChildren(MatMenuTrigger) public menuTriggers!: QueryList<MatMenuTrigger>;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

  timeOut = 250;

  constructor(
    private renderer: Renderer2,
    private triggersService: MenuTriggersService
  ) {
    this.renderer.listen('body', 'click', () => {
      this.closeMenus(this.triggersService.activeTrigger);
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.menuTriggers.forEach((t) => {
        // TODO: Findout why parentMenu is undefined till triggers are visited. The workaround below is to overcome that
        t.toggleMenu();
        t.toggleMenu();
        this.triggersService.menuTriggers.push(t);
      });
    });
  }

  clicked(trigger: MatMenuTrigger, event: MouseEvent) {
    event.preventDefault();
    // console.log(event);
  }

  mouseEnter(trigger: MatMenuTrigger, e: MouseEvent) {
    // if (isClicked && trigger.menuOpen) return;
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
      this.notify.emit(true);
    }

    this.closeMenus(trigger);
    trigger.openMenu();
    // Force the focus highlight to be on the active element only
    trigger.focus();
    this.triggersService.activeTrigger = trigger;
  }

  mouseLeave(trigger: MatMenuTrigger) {
    this.timedOutCloser = setTimeout(() => {
      this.notify.emit(false);
      trigger.closeMenu();
    }, this.timeOut);
    this.triggersService.activeTrigger = undefined;
  }

  childMouseEnter(link: MatMenuItem) {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
      this.notify.emit(true);
    }
    link.focus();
  }

  childMouseLeave(link: MatMenuItem) {
    this.notify.emit(false);
  }

  onNotify(isOpen: boolean): void {
    if (isOpen) {
      if (this.timedOutCloser) {
        clearTimeout(this.timedOutCloser);
        this.notify.emit(true);
      }
    } else {
      this.timedOutCloser = setTimeout(() => {
        this.notify.emit(false);
      }, this.timeOut);
    }
  }

  closeMenus(activeTrigger?: MatMenuTrigger) {
    const familyTriggers =
      this.triggersService.getTriggerAncestors(activeTrigger);
    for (const t of this.triggersService.menuTriggers) {
      if (activeTrigger) {
        if (familyTriggers.includes(this.triggersService.getTriggerName(t))) {
          continue;
        } else {
          t.closeMenu();
        }
      } else {
        t.closeMenu();
      }
    }
  }
}
