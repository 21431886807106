/**A ```decorator``` that unsubscribes any observable subscription in a class when the component is destroyed */
export const AutoUnsubscribe = () => {
  return function (constructor) {
    const orignalNgOnDestroy = constructor.prototype.ngOnDestroy;
    constructor.prototype.ngOnDestroy = function () {
      for (const prop in this) {
        const property = this[prop];
        if (!!property && typeof property.subscribe === 'function') {
          property.unsubscribe();
        }
      }
      !!orignalNgOnDestroy ? orignalNgOnDestroy.apply() : null;
    };
  };
};
