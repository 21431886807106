<div class="header-container">
  <div class="logo-container">
    <a
      title="Toggle sidebar"
      (click)="toggleSidebar()"
      href="#"
      class="sidebar-toggle"
    >
      <nb-icon *ngIf="temperedWith" icon="menu-2-outline"></nb-icon>
      <nb-icon
        *ngIf="!temperedWith"
        [icon]="sidebarExpanded ? 'menu-arrow-outline' : 'menu-outline'"
      >
      </nb-icon>
    </a>
    <img [hidden]="hideLogo" class="logo" alt="logo" (click)="navigateHome()" />
    <ng-content></ng-content>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action> -->
    <nb-action
      class="control-item"
      icon="color-palette-outline"
      (click)="themes()"
      nbTooltip="Themes"
      nbTooltipIcon="color-palette-outline"
      [nbTooltipTrigger]="NbTrigger.HINT"
    ></nb-action>
    <nb-action
      class="control-item"
      icon="email-outline"
      link="admin/account/contact-support"
    ></nb-action>
    <!-- <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action class="user-action">
      <nb-user
        [nbPopover]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="userName"
        [picture]="user?.picture || ''"
        [showInitials]="true"
        [title]="userTitle"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>

<ng-template #userMenu>
  <nb-list class="menu">
    <nb-list-item (click)="profile()" style="cursor: pointer">
      <nb-icon icon="person-outline" class="me-2"></nb-icon>
      <span>My Account</span>
    </nb-list-item>
    <nb-list-item (click)="themes()" style="cursor: pointer">
      <nb-icon icon="color-palette-outline" class="me-2"></nb-icon>
      <span>Themes</span>
    </nb-list-item>
    <nb-list-item (click)="logout()" style="cursor: pointer">
      <nb-icon icon="log-out-outline" class="me-2"></nb-icon>
      <span>Logout</span>
    </nb-list-item>
  </nb-list>
</ng-template>
