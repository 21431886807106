import { Injectable } from '@angular/core';
import { Constants } from '../../constants';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, retry, take, tap } from 'rxjs/operators';

import { IMobile } from '../../global-models/mobile';
import { errorMessage } from '../../helpers/error-message';
import { NotificationService } from './notification.service';
import { ErrorLoggingService } from './logging.service';
import { StoreService } from './store.service';
import { IndexDbService } from './index-db.service';
import { IExchangeRate, IExchangeRateIndexedDb } from '../../tracking/interfaces-enums/exchange-rate';

@Injectable()
export class PayService {
  /**Active Bundle monthly price */
  price: number;

  mobileProvider: string;
  mobileData: IMobile;
  bundleName: string;

  private trackingApiRoot = Constants.trackingApiRoot;
  private paynowPath = Constants.paynowPath;
  private pricingPath = Constants.pricingPath;
  private owner: string;
  private account: string;
  private currentTerms: number;
  private dbExchangeRate: number;

  get terms() {
    if (this.currentTerms) return this.currentTerms;

    const rawTerms = localStorage.getItem(`terms:${this.owner}:${this.account}:${Constants.appNamespace}`) || '1';
    this.currentTerms = JSON.parse(rawTerms);
    return this.currentTerms;
  }

  set terms(term: number) {
    this.currentTerms = term;
    localStorage.setItem(`terms:${this.owner}:${this.account}:${Constants.appNamespace}`, JSON.stringify(term));
  }

  constructor(
    private http: HttpClient,
    private notifier: NotificationService,
    private errorLogger: ErrorLoggingService,
    private store: StoreService,
    private db: IndexDbService,
  ) {
    this.store.account$.subscribe(account => {
      if (account) {
        this.account = account;
      }
    });

    this.store.user$.subscribe(user => {
      if (user) {
        this.owner = user;
      }
    });

    this.store.exchangeRate$.subscribe(rate => {
      if (rate) {
        this.dbExchangeRate = rate;
      }
    });
  }

  payMobile(mobileData: IMobile): Observable<any> {
    return this.http.post<any>(`${this.trackingApiRoot}/${this.paynowPath}/mobile/pay`, mobileData).pipe(retry(2));
  }

  checkMobilePayment(mobileData: IMobile) {
    return this.http.post<any>(`${this.trackingApiRoot}/${this.paynowPath}/mobile/check`, mobileData).pipe(retry(2));
  }

  getExchangeRate(): Observable<IExchangeRate> {
    let fromIndexedDb = false;
    return this.http.get<IExchangeRate>(`${this.trackingApiRoot}/${this.pricingPath}/exchange-rate`).pipe(
      retry(!this.dbExchangeRate && !this.store.isOnline ? 50 : 2),
      catchError(error => {
        if (this.dbExchangeRate && error instanceof HttpErrorResponse && (error.status === 0 || error.status === 504)) {
          fromIndexedDb = true;
          return of<IExchangeRate>({ rate: this.dbExchangeRate });
        }
        throw error;
      }),
      tap<IExchangeRate>(exRate => {
        if (!fromIndexedDb && this.account && this.owner) {
          if (exRate) {
            const rateObjDb: IExchangeRateIndexedDb = { rate: exRate.rate, account: this.account, owner: this.owner };
            this.db.exchangeRates
              .where('account')
              .equals(this.account)
              .and(er => er.owner === this.owner)
              .delete()
              .then(() => {
                this.db.exchangeRates
                  .add(rateObjDb)
                  .then(_ => (this.store.rate = rateObjDb.rate))
                  .catch(error => {
                    this.notifier.showError(errorMessage(error));
                  });
              })
              .catch(error => {
                this.notifier.showError(errorMessage(error));
              });
          }
        }
        fromIndexedDb = false;
      }),
    );
  }
}
