import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ITeamMember } from '../../global-models/team-member';
import { Constants } from '../../constants';

import { map, retry } from 'rxjs/operators';

@Injectable()
export class TeamService {
  regDetails: any;

  constructor(private http: HttpClient) {}

  getTeam(): Observable<ITeamMember[]> {
    return this.http.get<ITeamMember[]>(`${Constants.trackingApiRoot}/${Constants.membersPath}/get`);
  }

  getMember(memberId: string): Observable<ITeamMember> {
    return this.http
      .get<ITeamMember>(`${Constants.trackingApiRoot}/${Constants.membersPath}/get/${memberId}`)
      .pipe(retry(2));
  }

  addMember(member: ITeamMember): Observable<any> {
    return this.http.post<any>(`${Constants.trackingApiRoot}/${Constants.membersPath}/create`, member).pipe();
  }

  editMember(member: ITeamMember): Observable<any> {
    return this.http.put(`${Constants.trackingApiRoot}/${Constants.membersPath}/edit/`, member).pipe();
  }

  deleteMember(memberId: string): Observable<any> {
    return this.http.delete(`${Constants.trackingApiRoot}/${Constants.membersPath}/delete/${memberId}`).pipe();
  }

  deleteSuperUser(): Observable<any> {
    return this.http.delete(`${Constants.trackingApiRoot}/${Constants.usersPath}/delete/me`).pipe();
  }

  changePassword(data: any) {
    return this.http.post<{ message: string }>(
      `${Constants.trackingApiRoot}/${Constants.personalPath}/change-password`,
      data,
    );
  }
}
