/**
 * Removes the deleted object from the UI
 *
 * This is mainly intended for objects added to the UI through Angular's ```*ngFor``` directive.
 *
 * @param objectIndex - The index of the deleted object.
 * @param array - The array on which the ```*ngFor``` is acting upon.
 */
export const removeDeletedObjectFromUI = (objectIndex: number, array: any[]) => {
  array.splice(objectIndex, 1);
  array = new Array(...array);
};

