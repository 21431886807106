import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../core/services/date.service';

@Pipe({
  name: 'fromUnix',
})
export class FromUnixPipe implements PipeTransform {
  date = new DateService();
  /**
   *
   * @param value The unix value to transform in seconds
   * @param format Output format - defaults to ```lll```
   */
  transform(value: number, format = 'lll'): string {
    return this.date.fromUnix(value, format);
  }
}
