import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, Injectable, NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HammerModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';

import {
  NbSidebarModule,
  NbMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbWindowModule,
  NbToastrModule,
} from '@nebular/theme';

import Hammer from 'hammerjs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
import { AdminModule } from './admin/admin.module';
import { ThemeModule } from './@theme/theme.module';
import { LayoutService } from './@core/utils';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'pan-y',
      domEvents: true,
    });

    mc.get('tap').set({ enable: true, interval: 250, taps: 2 });
    // TODO: Find away to enable native zoom support on browsers. The code below didn't work
    mc.get('pinch').set({ enable: false });
    mc.get('rotate').set({ enable: false });

    return mc;
  }
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
    press: { time: 500 },
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    HammerModule,
    CoreModule,
    AdminModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    ThemeModule.forRoot(),
    ServiceWorkerModule.register(environment.serviceWorker),
  ],
  providers: [
    LayoutService,
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'USD' },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
