import { Component } from '@angular/core';
import { ProfileService } from '../../../@core/utils/services/profile.service';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <div class="d-flex flex-column align-items-center w-100">
      <p class="text-muted text-center">
        {{
          '&copy; ' +
            year +
            ' ' +
            profileService.institutionFullName +
            '. All rights reserved.'
        }}
      </p>
      <p class="text-center">
        <span
          class="text-muted"
          *ngIf="
            profileService.developerDetails.designerUrl &&
            (profileService.developerDetails.designerLogoUrl ||
              profileService.developerDetails.designerName)
          "
        >
          <span class="d-inline-block"> App design & development by: </span>
          <span class="d-inline-block spacer"></span>
          <a
            class="d-inline-block"
            href="{{ profileService.developerDetails.designerUrl }}"
            target="_blank"
          >
            <span
              class="fst-italic designer-name"
              *ngIf="!profileService.developerDetails.designerLogoUrl"
            >
              <small class="fw-lighter">{{
                profileService.developerDetails.designerName
              }}</small>
            </span>
            <img
              class="designer-logo"
              *ngIf="profileService.developerDetails.designerLogoUrl"
              src="{{ profileService.developerDetails.designerLogoUrl }}"
              alt="{{ profileService.developerDetails.designerName + ' logo' }}"
              title="Jasper Devs"
            />
          </a>
        </span>
      </p>
    </div>
  `,
})
export class FooterComponent {
  year: number;
  constructor(public profileService: ProfileService) {
    this.year = new Date().getFullYear();
  }
}
