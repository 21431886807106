<ng-template let-data let-ref="dialogRef" #popup>
  <nb-card>
    <nb-card-header>{{ data.header }}</nb-card-header>
    <nb-card-body>
      <router-outlet
        (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)"
        (attach)="onAttach($event)"
        (detach)="onDetach($event)"
      ></router-outlet>
    </nb-card-body>
    <nb-card-footer class="d-flex flex-wrap justify-content-between">
      <button
        type="button"
        class="m-2 flex-fill"
        (click)="ref.close()"
        nbButton
        outline
        status="danger"
      >
        <span class="material-icons me-2">close</span>
        <span>close window</span>
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>
