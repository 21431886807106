<button mat-button [matMenuTriggerFor]="userWidget">
  <mat-icon class="material-icons">person</mat-icon>
  <span>{{ 'Hello ' + (user ? user.firstName : '') }}</span>
  <mat-icon>arrow_drop_down</mat-icon>
</button>
<mat-menu #userWidget="matMenu" xPosition="after" [hasBackdrop]="false">
  <button *ngIf="user?.isLoggedIn" mat-menu-item (click)="userProfile()">
    <mat-icon>person</mat-icon>
    <span>My Profile</span>
  </button>
  <button *ngIf="user?.isLoggedIn" mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon>
    <span>Logout</span>
  </button>
  <button *ngIf="!user?.isLoggedIn" mat-menu-item (click)="login()">
    <mat-icon>login</mat-icon>
    <span>Login</span>
  </button>
</mat-menu>
