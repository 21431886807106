import { AbstractControl } from '@angular/forms';

export const specialCharacter = (c: AbstractControl): { [key: string]: boolean } | null => {
  const value = c.value;
  const pattern = /[\W]+/;
  if (!pattern.test(value)) {
    return { specialCharacter: true };
  }
  return null;
};
