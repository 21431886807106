/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopupService implements OnDestroy {
  closePopup$ = new BehaviorSubject(false);
  private dialogRef!: NbDialogRef<any>;
  private subscriptions = new Subscription();

  constructor(private router: Router, private dialogService: NbDialogService) {
    this.closePopup$.subscribe((vedict) => {
      if (vedict && this.dialogRef) {
        this.dialogRef.close();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Opens a dialog/popup window
   *
   * @param dialog Template to instantiate
   * @param header Popup header
   */
  openPopup(dialog: TemplateRef<any>, header: string = 'Window') {
    this.dialogRef = this.dialogService.open(dialog, {
      context: {
        header,
      },
      closeOnEsc: false,
      autoFocus: true,
    });

    const ocSub = this.dialogRef.onClose.subscribe(() => {
      this.router.navigate([{ outlets: { popup: null } }]);
    });

    const obcSub = this.dialogRef.onBackdropClick.subscribe(() => {
      this.router.navigate([{ outlets: { popup: null } }]);
    });

    this.subscriptions.add(ocSub);
    this.subscriptions.add(obcSub);
  }
}
