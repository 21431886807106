import { Injectable, NgZone } from '@angular/core';

import { NbToastrService, NbGlobalPhysicalPosition } from '@nebular/theme';
import { SpinnerService } from './spinner.service';

@Injectable({
  providedIn: 'root',
})
export class NbNotificationService {
  constructor(
    private toastrService: NbToastrService,
    private zone: NgZone,
    private spinner: SpinnerService
  ) {}
  /**
   * Displays success message
   *
   * @param message Message to show
   * @param hideSpinner Flag whether to dismiss the spinner before showing the message. Defaults to `true`
   */
  showSuccess(message: string, hideSpinner = true) {
    const config = {
      status: 'success',
      destroyByClick: true,
      duration: 6000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
      preventDuplicates: true,
    };

    this.zone.run(() => {
      hideSpinner ? this.spinner.hide() : null;
      this.toastrService.show(message, `Success`, config);
    });
  }
  /**
   * Displays error message
   *
   * @param message Message to show
   * @param hideSpinner Flag whether to dismiss the spinner before showing the message. Defaults to `true`
   */
  showError(message: string, hideSpinner = true) {
    const config = {
      status: 'danger',
      destroyByClick: true,
      duration: 10000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
      preventDuplicates: true,
    };
    console.error(message);

    this.zone.run(() => {
      hideSpinner ? this.spinner.hide() : null;
      this.toastrService.show(message, `Error`, config);
    });
  }
  /**
   * Displays informational message
   *
   * @param message Message to show
   * @param hideSpinner Flag whether to dismiss the spinner before showing the message. Defaults to `true`
   */
  showInfo(message: string, hideSpinner = true) {
    const config = {
      status: 'info',
      destroyByClick: true,
      duration: 15000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
      preventDuplicates: true,
    };

    this.zone.run(() => {
      hideSpinner ? this.spinner.hide() : null;
      this.toastrService.show(message, `Info`, config);
    });
  }
}
