import { Constants } from '../constants';
import { IUserProfile } from '../global-models/user-profile.interface';

export const devUserProfile: IUserProfile = {
  userEmail: 'test@nirgel.com',
  role: 'admin',
  roles: Constants.ROLE_MAPPINGS.admin,
  account: 'testaccountnumber',
  sub: 'auth0remoteid',
  nickname: 'mynickname',
  name: 'myname',
  emailVerified: false,
};
