import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PayService } from '../../core/services/pay.service';
import { NotificationService } from '../../core/services/notification.service';

@Injectable()
export class PayGuardService  {
  constructor(private payService: PayService, private notifier: NotificationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (navigator.onLine) {
      return true;
    }
    this.notifier.showError('Sorry you are offline. Try again later.');
    return false;
  }
}
