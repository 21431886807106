<ul
  class="{{ 'root' + index }}"
  [ngClass]="{
    'navbar-nav d-flex justify-content-between links-container root': true,
    'flex-row': orientation === 'horizontal',
    'flex-column': orientation === 'vertical',
    'ms-auto': alignment === 'right',
    'me-auto': alignment === 'left',
    'mt-auto': alignment === 'bottom',
    'mb-auto': alignment === 'top',
    'mx-auto': alignment === 'center-horizontal'
  }"
>
  <li class="nav-item" *ngIf="facebookUrl">
    <a
      href="{{ facebookUrl }}"
      title="Follow us on Facebook"
      class="my-2"
      target="_blank"
      [ngClass]="{
        'text-primary': !iconColor
      }"
    >
      <mat-icon svgIcon="facebook" aria-hidden="true"></mat-icon>
    </a>
  </li>
  <li class="nav-item" *ngIf="twitterUrl">
    <a
      href="{{ twitterUrl }}"
      class="my-2"
      title="Follow us on twitter"
      target="_blank"
      [ngClass]="{
        'text-primary': !iconColor
      }"
    >
      <mat-icon svgIcon="twitter" aria-hidden="true"></mat-icon>
    </a>
  </li>
  <li class="nav-item" *ngIf="instagramUrl">
    <a
      href="{{ instagramUrl }}"
      class="my-2"
      title="Follow us on Instagram"
      target="_blank"
      [ngClass]="{
        'text-primary': !iconColor
      }"
    >
      <mat-icon svgIcon="instagram" aria-hidden="true"></mat-icon>
    </a>
  </li>
</ul>
