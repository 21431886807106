import { Component, Input } from '@angular/core';

import { INavLink } from '../..';

/**
 * ```Bootstrap 5``` based nav-bar
 */
export interface INavBar {
  navLinks: INavLink[];
}

@Component({
  selector: 'smx-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements INavBar {
  @Input() navLinks!: INavLink[];
}
