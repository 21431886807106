import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';

export interface ISocial {
  facebookUrl?: string;
  twitterUrl?: string;
  messengerUrl?: string;
  instagramUrl?: string;
}

export abstract class SocialComponentToken {}

@Component({
  selector: 'smx-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss'],
  providers: [{ provide: SocialComponentToken, useExisting: SocialComponent }],
})
export class SocialComponent implements OnInit, AfterViewInit {
  /**Icons orientation. Defaults to ```horizontal``` */
  @Input() orientation: 'vertical' | 'horizontal' = 'horizontal';
  /**Icons color*/
  @Input() iconColor: string | undefined;
  /**Additional ```Bootstrap 5``` classes to be applied to the icons container */
  @Input() extraClasses = '';
  @Input() index = 0;
  @Input() alignment:
    | 'left'
    | 'right'
    | 'top'
    | 'bottom'
    | 'center-horizontal' = 'right';
  @Input()
  socialProfile: ISocial | undefined;

  facebookUrl: string | undefined;
  twitterUrl: string | undefined;
  messengerUrl: string | undefined;
  instagramUrl: string | undefined;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.facebookUrl = this.socialProfile?.facebookUrl;
    this.twitterUrl = this.socialProfile?.twitterUrl;
    this.messengerUrl = this.socialProfile?.messengerUrl;
    this.instagramUrl = this.socialProfile?.instagramUrl;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const rootElem = this.renderer.selectRootElement(
        `.root${this.index}`,
        true
      ) as HTMLElement;

      const links = rootElem?.querySelectorAll('li a');
      const numOfIcons = links?.length;

      if (rootElem) {
        this.renderer.setStyle(
          rootElem,
          'maxWidth',
          `${numOfIcons ? numOfIcons * 4 : 0}rem`
        );

        this.orientation === 'vertical'
          ? this.renderer.setStyle(
              rootElem,
              'height',
              `${numOfIcons ? numOfIcons * 2.5 : 0}rem`
            )
          : null;
      }

      if (this.iconColor) {
        links?.forEach((link) => {
          this.renderer.setStyle(link, 'color', this.iconColor);
        });
      }
    });
  }
}
