import { Pipe, PipeTransform } from '@angular/core';
import { broadcastInterval } from './broadcastInterval';

@Pipe({ name: 'label', standalone: true })
export class LabelMakerPipe implements PipeTransform {
  /**
   * Converts duration adjective into the duration name eg. ```monthly``` to ```month```
   *
   * @param value The value to be converted into a time span
   * @returns The converted value
   */
  transform(value: string) {
    return broadcastInterval(value);
  }
}
