import { Injectable } from '@angular/core';

@Injectable()
export class MathsService {
  constructor() {}

  getAverage(arr: number[]): number {
    return Number((this.getTotal(arr) / arr.length).toFixed(3));
  }

  getMax(arr: number[]): number {
    return Math.max(...arr);
  }

  getMin(arr: number[]): number {
    return Math.min(...arr);
  }

  getModes(arr: number[]): number[] {
    if (!arr.length) {
      return [];
    }
    const modeMap = {};
    let maxCount = 0;
    let modes = [];
    arr.forEach(val => {
      Object.keys(modeMap).includes(`${val}`) ? (modeMap[`${val}`] += 1) : (modeMap[`${val}`] = 0);
      if (modeMap[val] > maxCount) {
        modes = [val];
        maxCount = modeMap[val];
      } else if (modeMap === maxCount) {
        modes.push(val);
        maxCount = modeMap[val];
      }
    });
    return modes;
  }

  getCount(arr: number[]): number {
    return arr.length;
  }

  getTotal(arr: number[]): number {
    return arr.reduce((sum, num) => sum + num, 0);
  }

  getRange(arr: number[]): number {
    return this.getMax(arr) - this.getMin(arr);
  }

  getMidRange(arr: number[]): number {
    return this.getRange(arr) / 2;
  }

  getMedian(arr: number[]): number {
    arr.sort((a, b) => a - b);
    const middle = arr.length / 2;
    return middle % 1 ? arr[middle - 0.5] : (arr[middle - 1] + arr[middle]) / 2;
  }

  getVariance(arr: number[]): number {
    const mean = this.getAverage(arr);
    return Number(this.getAverage(arr.map(num => Math.pow(num - mean, 2))).toFixed(3));
  }

  getStandardDeviation(arr: number[]): number {
    return Number(Math.sqrt(this.getVariance(arr)).toFixed(3));
  }

  getMeanAbsoluteDeviation(arr: number[]): number {
    const mean = this.getAverage(arr);
    return Number(this.getAverage(arr.map(num => Math.abs(num - mean))).toFixed(3));
  }

  getZScores(arr: number[]): number[] {
    const mean = this.getAverage(arr);
    const sd = this.getStandardDeviation(arr);
    return arr.map(num => Number(((num - mean) / sd).toFixed(3)));
  }

  getPopulationMean(arr: number[]): number {
    return Number((this.getTotal(arr) / (arr.length - 1)).toFixed(3));
  }

  getPopulationVariance(arr: number[]): number {
    const populationMean = this.getPopulationMean(arr);
    return Number(this.getPopulationMean(arr.map(num => Math.pow(num - populationMean, 2))).toFixed(3));
  }

  getPopulationStandardDeviation(arr: number[]): number {
    return Number(Math.sqrt(this.getPopulationVariance(arr)).toFixed(3));
  }
}
