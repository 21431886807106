import { AbstractControl } from '@angular/forms';

export const letter = (c: AbstractControl): { [key: string]: boolean } | null => {
  const value = c.value;
  const pattern = /[\w]+/;
  if (!pattern.test(value)) {
    return { letter: true };
  }
  return null;
};
