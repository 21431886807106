<nb-card>
  <nb-card-header class="d-flex">
    <h1 tabindex="0" class="page-heading" id="page-title">Make A Payment</h1>
    <nb-actions class="ms-auto">
      <nb-action
        (click)="goToManage()"
        id="back"
        nbTooltip="Go to My Account"
        nbTooltipIcon="arrow-back-outline"
        icon="arrow-back-outline"
      ></nb-action>
    </nb-actions>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="pricingForm">
      <nb-card class="mx-auto" id="subscription">
        <nb-card-header class="d-flex">Subscription</nb-card-header>
        <nb-card-body class="">
          <div class="form-group row mb-2">
            <label for="bundleName" class="col-sm-4 col-form-label">Pack</label>
            <div class="col-sm-8">
              <nb-select
                fullWidth
                class=""
                id="bundleName"
                formControlName="bundleName"
                aria-label="Subscription package name"
              >
                <nb-option value="basic" selected>Basic</nb-option>
                <nb-option value="standard">Standard</nb-option>
                <nb-option value="pro">Pro</nb-option>
                <nb-option value="enterprise">Enterprise</nb-option>
              </nb-select>
            </div>
          </div>
          <div class="form-group row mb-2">
            <label for="terms" class="col-sm-4 col-form-label">Term</label>
            <div class="col-sm-8">
              <nb-select
                fullWidth
                class=""
                id="terms"
                formControlName="terms"
                aria-label="Subscription duration in months"
              >
                <nb-option value="1" selected>1 Month</nb-option>
                <nb-option value="2">2 Months</nb-option>
                <nb-option value="3">3 Months</nb-option>
                <nb-option value="4">4 Months</nb-option>
                <nb-option value="5">5 Months</nb-option>
                <nb-option value="6">6 Months</nb-option>
                <nb-option value="7">7 Months</nb-option>
                <nb-option value="8">8 Months</nb-option>
                <nb-option value="9">9 Months</nb-option>
                <nb-option value="10">10 Months</nb-option>
                <nb-option value="11">11 Months</nb-option>
                <nb-option value="12">12 Months</nb-option>
              </nb-select>
            </div>
          </div>
          <div class="form-group row mb-2">
            <label for="usd" class="col-sm-4 col-form-label">USD</label>
            <div class="col-sm-8">
              <input
                nbInput
                fullWidth
                type="text"
                class=""
                id="usd"
                aria-label="US dollars"
                formControlName="usd"
                readonly
              />
            </div>
          </div>
          <div class="form-group row mb-2">
            <label for="zwd" class="col-sm-4 col-form-label">ZWD</label>
            <div class="col-sm-8">
              <input
                nbInput
                fullWidth
                type="text"
                class=""
                id="zwd"
                aria-label="Zimbabwean dollars"
                formControlName="zwd"
                readonly
              />
            </div>
          </div>
        </nb-card-body>
      </nb-card>
    </form>
    <div class="card placeholder hide" id="check-payment">
      <div class="arranger">
        <img
          src="../../../assets/images/brands/paynow-logo.png"
          class="mb-3"
          alt="Paynow logo"
          height="35"
        />
        <img
          src="../../../assets/images/brands/ecocash-logo.png"
          class="mb-3"
          *ngIf="isEcocash"
          alt="Ecocash logo"
          height="20"
        />
        <img
          src="../../../assets/images/brands/onemoney-logo.png"
          class="mb-3"
          *ngIf="isOnemoney"
          alt="One Money logo"
          height="30"
        />
        <p id="instructions">
          Follow instructions on your mobile and press 'Confirm Payment' button
          when done.
        </p>
      </div>
      <div class="form-group mt-auto mb-0 row buttons_wrapper arranger">
        <div class="col-sm-6 mt-2 mb-3 flex-item">
          <button
            type="submit"
            class="btn green"
            (click)="checkMobilePayment()"
          >
            Confirm Payment
          </button>
        </div>
        <div class="col-sm-6 mt-2 flex-item">
          <button type="button" class="btn red" (click)="abortPayment()">
            Cancel
          </button>
        </div>
      </div>
    </div>

    <div class="container row mx-auto mb-3 pt-4 form_wrapper">
      <div class="card blank mx-auto mb-3" *ngIf="!isOnline">
        <p>Sorry, you are currently offline.</p>
        <p>Payment options are only available when you are online.</p>
      </div>
      <div class="arranger" *ngIf="isOnline">
        <div
          class="pay-button-container mb-4 mx-2"
          id="paypal-button-container"
          #paypalBtnContainer
        ></div>
        <div
          class="pay-button-container mobile mb-4 mx-2"
          id="ecocash-button-container"
        >
          <button
            type="button"
            class="pay-button"
            id="ecocash"
            (click)="detectMobile('ecocash')"
            data-bs-toggle="modal"
            data-bs-target="#mobile-number"
          >
            <img
              src="../../../assets/images/brands/ecocash-logo.png"
              alt="Pay with Ecocash"
              height="35"
            />
          </button>
        </div>
        <div
          class="pay-button-container mobile mb-4 mx-2"
          id="onewallet-button-container"
        >
          <button
            type="button"
            class="pay-button"
            id="onemoney"
            (click)="detectMobile('onemoney')"
            data-bs-toggle="modal"
            data-bs-target="#mobile-number"
          >
            <img
              src="../../../assets/images/brands/onemoney-logo.png"
              alt="Pay with One Money"
              height="70"
            />
          </button>
        </div>
      </div>
    </div>
  </nb-card-body>
  <app-modal-popup
    (formData)="payMobile($event)"
    inputType="tel"
    placeholder="07xxxxxxxx"
    buttonText="Submit"
    label="Mobile Number"
    title="Enter your Mobile Number"
    identifier="mobile-number"
  >
  </app-modal-popup>
</nb-card>
