import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconRegistryService {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    const options = { viewBox: '-60 -60 625 625' };

    // Social
    iconRegistry.addSvgIcon(
      'messenger',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/fa/facebook-messenger.svg'
      ),
      options
    );
    iconRegistry.addSvgIcon(
      'facebook',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/fa/facebook.svg'),
      options
    );
    iconRegistry.addSvgIcon(
      'twitter',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/fa/twitter.svg'),
      options
    );
    iconRegistry.addSvgIcon(
      'instagram',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/fa/instagram.svg'
      ),
      options
    );

    // Other
    iconRegistry.addSvgIcon(
      'email',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/fa/envelope.svg'),
      options
    );
    iconRegistry.addSvgIcon(
      'phone',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/fa/phone-square-alt.svg'
      ),
      options
    );
    iconRegistry.addSvgIcon(
      'graduation-cap',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/fa/graduation-cap.svg'
      ),
      options
    );
    iconRegistry.addSvgIcon(
      'book-reader',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/fa/book-reader.svg'
      ),
      options
    );
    iconRegistry.addSvgIcon(
      'eye',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/fa/eye.svg'),
      options
    );
    iconRegistry.addSvgIcon(
      'check-double',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/fa/check-double.svg'
      ),
      options
    );
    iconRegistry.addSvgIcon(
      'medal',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/fa/medal.svg'),
      options
    );
    iconRegistry.addSvgIcon(
      'clock',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/fa/clock.svg'),
      options
    );
    iconRegistry.addSvgIcon(
      'newspaper',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/fa/newspaper.svg'
      ),
      options
    );
    iconRegistry.addSvgIcon(
      'chair',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/fa/chair.svg'),
      options
    );

    // Material Icons
    iconRegistry.addSvgIcon(
      'contacts',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/material/contacts.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'appointment',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/material/appointment.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'place',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/material/place.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'menu-book',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/material/menu-book.svg'
      )
    );
  }
}
