import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

import { filter, take, takeUntil, tap } from 'rxjs/operators';

import { AuthService } from './core/services/auth.service';
import { NotificationService } from './core/services/notification.service';
import { SpinnerService } from './core/services/spinner.service';
import { Constants } from './constants';
import { PushNotificationService } from './core/services/push-notification.service';
import { SwMasterService } from './core/services/sw-master.service';
import { IndexDbService } from './core/services/index-db.service';
import { AutoUnsubscribe } from './helpers/observable-auto-unsubscribe';
import { NoBgSyncService } from './core/services/no-sync.service';
import { StoreService } from './core/services/store.service';
import { errorMessage } from './helpers/error-message';
import { ErrorLoggingService } from './core/services/logging.service';
import { SyncErrorContext } from './global-models/sync-error-context.enum';
import { IParamValueIndexedDB } from './tracking/interfaces-enums/parameter-value';
import { BackdropManagerService } from './core/services/backdrop-manager.service';
import { DataService } from './core/services/data.service';
import { BundlesService } from './core/services/bundles.service';
import { PayService } from './core/services/pay.service';
import { ConnectionService } from './core/services/connection.service';
import { WebRtcService } from './core/services/web-rtc.service';
import { TrackablesFilterService } from './tracking/services/trackable-filter.service';
import { PrintService } from './@core/utils/services/print.service';
import { ProfileService } from './@core/utils/services/profile.service';
import { MenuItemsService } from './@core/utils/services/menuItemsService';
import { NbIconLibraries, NbThemeService } from '@nebular/theme';
import { DeviceService } from './core/services/device-status.service';
import { Subject, firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
@AutoUnsubscribe()
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('vidPreview') videoPreview!: ElementRef<HTMLButtonElement>;

  isCamera = false;
  isOnline: boolean;
  // isLoggedIn = false;
  isSyncing = false;
  installPrompt: any;
  isPushable: boolean;
  subscriptionButtonTitle: string;

  updateAvailable: boolean;
  pendingSync: boolean;
  srPendingBroadcastsSummary = '';
  pendingBroadcasts: IParamValueIndexedDB[] = [];
  pendingPushMessages = [];
  actions = {};

  // get isCamera(): boolean {
  //   return this.rtc.isCamera;
  // }

  get isLoggedIn() {
    return this.authService ? this.authService.isLoggedIn : false;
  }

  set isLoggedIn(status: boolean) {
    this.isLoggedIn = status;
  }

  get filteringRequired(): boolean {
    return (
      this.store.currentUrl?.includes('/subscribed/tracking/trackables') &&
      !(
        this.store.currentUrl?.includes('/edit') ||
        this.store.currentUrl?.includes('/bulk-kpi-update')
      )
    );
  }

  get onCommand(): boolean {
    return this.store.currentUrl?.includes('/command-center');
  }

  get onPayment(): boolean {
    return this.store.currentUrl?.includes('/make-payment');
  }

  get user() {
    return this._user;
  }

  get activeRoute() {
    return this._activeRoute;
  }

  get onHome() {
    return (
      this.activeRoute?.startsWith('/home') ||
      this.activeRoute?.startsWith('/auth')
    );
  }

  get hideLogo() {
    return (
      this.viewportSize === 'xs' && this.filteringRequired && this.pendingSync
    );
  }

  readonly VAPID_PUBLIC_KEY = Constants.vapidKeys.publicKey;
  public isSubscribed: boolean;
  private _activeRoute!: string;
  private _user: any;
  private pushSubscription: PushSubscription;
  private loginTimer: number;
  private wasOnline: boolean;
  private themeKey!: string;
  private viewportSize!: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  private destroy$: Subject<void> = new Subject<void>();
  private loggedOffSafePages = ['/home', '/faq', '/signup'];

  constructor(
    private authService: AuthService,
    private router: Router,
    private spinner: SpinnerService,
    private notifier: NotificationService,
    private pushService: PushNotificationService,
    private swMaster: SwMasterService,
    private indexedDb: IndexDbService,
    private noSync: NoBgSyncService,
    private store: StoreService,
    private errorLogger: ErrorLoggingService,
    private backdrop: BackdropManagerService,
    private dataService: DataService,
    private bundlesService: BundlesService,
    private payService: PayService,
    private connect: ConnectionService,
    private rtc: WebRtcService,
    private filterService: TrackablesFilterService,
    private menuItemsService: MenuItemsService,
    private deviceService: DeviceService,
    private themeService: NbThemeService,
    private iconLibraries: NbIconLibraries,
    public profileService: ProfileService,
    public printService: PrintService
  ) {
    // NB. Do not remove this theme setting code
    this.themeKey = `${this.profileService.institutionAbreviation}:theme`;
    this.setTheme();

    this.iconLibraries.registerFontPack('font-awesome', {
      iconClassPrefix: 'fa',
    });

    this.store.isOnline$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (status) => (this.isOnline = status),
      error: (error) => console.error(errorMessage(error)),
    });

    this.store.user$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (user) => {
        user ? this.manageRoutes() : null;
        this._user = user;
      },
      error: (error) => console.error(errorMessage(error)),
    });
  }

  menu = this.menuItemsService.getMenuItems();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    if (window.location.href.includes('?postLogin=true')) {
      this.postLoginHandler();
    }
    this.isPushable = this.swMaster.swPushSupported;
    this.setUpEventListeners();
    this.clearResidualTokens();
    this.initialisePushNotificationsReactions();
    this.loginTimer = window.setInterval(() => {
      this.checkLoginStatus();
    }, 500);
    this.askForPermanentStorage();
    this.checkSyncStatus();
    this.monitorRoutes();
  }

  async ngAfterViewInit(): Promise<void> {
    window.setTimeout(() => {
      try {
        this.deviceService.viewportSize$
          .pipe(takeUntil(this.destroy$))
          .subscribe((size) => (this.viewportSize = size));

        this.setSubBtnTitle();
        this.collapseNavbar();
        navigator.onLine
          ? dispatchEvent(new Event('online'))
          : dispatchEvent(new Event('offline'));
        this.listenForCamera();
      } catch (error) {
        console.error(error);
        this.notifier.showError(errorMessage(error));
      }
    });
  }

  manageFilter() {
    this.filterService.filterClicked$.next(true);
    // This is necessary to prevent an initial filterClicked true event on every filter capable page that is being opened
    this.filterService.filterClicked$.next(false);
  }

  listenForCamera() {
    this.rtc.isCamera$.subscribe({
      next: (isOn) => {
        this.isCamera = isOn;
        if (isOn) {
          this.videoPreview.nativeElement.click();
        }
      },
      error: (error) => {
        this.notifier.showError(errorMessage(error));
      },
    });
  }

  deleteRoom() {
    try {
      this.rtc.deleteRoom();
    } catch (error) {
      this.notifier.showError(errorMessage(error));
    }
  }

  logout() {
    this.spinner.show(true);
    this.authService.logout(!this.isOnline).then(
      () => {
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.notifier.showError('Logout Failure.');
      }
    );
  }

  login() {
    this.spinner.show();
    this.authService.login().then(
      () => {
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.errorLogger.logError(errorMessage(error));
        this.notifier.showError(
          'Login Failure. Check your internet connection.'
        );
      }
    );
  }

  async deleteParam(index: number) {
    try {
      this.actions[`deleting${index}`] = true;
      await this.indexedDb.deleteRecords('parameterValues', [
        this.pendingBroadcasts[index].id,
      ]);

      // TODO: Find a better way to identify the correct pushMessage to delete.
      // The current implementation doesn't delete the message if broadcasts and push messages are different
      // because can't tell the exact message to delete
      this.pendingBroadcasts.length === this.pendingPushMessages.length
        ? await this.indexedDb.deleteRecords('pushMessages', [
            this.pendingPushMessages[index].id,
          ])
        : null;

      // Work-around to improve UX. Deleting the parameter and removing it from UI takes some time
      // since it is asynchronous
      // This timeout gives the chance for the dbStorage event to be fired while still showing the user that
      // we are still processing the request. Without this timeout, there will be a noticeble delay
      // between spinner stopping and the entry being removed from the UI and this confuses the user.
      setTimeout(() => {
        this.actions = {};
      }, 1500);
    } catch (error) {
      this.actions = {};
      this.notifier.showError(errorMessage(error));
    }
  }

  // installApp() {
  //   if (this.installPrompt !== undefined) {
  //     this.installPrompt.prompt();
  //     this.installPrompt.userChoice.then(
  //       (choice: { outcome: string }) => {
  //         if (choice.outcome !== 'dismissed') {
  //           // TODO: Show the power-off button on the nav menu
  //         } else {
  //           console.log('App dismissed');
  //         }
  //         this.installPrompt = undefined;
  //       },
  //       (error) =>
  //         this.notifier.showError(
  //           'Failed to install the app. Try to refresh the page and try again.'
  //         )
  //     );
  //   }
  // }

  installApp(confirmed: boolean) {
    if (this.installPrompt !== undefined) {
      if (confirmed) {
        this.installPrompt.prompt();
        this.installPrompt.userChoice.then(
          (choice: { outcome: string }) => {
            if (choice.outcome !== 'dismissed') {
              // TODO: Show the power-off button on the nav menu
            } else {
              console.log('App dismissed');
            }
            this.installPrompt = undefined;
          },
          () =>
            this.notifier.showError(
              'Failed to install the app. Try to refresh the page and try again.'
            )
        );
      } else {
        this.installPrompt = undefined;
      }
    }
  }

  installUpdate(confirmed: boolean) {
    if (confirmed) {
      this.spinner.show(true);
      this.swMaster.installUpdate();
    }
  }

  async subscription(): Promise<void> {
    const pushSubscribed = this.isSubscribedToPushNotifications();
    if (pushSubscribed) {
      await this.unSubscribeFromPushNotifications();
    } else {
      await this.subscribeToPushNotifications();
    }
    this.setSubBtnTitle();
  }

  goToCommandCenter() {
    this.spinner.show();
    this.router.navigateByUrl('/subscribed/tracking/trackables/command-center');
  }

  goToFbMessenger() {
    this.store.externalUrl = Constants.fbMessenger;
  }

  goToFacebook() {
    this.store.externalUrl = Constants.fbPage;
  }

  goToTwitter() {
    this.store.externalUrl = Constants.twitter;
  }

  goToWebsite() {
    this.store.externalUrl = Constants.website;
  }

  async syncIndexedDb(instantSync = false) {
    if (!this.authService.isLoggedIn || !this.isOnline) return;

    const broadcastsSync = async () => {
      this.isSyncing = true;

      try {
        await this.syncBroadcasts();
        this.isSyncing = false;
      } catch (error) {
        this.isSyncing = false;
        throw Error(errorMessage(error));
      }
    };

    try {
      if (!(await this.swMaster.bgSyncSupported())) {
        // No need to wait. Fallback immediately
        await broadcastsSync();
      } else {
        if (instantSync) {
          // Sync is required immediately
          await broadcastsSync();
        } else {
          // Give a chance to browser bgSync
          setTimeout(async () => {
            // If bgSync fails or is partially completed for any reason, fallback
            this.pendingSync ? await broadcastsSync() : null;
          }, 20000);
        }
      }
    } catch (error) {
      console.error(error);
      this.notifier.showError(errorMessage(error));
    }
  }

  hookLocalStream() {
    this.rtc.localStreamClone = this.rtc.localStream.clone();
    this.rtc.localStreamClone
      .getAudioTracks()
      .forEach((aud) => (aud.enabled = false));
    (document.querySelector('#local-video') as HTMLVideoElement).srcObject =
      this.rtc.localStreamClone;
  }

  private preloadEssentialUserData() {
    firstValueFrom(this.dataService.getAccountDetails()).catch((error) => {});

    firstValueFrom(this.bundlesService.getActiveBundle()).catch((error) => {});

    firstValueFrom(this.bundlesService.getBundles()).catch((error) => {});

    firstValueFrom(this.payService.getExchangeRate()).catch((error) => {});
  }

  private async askForPermanentStorage() {
    let allowed = false;
    let msg = 'Persistent storage inactive';
    if (navigator.storage && navigator.storage.persist) {
      allowed = await navigator.storage.persisted();
      if (!allowed) {
        allowed = await navigator.storage.persist();
        msg = allowed ? 'Persistent storage active' : msg;
      } else {
        return;
      }
      console.info(msg);
    }
  }

  private setSubBtnTitle() {
    const pushSubscribed = this.isSubscribedToPushNotifications();
    if (pushSubscribed) {
      this.subscriptionButtonTitle = 'Unsubscribe';
    } else {
      this.subscriptionButtonTitle = 'Subscribe';
    }
  }

  private async isSubscribedToPushNotifications() {
    const sub = await firstValueFrom(this.swMaster.getPushSubscription(), {
      defaultValue: undefined,
    });
    if (sub) {
      this.isSubscribed = true;
      this.pushSubscription = sub;
    } else {
      this.isSubscribed = false;
      this.pushSubscription = undefined;
    }

    return this.isSubscribed;
  }

  private async subscribeToPushNotifications() {
    let sub: any;
    if (this.isPushable) {
      this.spinner.show();
      try {
        sub = await this.swMaster.requestSubscription({
          serverPublicKey: this.VAPID_PUBLIC_KEY,
        });
        this.pushService.addPushSubscriber(sub).subscribe({
          next: (payload) => {
            this.spinner.hide();
            this.setSubBtnTitle();
            this.notifier.showSuccess('You have been subscribed');
          },
          error: (error) => {
            this.spinner.hide();
            if (sub) {
              this.swMaster.unsubscribe();
            }
            this.setSubBtnTitle();
            this.notifier.showError(errorMessage(error));
          },
        });
      } catch (error) {
        this.spinner.hide();
        if (sub) {
          await this.swMaster.unsubscribe();
        }
        this.setSubBtnTitle();
        this.notifier.showError(
          `Live Broadcasts subscribing failed due to:- ${errorMessage(error)}`
        );
      }
    }
  }

  private async unSubscribeFromPushNotifications() {
    if (this.isPushable) {
      this.spinner.show();
      this.pushService
        .removePushSubscriber({ subscription: this.pushSubscription })
        .subscribe({
          next: async (payload) => {
            try {
              await this.swMaster.unsubscribe();
              this.setSubBtnTitle();
              this.spinner.hide();
              this.notifier.showSuccess('You have been unsubscribed');
            } catch (error) {
              this.spinner.hide();
              this.setSubBtnTitle();
              this.notifier.showError(errorMessage(error));
            }
          },
          error: (error) => {
            this.spinner.hide();
            this.setSubBtnTitle();
            this.notifier.showError(`Unsubcribe failure because of: ${error}.`);
          },
        });
    }
  }

  private postLoginHandler() {
    this.authService.userManager$.subscribe(async (userManager) => {
      this.spinner.show();
      userManager
        ? (await this.authService.postLogin(), this.setSubBtnTitle())
        : null;
      this.spinner.hide();
    });
  }

  private checkLoginStatus() {
    if (this.isLoggedIn) {
      window.clearInterval(this.loginTimer);
      this.loginTimer = undefined;
      this.setSubBtnTitle();
      this.presetToken();
      this.rtc.performHandShake();
      this.dataService
        .setAccountDetails()
        .catch((error) => this.notifier.showError(errorMessage(error)))
        .finally(() => this.store.appExpired);

      this.checkSyncStatus()
        .catch((error) => console.error(error))
        .finally(() => {
          this.syncIndexedDb().catch((error) => console.error(error));
        });

      setTimeout(() => {
        // This minimises the chances of race conditions with pages that maybe loading the same data via resolvers
        this.preloadEssentialUserData();
      }, 2000);
    }
  }

  private async presetToken() {
    try {
      await this.indexedDb.clearData('tokens');
      await this.indexedDb.addData(
        { token: this.authService.getToken() },
        'tokens'
      );
    } catch (error) {
      console.error(error);
    }
  }

  private manageRoutes() {
    this.store.user$.subscribe((user) => {
      if (user) {
        this.trackRoutes();
        this.restoreState();
      }
    });
  }

  private async restoreState() {
    // Don't try to restore state when the requested route is not on home or root
    // A user might be trying to load a specific route e.g. when reloading a page
    // Absence of this line was causing unpredictable behaviour when reloading page within the tracking module
    if (!(this.router.url === '/' || this.router.url === '/home')) return;

    const savedState = this.store.currentUrl;
    const url =
      savedState && savedState !== '/home'
        ? savedState
        : '/subscribed/tracking/trackables';
    await this.router.navigateByUrl(url);

    this.spinner.hide();
  }

  private trackRoutes() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe({
        next: (event: any) => {
          this.backdrop.hide(); // Work-around to hide bootstrap backdrop when browser back/forward button is clicked while the backdrop is showing
          const savedState = this.store.currentUrl;
          this.store.currentUrl = event.url;
          this.store.previousUrl = savedState;

          // This forces the manage account page to fetch fresh data when navigated to i.e. should refresh the rate-resolver
          if (event.url.endsWith('manage-account')) {
            this.store.activeBundleRefreshNeeded = true;
          }

          // this.tweakUI();
          this.allowBodyScroll();
        },
      });
  }

  private allowBodyScroll() {
    document.body.style.overflowY = 'unset';
  }

  private initialisePushNotificationsReactions() {
    if (this.isPushable) {
      this.swMaster.pushMessages().subscribe((payload) => {
        // console.log(payload);
        this.store.trackablesRefreshNeeded = true;
      });

      this.swMaster
        .notificationClicks()
        .subscribe(({ action, notification }) => {
          const detailsPath = notification.data.detailsPath;
          // This only runs when the Angular app is running. When the app is not running the navigation
          // is handled in sw-custom.js
          action === 'details' ? this.router.navigate([detailsPath]) : null;
        });
    }
  }

  private setUpEventListeners() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.installPrompt = e;
      console.log('App ready to be installed');
    });

    window.addEventListener('appinstalled', (e) => {
      console.log('App installed');
    });

    window.addEventListener('online', async (e) => {
      await this.checkSyncStatus();
      if (this.isOnline) {
        if (!this.wasOnline) {
          // this.notifier.showInfo('You are online');
          setTimeout(() => this.rtc.performHandShake(), 5000);
          this.rtc
            .updateRooms()
            .catch((error) => this.notifier.showError(errorMessage(error)));
          if (this.pendingSync) {
            await this.syncIndexedDb();
          }
        }

        this.wasOnline = true;

        // !this.isLoggedIn && !this.loggedOffSafePages.includes(this.router.url)
        //   ? this.router.navigateByUrl('/home')
        //   : null;
      }
      this.scanConnection();
    });

    window.addEventListener('offline', (e) => {
      // this.wasOnline ? this.notifier.showInfo('You are Offline') : null;

      this.wasOnline = false;
      this.store.isOnline = false;
      this.checkSyncStatus();

      this.scanConnection();
    });

    // dbStorage is a custom IndexedDB event that signals that the one or more of the its tables have been modified
    window.addEventListener('dbStorage', (e) => {
      this.checkSyncStatus();
    });

    if (this.swMaster.swSupported()) {
      this.swMaster.updates.versionUpdates
        .pipe(filter((event) => event.type === 'VERSION_READY'))
        .subscribe((event) => {
          this.updateAvailable = true;
          this.notifier.showSuccess(
            `New App version available. Click 'Install Updates' to update.`
          );
        });

      this.swMaster.updates
        .activateUpdate()
        .then((status) => {
          this.updateAvailable = false;
        })
        .catch((error) => console.error(error));

      this.swMaster.updates.unrecoverable.subscribe((event) => {
        this.notifier.showError(
          `An error occured we cannot recover from. Please reload the page. Reason: ${event.reason}`
        );
      });
    }

    this.swMaster
      .bgSyncSupported()
      .then((supported) => {
        if (supported) {
          window.navigator.serviceWorker.addEventListener('message', (e) => {
            const msg: string = e.data.message
              ? e.data.message.toLowerCase()
              : '';
            if (msg.includes('synced')) {
              msg === 'broadcastssynced'
                ? this.notifier.showSuccess('Broadcasting successful')
                : null;
              this.checkSyncStatus();
            } else if (msg === 'error') {
              let message = '';
              const context = e.data.context && e.data.context.toLowerCase();
              const error = e.data.error;
              switch (context) {
                case 'paramssync':
                  this.noSync.handleSyncError(
                    SyncErrorContext.paramsSync,
                    error
                  );
                  break;
                case 'messagessync':
                  this.noSync.handleSyncError(
                    SyncErrorContext.messagesSync,
                    error
                  );
                  break;
                default:
                  this.notifier.showError(error);
                  this.errorLogger.logError(error);
                  break;
              }
            }
          });
        }
      })
      .catch((error) => {
        this.errorLogger.logError(errorMessage(error));
        this.notifier.showError(errorMessage(error));
      });
  }

  private async scanConnection() {
    const checkForWifi = () => {
      window.setTimeout(() => {
        dispatchEvent(new Event('offline'));
      }, 5000);
    };

    try {
      await this.connect.pingBackend();
      if (navigator.onLine) {
        this.isOnline
          ? window.setTimeout(
              () => {
                // App online. Periodically search for Lie-fi
                // N.B. Lie-fi search is initiated in the 'online' eventListener handler, just fire the 'online' event
                dispatchEvent(new Event('online'));
              },
              this.wasOnline ? 20000 : 5000
            )
          : checkForWifi(); // Server unreachable. Continue to check for it's availability
      } else {
        // App is definately offline. Passively wait for an online event to be fired by navigator
      }
    } catch (error) {
      console.error(error);
    }
  }

  private async checkSyncStatus() {
    const hasBroadcasts = await this.indexedDb.hasData('parameterValues');
    const hasPushMessages = await this.indexedDb.hasData('pushMessages');
    if (hasBroadcasts) {
      this.pendingBroadcasts = await this.indexedDb.getAll('parameterValues');
    } else {
      this.pendingBroadcasts = [];
    }

    if (hasPushMessages) {
      this.pendingPushMessages = await this.indexedDb.getAll('pushMessages');
    } else {
      this.pendingPushMessages = [];
    }

    const syncables = [hasBroadcasts];
    this.pendingSync = syncables.some((status) => status);
  }

  private async syncBroadcasts() {
    await this.noSync.updateKpi();
    await this.checkSyncStatus();
  }

  private clearResidualTokens() {
    if (window.location.href.indexOf('?postLogout=true') > 0) {
      this.authService.signoutRedirectCallback().then(() => {
        const url: string = this.router.url.substring(
          0,
          this.router.url.indexOf('?')
        );
        this.router.navigateByUrl(url);
      });
    }
  }

  private collapseNavbar() {
    addEventListener('click', (e) => {
      const collapsible = document.querySelector('#collapsible');
      const isExtended = collapsible
        ? collapsible.classList.contains('show')
        : false;
      const toggler = document.querySelector('#hamburger');
      if (isExtended) {
        collapsible.classList.remove('show');
        toggler.setAttribute('aria-expanded', 'false');
        toggler.classList.add('collapsed');
      }
    });
  }

  private monitorRoutes() {
    this.router.events
      .pipe(
        tap((event) => {
          event instanceof NavigationStart ? this.spinner.show() : null;
        }),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this._activeRoute = this.router.url;
        this.spinner.hide();
      });
  }

  private setTheme() {
    const storedTheme = localStorage.getItem(this.themeKey);
    storedTheme ? this.themeService.changeTheme(storedTheme) : null;
  }
}
