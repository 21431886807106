import { Injectable, NgZone } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(public snackBar: MatSnackBar, private zone: NgZone) {}

  /**
   * Displays success message
   *
   * @param message Success message to be displayed
   */
  showSuccess(message: string): void {
    // Had an issue with the snackbar being ran outside of angular's zone.
    this.zone.run(() => {
      this.snackBar.open(message, '', {
        duration: 5000,
        panelClass: ['error', 'green-flat'],
        politeness: 'assertive',
      });
    });
  }

  /**
   * Displays an error message
   *
   * @param message Error message to be displayed
   */
  showError(message: string): void {
    this.zone.run(() => {
      // The second parameter is the text in the button.
      // In the third, we send in the css class for the snack bar.
      this.snackBar.open(this.errorDecipher(message), '', {
        panelClass: ['error', 'red-flat'],
        politeness: 'assertive',
      });
    });
  }

  private errorDecipher(message: string): string {
    return message ? message : 'Something went wrong';
  }
}
