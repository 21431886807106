<!--

<footer id="app-footer">
  <div class="container-fluid m-0 p-0 row">
    <div class="col-6 me-0 pe-0">
      <nb-icon class="icon" id="envelope" icon="email-outline"></nb-icon>
      <span id="contact" class="ps-1">info@nirgel.com</span>
    </div>
    <div class="brands col-6">
      <a
        class="external-link ms-auto"
        [routerLink]="['/external-page']"
        (click)="goToFbMessenger()"
        ><img
          src="./assets/images/brands/fb-m-logo.svg"
          alt="Send us a message through Facebook Messenger"
          class="icon"
      /></a>
      <a
        class="external-link ms-3 ms-sm-4"
        [routerLink]="['/external-page']"
        (click)="goToFacebook()"
        ><img
          src="./assets/images/brands/fb-logo.svg"
          alt="Go to our Facebook page"
          class="icon"
      /></a>
      <a
        class="external-link ms-3 ms-sm-4"
        [routerLink]="['/external-page']"
        (click)="goToTwitter()"
        ><img
          src="./assets/images/brands/tw-logo.svg"
          alt="Go to our Twitter"
          class="icon"
      /></a>
      <a
        class="external-link ms-3 ms-sm-4 me-3"
        [routerLink]="['/external-page']"
        (click)="goToWebsite()"
      >
        <img
          src="/assets/icons/fa/globe.svg"
          alt="Visit to our website"
          class="icon"
        />
      </a>
    </div>
  </div>
</footer> -->

<div [class.isPrinting]="printService.isPrinting">
  <ngx-one-column-layout *ngIf="!onHome; else home" [hideLogo]="hideLogo">
    <nb-menu tag="mainNavMenu" [items]="menu" autoCollapse="true"></nb-menu>
    <nb-actions>
      <nb-action
        *ngIf="isOnline && isPushable && isLoggedIn && isSubscribed"
        [icon]="'bell-off'"
        [nbTooltipIcon]="'bell-off'"
        [nbTooltip]="subscriptionButtonTitle"
        (click)="subscription()"
      ></nb-action>
      <nb-action
        *ngIf="isOnline && isPushable && isLoggedIn && !isSubscribed"
        [icon]="'bell'"
        [nbTooltipIcon]="'bell'"
        [nbTooltip]="subscriptionButtonTitle"
        (click)="subscription()"
      ></nb-action>
      <nb-action
        *ngIf="filteringRequired"
        icon="funnel"
        nbTooltipIcon="funnel"
        nbTooltip="Filter"
        (click)="manageFilter()"
      ></nb-action>
      <nb-action
        *ngIf="pendingSync && !isOnline"
        icon="wifi-off"
        nbTooltipIcon="wifi-off"
        nbTooltip="You have unsynced data. Will sync it the next time you go online. Click to view"
        data-bs-toggle="modal"
        data-bs-target="#pendingsModal"
      ></nb-action>
      <nb-action
        id="sync-yes"
        *ngIf="isOnline && isLoggedIn && pendingSync && !isSyncing"
        (click)="syncIndexedDb(true)"
        icon="sync"
        nbTooltipIcon="sync"
        nbTooltip="Sync data"
      ></nb-action>
    </nb-actions>
    <router-outlet></router-outlet>
  </ngx-one-column-layout>

  <router-outlet name="print"></router-outlet>
  <router-outlet name="popup"></router-outlet>
  <ng-template #home>
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header-basic></ngx-header-basic>
      </nb-layout-header>

      <nb-layout-column>
        <router-outlet></router-outlet>
      </nb-layout-column>

      <nb-layout-footer class="pt-4" fixed>
        <smx-footer [footerData]="profileService"></smx-footer>
      </nb-layout-footer>
    </nb-layout>
  </ng-template>

  <smx-nb-confirm-dialog
    *ngIf="installPrompt"
    [body]="'You can install this app for a good user experience'"
    (confirmed)="installApp($event)"
    [closeOnBackdropClick]="false"
    submitBtnLabel="Install App"
    cancelBtnLabel="Not Now"
    header="App Installation"
  ></smx-nb-confirm-dialog>

  <smx-nb-confirm-dialog
    *ngIf="updateAvailable"
    body="A new version of this app is available. Would you like to install the update now? Please note that the app will reload to complete the update"
    (confirmed)="installUpdate($event)"
    [closeOnBackdropClick]="false"
    submitBtnLabel="Update App"
    cancelBtnLabel="Not Now"
    header="App Update"
  ></smx-nb-confirm-dialog>
</div>

<!-- Unsynced Data Modal -->
<div
  class="modal fade"
  id="pendingsModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="unsyncedLabel"
  aria-describedby="table-summary"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <nb-card class="modal-content">
      <nb-card-header class="d-flex">
        <h2 class="modal-title d-inline-block" id="unsyncedLabel">
          <b>PENDING BROADCASTS</b>
        </h2>
        <nb-actions class="ms-auto">
          <nb-action
            data-bs-dismiss="modal"
            aria-label="Unsynced data window"
            icon="close-outline"
            nbTooltipIcon="close-outline"
            nbTooltip="Close"
          ></nb-action>
        </nb-actions>
      </nb-card-header>
      <nb-card-body>
        <div class="table-responsive" *ngIf="pendingBroadcasts.length">
          <table
            tabindex="0"
            class="table table-sm table-bordered table-striped"
            aria-describedby="table-summary"
          >
            <caption>
              Pending Broadcasts
            </caption>
            <thead>
              <tr>
                <th
                  scope="col"
                  class="left-align"
                  aria-label="Date and time created"
                >
                  Created
                </th>
                <th scope="col" class="center-align" aria-label="Tracked item">
                  Item
                </th>
                <th
                  scope="col"
                  class="center-align"
                  aria-label="Monitored parameter name"
                >
                  Parameter
                </th>
                <th scope="col" class="center-align" aria-label="Time slot">
                  Slot
                </th>
                <th
                  scope="col"
                  class="center-align"
                  aria-label="Reported value"
                >
                  Value
                </th>
                <th scope="col" class="center-align" aria-label="Actions">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let pending of pendingBroadcasts; let i = index">
                <td scope="row" tabindex="0" class="left-align">
                  {{ pending.createdAt | fromUnix : 'l' }}
                </td>
                <td tabindex="0" class="center-align">
                  {{ pending.trackerName | titlecase }}
                </td>
                <td tabindex="0" class="center-align">
                  {{ pending.paramName | titlecase }}
                </td>
                <td tabindex="0" class="center-align">
                  {{ pending.reportSlot }}
                </td>
                <td tabindex="0" class="center-align">
                  {{ pending.paramValue }}
                </td>
                <td tabindex="0" class="center-align">
                  <button
                    type="button"
                    title="{{ 'Delete ' + pending.paramName + ' entry' }}"
                    class="m-0 p-0 action delete"
                    (click)="deleteParam(i)"
                    id="{{ 'deleteParam' + i }}"
                  >
                    <div class="m-auto" *ngIf="actions['deleting' + i]">
                      <span
                        class="spinner-border spinner-border-sm text-success"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden">Deleting...</span>
                    </div>
                    <nb-icon
                      icon="trash-outline"
                      class="px-1 m-auto action"
                      *ngIf="!actions['deleting' + i]"
                    >
                    </nb-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <span class="visually-hidden" id="table-summary">{{
            srPendingBroadcastsSummary
          }}</span>
        </div>
        <div class="card blank" *ngIf="!pendingBroadcasts.length">
          Cheer up! You are clean
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</div>
