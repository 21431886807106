import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { ErrorLoggingService } from './logging.service';
import { ErrorService } from './error.service';
import { NotificationService } from './notification.service';
import { CustomError } from '../../global-models/custom-error';
import { AuthService } from './auth.service';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse | CustomError) {
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(ErrorLoggingService);
    const notifier = this.injector.get(NotificationService);
    const auth = this.injector.get(AuthService);

    let message: string;
    let stackTrace: string;

    if (error instanceof HttpErrorResponse) {
      // Server Error

      message = errorService.getServerMessage(error);
      stackTrace = errorService.getServerStack(error);

      if (error.status === 401) {
        notifier.showError(message);
        return auth.login();
      } else if (error.status === 403) {
        return notifier.showError(message);
      }

      notifier.showError(message);
    } else if (error instanceof CustomError) {
      // Custom Error
      message = errorService.getCustomMessage(error);
      stackTrace = errorService.getCustomStack(error);
      notifier.showError(message);
    } else {
      // Client Error
      message = errorService.getClientMessage(error);
      stackTrace = errorService.getClientStack(error);
      notifier.showError(message);
    }

    // Always log errors
    logger.logError(message, stackTrace);

    console.error(error);
  }
}
