import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';

import { AuthService } from './services/auth.service';
import { BackdropManagerService } from './services/backdrop-manager.service';
import { DataService } from './services/data.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { ErrorService } from './services/error.service';
import { GlobalHttpInterceptor } from './services/global-http-interceptor.service';
import { HomeComponent } from './home/home.component';
import { ErrorLoggingService } from './services/logging.service';
import { NotificationService } from './services/notification.service';
import { SharedModule } from '../shared/shared.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PushNotificationService } from './services/push-notification.service';
import { SignupComponent } from './signup/signup.component';
import { SpinnerService } from './services/spinner.service';
import { ValidationMessagesService } from './services/validation-messages.service';
import { LoginComponent } from './login/login.component';
import { TeamService } from './services/team.service';
import { AuthGuardService } from './services/auth-guard.service';
import { DateService } from './services/date.service';
import { MathsService } from './services/maths.service';
import { SwMasterService } from './services/sw-master.service';
import { IndexDbService } from './services/index-db.service';
import { PayService } from './services/pay.service';
import { StoreService } from './services/store.service';
import { MailingService } from './services/mailing.service';
import { FaqComponent } from './faq/faq.component';
import { ConnectionService } from './services/connection.service';
import { NoBgSyncService } from './services/no-sync.service';
import { EventGeneratorService } from './services/event-generator.service';
import { ExternalPagesDummyComponent } from './external-pages-dummy/external-pages-dummy.component';
import { ExternalPagesGuard } from './external-pages-dummy/external-pages.guard';
import { BroadcastsService } from './services/broadcasts.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorIntl } from './services/paginator-intl.service';

@NgModule({
  declarations: [
    SignupComponent,
    LoginComponent,
    HomeComponent,
    PageNotFoundComponent,
    FaqComponent,
    ExternalPagesDummyComponent,
  ],
  imports: [SharedModule],

  providers: [
    BackdropManagerService,
    TeamService,
    ErrorService,
    ValidationMessagesService,
    DataService,
    NotificationService,
    ErrorLoggingService,
    AuthService,
    AuthGuardService,
    SpinnerService,
    PushNotificationService,
    DateService,
    MathsService,
    SwMasterService,
    IndexDbService,
    PayService,
    StoreService,
    MailingService,
    ConnectionService,
    NoBgSyncService,
    EventGeneratorService,
    ExternalPagesGuard,
    BroadcastsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptor,
      multi: true,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 10000 },
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: MatPaginatorIntl, useClass: PaginatorIntl },
  ],

  exports: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
