import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { ContactSupportComponent } from './contact-support.component';

@Injectable()
export class ContactSupportGuard  {
  canDeactivate(
    component: ContactSupportComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.contactForm.dirty) {
      return component.confirmleavePage(nextState.url);
    }
    return true;
  }
}
