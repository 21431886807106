import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Constants } from '../../constants';
import { StoreService } from './store.service';

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private store: StoreService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const httpTimeout = this.store.isOnline ? 30000 : 250;
    let reqClone: HttpRequest<any> = req.clone();
    if (req.url.endsWith('/ping')) {
      return next.handle(reqClone);
    }
    // Set access token to requests going to the tracking API
    if (req.url.startsWith(`${Constants.trackingApiRoot}`)) {
      const token = this.authService.getToken();
      const payload = Object.assign({}, req.body);

      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
      });

      headers = headers.append('Authorization', `Bearer ${token}`);

      const url = `${req.url}`;
      let withCredentials = false;
      const userProfile = this.authService.getUserProfile();

      // The 'tag' query param being set is not required by the backend. It is a work-around to force the
      // service-worker to only provide data for the logged in user i.e. by making the routes user specific.
      const setParams =
        req.method.toLowerCase() === 'get' && !req.url.endsWith('/ping')
          ? { tag: userProfile ? this.authService.getUserProfile().sub.replace('auth0|', '') : undefined }
          : undefined;

      reqClone = req.clone({ headers, url, withCredentials, setParams });
    }

    return next.handle(reqClone).pipe(
      timeout(httpTimeout),
      catchError(error => {
        if (error.name === 'TimeoutError') {
          throw new HttpErrorResponse({ error, url: reqClone.url, status: 0, statusText: 'Request timed out' });
        } else {
          throw error;
        }
      }),
    );
  }
}
