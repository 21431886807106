import { Injectable } from '@angular/core';

import { MatMenuTrigger } from '@angular/material/menu';

@Injectable({
  providedIn: 'root',
})
export class MenuTriggersService {
  menuTriggers: MatMenuTrigger[] = [];
  activeTrigger: MatMenuTrigger | undefined;

  getParentTriggerName(trigger: MatMenuTrigger) {
    if (!trigger) return undefined;
    const nodes: HTMLElement[] = [];

    // TODO: Find a way to pin down on activeTrigger. This work-around is a dirty hack
    const children: HTMLCollection =
      trigger.menu?.parentMenu &&
      (trigger.menu?.parentMenu as any)._elementRef.nativeElement.parentNode
        .children;

    if (!children) return undefined;

    for (let i = 0; i < children.length; i++) {
      const child = children[i] as HTMLElement;
      nodes.push(child);
    }
    return nodes.find((n) => n.classList.contains('mat-menu-item'))?.innerText;
  }

  getTriggerName(trigger: MatMenuTrigger) {
    if (!trigger) return undefined;

    // TODO: Find a way to pin down on activeTrigger. This work-around is a dirty hack
    return (trigger as unknown as any)._element.nativeElement.innerText;
  }

  getTriggerElement(triggerName: string) {
    if (!triggerName) return undefined;

    return this.menuTriggers.find((t) => {
      return this.getTriggerName(t) === triggerName;
    });
  }

  getTriggerAncestors(trigger?: MatMenuTrigger): string[] {
    if (!trigger) return [];

    const triggerList: string[] = [];
    const gatherFamily = (t?: MatMenuTrigger) => {
      if (!t) return;
      const child = this.getTriggerName(t);
      triggerList.push(child);

      const ancestorName = this.getParentTriggerName(t);
      if (!ancestorName) return;
      const ancestorElem = this.getTriggerElement(ancestorName);
      gatherFamily(ancestorElem);
    };

    gatherFamily(trigger);
    return triggerList;
  }
}
