import { AbstractControl } from '@angular/forms';

export const uppercase = (c: AbstractControl): { [key: string]: boolean } | null => {
  const value = c.value;
  const pattern = /[A-Z]+/;
  if (!pattern.test(value)) {
    return { uppercase: true };
  }
  return null;
};
