import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { Subscription, firstValueFrom } from 'rxjs';

import { TeamService } from '../../core/services/team.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { AuthService } from '../../core/services/auth.service';
import { NotificationService } from '../../core/services/notification.service';
import { DateService } from '../../core/services/date.service';
import { StoreService } from '../../core/services/store.service';
import { ValidationErrorCheckService } from '../../core/services/validation-error-check.service';
import { digit } from '../../core/validators/digit.validator';
import { lowercase } from '../../core/validators/lowercase.validator';
import { specialCharacter } from '../../core/validators/special-character.validator';
import { uppercase } from '../../core/validators/uppercase.validator';
import { passwordMatch } from '../../core/validators/password-match.validator';
import { ErrorLoggingService } from '../../core/services/logging.service';
import { PayService } from '../../core/services/pay.service';
import { DataService } from '../../core/services/data.service';
import { arrayToPhrase } from '../../helpers/grammar';
import { IBundle } from '../../global-models/bundle.interface';
import { errorMessage } from '../../helpers/error-message';
import { IUserProfile } from '../../global-models/user-profile.interface';
import { TrackablesFilterService } from '../../tracking/services/trackable-filter.service';
import { NbTrigger } from '@nebular/theme';

@Component({
  selector: 'app-manage',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.scss'],
})
export class ManageAccountComponent implements OnInit, OnDestroy {
  changePasswordForm: UntypedFormGroup;
  accountUpgradeForm: UntypedFormGroup;
  clearLocalDataForm: UntypedFormGroup;
  formErrors: any;
  expiry: string;
  userProfile: IUserProfile;
  isAdmin: boolean;
  bundleWeight: number;
  bundles: IBundle[];
  srBundlesSummary: string;
  actions = {};

  NbTrigger = NbTrigger;

  private subscriptions = new Subscription();

  get isOnline(): boolean {
    return this.online();
  }
  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn;
  }

  get activeBundle() {
    return this.store.activeBundle;
  }

  set activeBundle(bundle: IBundle) {
    this.store.activeBundle = bundle;
  }

  constructor(
    private teamService: TeamService,
    private spinner: SpinnerService,
    private authService: AuthService,
    private notifier: NotificationService,
    private route: ActivatedRoute,
    private date: DateService,
    private store: StoreService,
    private fb: UntypedFormBuilder,
    private errorCheck: ValidationErrorCheckService,
    private router: Router,
    private errorLogger: ErrorLoggingService,
    private payService: PayService,
    private dataService: DataService,
    private filter: TrackablesFilterService
  ) {}

  async ngOnInit() {
    try {
      this.initialiseForm();
      this.getUserProfile();
      await this.getActiveBundle();
      await this.getAccountDetails();
      await this.getBundles();
    } catch (error) {}
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  makePayment() {}

  async accountUpgrade(bundleName: string) {
    try {
      this.actions[bundleName] = true;
      const response = await firstValueFrom(
        this.dataService.accountUpgrade(bundleName)
      );

      const { expiry, message } = response;
      this.store.appExpiry = expiry;
      this.expiry = this.date.fromUnix(this.store.appExpiry, 'll');
      this.activeBundle = this.bundles.find((bndl) => bndl.name === bundleName);
      this.actions = {};
      this.notifier.showSuccess(message);
    } catch (error) {
      this.actions = {};
      this.notifier.showError(errorMessage(error));
      this.errorLogger.logError(errorMessage(error));
    }
  }

  setCurrentBundle() {
    this.accountUpgradeForm.patchValue({ bundleName: this.activeBundle.name });
  }

  async clearLocalData(depth: 'user' | 'account' | 'application') {
    try {
      await this.filter.clearLocalData(depth);
      this.store.trackablesRefreshNeeded = true;
      // Navigate to Trackables page to force fetch trackables
      await this.router.navigateByUrl('/subscribed/tracking/trackables');
      // Trick the app to come to the manage-account page after reload
      this.store.currentUrl = '/admin/account/manage-account';
      window.document.location.reload();
    } catch (error) {
      console.error(error);
    }
  }

  async changePassword(formData: any) {
    try {
      this.spinner.show(true);
      const response = await firstValueFrom(
        this.teamService.changePassword(formData.passwords)
      );

      this.spinner.hide();
      this.notifier.showSuccess(response.message);
      await this.authService.removeUser();
      this.authService.login();
    } catch (error) {
      this.spinner.hide();
      this.notifier.showError(errorMessage(error));
      this.errorLogger.logError(errorMessage(error));
    }
  }

  async deleteAccount() {
    try {
      this.spinner.show(true);
      const response = await firstValueFrom(this.teamService.deleteSuperUser());

      this.spinner.hide();
      await this.authService.removeUser();
      this.notifier.showSuccess('Account deletion successful');
    } catch (error) {
      this.spinner.hide();
      this.notifier.showError(errorMessage(error));
      this.errorLogger.logError(errorMessage(error));
    }
  }

  back() {
    this.router.navigate([`${this.store.previousUrl}`]);
  }

  private async getBundles() {
    try {
      this.bundles = this.store.bundles;
      if (!this.bundles) {
        const data = await firstValueFrom(this.route.parent.data);
        this.bundles = data.bundles;
        this.store.bundles = data.bundles;
      }
      this.buildBundlesScreenReaderSummary(this.bundles);
    } catch (error) {
      this.notifier.showError(errorMessage(error));
      this.errorLogger.logError(errorMessage(error));
    }
  }

  private buildBundlesScreenReaderSummary(bundles: any[]) {
    try {
      this.srBundlesSummary = '';
      const num = bundles.length;
      const names = bundles.map((bndl) => bndl.name);
      const descriptions = bundles.map((bndl) => {
        const name = bndl.name.toLowerCase();
        return `${bndl.name}, Team Members ${
          name === 'enterprise' ? 'unlimited' : bndl.maxMembers
        }, Tracker Groups ${
          name === 'enterprise' ? 'unlimited' : bndl.maxTrackables
        }, Tracked Items ${
          name === 'enterprise' ? 'unlimited' : bndl.maxTrackables * 100
        } and priced at ${bndl.price} US Dollars per month `;
      });

      this.srBundlesSummary += `Nirgel comes in ${num} ${
        num === 1 ? 'package namely,' : 'packages which are'
      } ${arrayToPhrase(
        names
      )}. The packages upper limits are as follows: ${arrayToPhrase(
        descriptions
      )}`;
    } catch (error) {
      this.notifier.showError(errorMessage(error));
      this.errorLogger.logError(errorMessage(error));
    }
  }

  private initialiseForm() {
    this.accountUpgradeForm = this.fb.group({
      bundleName: ['', [Validators.required]],
    });

    this.clearLocalDataForm = this.fb.group({
      depth: ['user', [Validators.required]],
    });

    this.changePasswordForm = this.fb.group({
      passwords: this.fb.group(
        {
          password: [
            '',
            [
              Validators.required,
              Validators.minLength(8),
              specialCharacter,
              digit,
              lowercase,
              uppercase,
            ],
          ],
          confirmPassword: '',
        },
        { validator: passwordMatch }
      ),
    });

    const sub = this.errorCheck.errorCheck(this.changePasswordForm).subscribe(
      (errors) => (this.formErrors = errors),
      (error) => {
        throw Error(errorMessage(error));
      }
    );
    this.subscriptions.add(sub);
  }

  private async getActiveBundle() {
    try {
      const data = this.route.parent.snapshot.data;
      this.activeBundle = data.activeBundle;
      this.payService.price = this.activeBundle.price;
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      this.notifier.showError(errorMessage(error));
      this.errorLogger.logError(errorMessage(error));
    }
  }

  private async getAccountDetails() {
    try {
      this.spinner.hide();
      const data = this.route.snapshot.data;
      this.store.appExpiry = data.details.expiry;
      this.expiry = this.date.fromUnix(this.store.appExpiry, 'll');
    } catch (error) {
      this.notifier.showError(errorMessage(error));
      this.errorLogger.logError(errorMessage(error));
    }
  }

  private getUserProfile() {
    this.userProfile = this.authService.getUserProfile();
    this.isAdmin = this.authService.isAdmin;
  }

  private online(): boolean {
    return this.store.isOnline;
  }
}
