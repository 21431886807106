import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import {
  BreakpointState,
  BreakpointObserver,
  Breakpoints,
} from '@angular/cdk/layout';

import { Observable } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { INavLink } from '../..';

@Component({
  selector: 'smx-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements AfterViewInit {
  @Input() navLinks!: INavLink[];
  @Input() opened!: boolean;
  @Output() openedChanged!: EventEmitter<boolean>;
  @ViewChild(MatSidenav) sideNav!: MatSidenav;

  showFiller!: boolean;
  isHandset: Observable<BreakpointState> = this.breakPointObserver.observe(
    Breakpoints.Handset
  );
  constructor(
    private router: Router,
    private breakPointObserver: BreakpointObserver,
    private renderer: Renderer2
  ) {
    this.openedChanged = new EventEmitter();
    // this.opened = true;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // const rootElem = this.renderer.selectRootElement(
      //   '.mat-sidenav-container'
      // );
      // this.sideNav.open();
      // // console.log(this.sideNav.opened);
      // // console.log(this.navLinks);
    });
  }

  openedChangeHandler() {
    this.openedChanged.emit(this.opened);
  }

  logout(): void {
    this.router.navigate(['/login']);
  }
}
