import { AbstractControl } from '@angular/forms';

export const digit = (c: AbstractControl): { [key: string]: boolean } | null => {
  const value = c.value;
  const pattern = /[\d]+/;
  if (!pattern.test(value)) {
    return { digit: true };
  }
  return null;
};
