import { Injectable } from '@angular/core';

/**
 * Removes modal-backdrop from a page
 */
@Injectable()
export class BackdropManagerService {
  /**
   * Removes a modal-backdrop and modal-open class from the body element of the page
   *
   * Primarily used as a work-around to remove a bootstrap added backdrop when
   * a back button is clicked while a modal window is open. The default bootstrap
   * behaviour is to dismiss the modal window but leaving the backdrop div and the
   * modal-open class on the body element intact. This leaves the page not clickable.
   */
  hide() {
    const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
      document.body.removeChild(backdrop);
      document.body.classList.remove('modal-open');
      document.body.classList.remove('page-lockdown');
    }
  }

  /**
   * Enforces a screen-wide lockdown
   */
  show() {
    const backdrop = document.querySelector('.modal-backdrop');
    const body = document.body;
    if (!backdrop) {
      const div = document.createElement('div');
      div.setAttribute('class', 'modal-backdrop show');
      body.appendChild(div);
      body.classList.add('modal-open');
      body.classList.add('page-lockdown');
    }
  }

  /**Ascertain if a page is on screen-wide lockdown or not */
  onLockdown() {
    return !!document.querySelector('.page-lockdown');
  }
}
