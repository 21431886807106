import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import { take } from 'rxjs/operators';

import { emailDot } from '../../core/validators/email.validator';
import { NotificationService } from '../../core/services/notification.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { StoreService } from '../../core/services/store.service';
import { ValidationErrorCheckService } from '../../core/services/validation-error-check.service';
import { AutoUnsubscribe } from '../../helpers/observable-auto-unsubscribe';
import { MailingService } from '../../core/services/mailing.service';
import { letter } from '../../core/validators/letter.validator';
import { letterFirst } from '../../core/validators/letter-first';
import { ErrorLoggingService } from '../../core/services/logging.service';
import { errorMessage } from '../../helpers/error-message';
import { AuthService } from '../../core/services/auth.service';
import { NbTrigger } from '@nebular/theme';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss'],
})
@AutoUnsubscribe()
export class ContactSupportComponent implements OnInit, AfterViewInit {
  @ViewChild('message') messageField: ElementRef<HTMLTextAreaElement>;
  contactForm: UntypedFormGroup;
  formErrors: any;
  nextRoute: string;

  NbTrigger = NbTrigger;

  constructor(
    private fb: UntypedFormBuilder,
    private errorCheck: ValidationErrorCheckService,
    private spinner: SpinnerService,
    private notifier: NotificationService,
    private mailService: MailingService,
    private router: Router,
    private store: StoreService,
    private errorLogger: ErrorLoggingService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.spinner.hide();
    this.initialiseForm();
    this.listenForValidationErrors();
  }

  ngAfterViewInit(): void {
    window.setTimeout(() => {
      const user = this.authService.getUserProfile();
      if (user) {
        this.contactForm.patchValue({ email: user.userEmail });
      }
      this.setFocus();
    }, 100);
  }

  async sendMessage() {
    try {
      this.spinner.show();
      const formData = this.contactForm.value;
      const response = await firstValueFrom(
        this.mailService.sendMail(formData)
      );
      this.spinner.hide();
      this.contactForm.markAsPristine();
      this.notifier.showSuccess(response.message);
    } catch (error) {
      this.spinner.hide();
      this.notifier.showError(errorMessage(error));
      this.errorLogger.logError(errorMessage(error));
    }
  }

  back() {
    const previousUrl = this.store.previousUrl || '/home';
    this.router.navigateByUrl(`${previousUrl}`);
  }

  leavePage(data: any) {
    this.contactForm.markAsPristine();
    this.nextRoute ? this.router.navigateByUrl(this.nextRoute) : null;
    this.nextRoute = undefined;
  }

  confirmleavePage(nextRoute: string): boolean {
    this.nextRoute = nextRoute;
    (document.querySelector('#leave-page-btn') as HTMLElement).click();
    return false;
  }

  private initialiseForm() {
    this.contactForm = this.fb.group({
      email: ['', [Validators.required, Validators.email, emailDot]],
      phone: null,
      subject: ['general', Validators.required],
      message: ['', [Validators.required, letter, letterFirst]],
    });
  }

  private listenForValidationErrors() {
    this.errorCheck.errorCheck(this.contactForm).subscribe(
      (errors) => (this.formErrors = errors),
      (error) => {
        throw Error(errorMessage(error));
      }
    );
  }

  private setFocus(): void {
    this.messageField ? this.messageField.nativeElement.focus() : null;
    // (document.querySelector('#email') as HTMLInputElement).focus();
  }
}
