/**
 * Gets a random integer between and inclusive of the the supplied ```min``` and ```max```
 *
 * @param min Minimum integer
 * @param max Maximum integer
 * @returns Random integer
 */
export const getRandomInt = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * Checks if a given element's bottom edge is in viewport
 *
 * @param element A DOM element to be checked for visibility. Optional if ```boundingRect``` is defined
 * @param boundingRect ```element```'s bounding rectangle. Optional if ```element``` is defined
 */
export const bottomEdgeInViewport = (
  element?: HTMLElement,
  boundingRect?: DOMRect
): boolean => {
  const rect = element?.getBoundingClientRect() || boundingRect;

  if (rect) {
    return (
      rect.bottom > 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)
    );
  }
  throw Error(`Please provide either one of the parameters`);
};

/**
 * Checks if a given element is fully visible
 *
 * @param element A DOM element to be checked for visibility
 */
export const isFullyInViewport = (element: HTMLElement): boolean => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    bottomEdgeInViewport(undefined, rect) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
