import { Injectable } from '@angular/core';

import { createEvent } from '../../helpers/create-event';

@Injectable()
export class EventGeneratorService {
  /**Emits a ```chartResized``` event */
  chartResizedEvent: Event;

  /**Emits a ```chartSelected``` event */
  chartSelectedEvent: Event;
  constructor() {
    this.chartResizedEvent = createEvent('chartResized');
    this.chartSelectedEvent = createEvent('chart-selected');
  }
}
