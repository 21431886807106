<a
  mat-menu-item
  [matMenuTriggerFor]="menu"
  *ngIf="matMenuItem.isDropdown"
  #locationMenuTrigger="matMenuTrigger"
  (mouseover)="mouseEnter(locationMenuTrigger, $event)"
  (mouseleave)="mouseLeave(locationMenuTrigger)"
  (click)="mouseEnter(locationMenuTrigger, $event)"
  (click)="clicked(locationMenuTrigger, $event)"
  class="d-flex align-items-center"
>
  <span>
    <mat-icon *ngIf="matMenuItem.preIcon" class="material-icons">
      {{ matMenuItem.preIcon }}
    </mat-icon>
    <span>{{ matMenuItem.label }}</span>
  </span>
  <mat-icon *ngIf="matMenuItem.postIcon" class="material-icons ms-auto me-0">
    {{ matMenuItem.postIcon }}
  </mat-icon>
</a>

<mat-menu
  #menu="matMenu"
  xPosition="after"
  yPosition="below"
  hasBackdrop="false"
  overlapTrigger="false"
>
  <smx-mat-menu-wrapper
    (notify)="onNotify($event)"
    [timedOutCloser]="timedOutCloser"
    *ngFor="let menuItem of matMenuItem.dropdownItems"
    [matMenuItem]="menuItem"
  ></smx-mat-menu-wrapper>
</mat-menu>

<a
  #simpleLink
  mat-menu-item
  *ngIf="!matMenuItem?.isDropdown"
  [routerLink]="matMenuItem.link"
  (mouseover)="childMouseEnter(simpleLink)"
  (click)="childMouseEnter(simpleLink)"
  (mouseleave)="childMouseLeave(simpleLink)"
  class="d-flex align-items-center"
>
  <span>
    <mat-icon *ngIf="matMenuItem.preIcon" class="material-icons">
      {{ matMenuItem.preIcon }}
    </mat-icon>
    <span>{{ matMenuItem.label }}</span>
  </span>
  <mat-icon *ngIf="matMenuItem.postIcon" class="material-icons ms-auto me-0">
    {{ matMenuItem.postIcon }}
  </mat-icon>
  <span *ngIf="!matMenuItem.postIcon"></span>
</a>
