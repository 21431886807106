<nb-card>
  <nb-card-header class="">Select Theme</nb-card-header>
  <nb-card-body>
    <nb-select
      fullWidth
      [selected]="currentTheme"
      (selectedChange)="changeTheme($event)"
      status="primary"
    >
      <nb-option *ngFor="let theme of themes" [value]="theme.value">
        {{ theme.name }}</nb-option
      >
    </nb-select>
  </nb-card-body>
</nb-card>
