import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';

export interface INavLink {
  link: string;
  label: string;
  role?: string;
  id?: string;
  raised?: boolean;
  linkIsExternal?: boolean;
  isDropdown?: boolean;
  listItemClass?: string;
  linkClass?: string;
  dropdownItems?: INavLink[];
  title?: string;
  hasPopover?: boolean;
  popoverTriggers?: string;
  popoverContent?: string;
  color?: string;
  preIcon?: string;
  postIcon?: string;
  dropdownSubmenu?: boolean;
}

@Component({
  selector: 'smx-nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: ['./nav-link.component.scss'],
})
export class NavLinkComponent implements AfterViewInit, INavLink {
  @Input() isDropdown: boolean | undefined;
  @Input() link!: string;
  @Input() label!: string;
  @Input() role?: string | undefined;
  @Input() id?: string | undefined;
  @Input() raised?: boolean | undefined;
  @Input() linkIsExternal?: boolean | undefined;
  @Input() listItemClass?: string | undefined;
  @Input() linkClass?: string | undefined;
  @Input() dropdownItems?: INavLink[] | undefined;
  @Input() title?: string | undefined;
  @Input() hasPopover?: boolean | undefined;
  @Input() popoverTriggers?: string | undefined;
  @Input() popoverContent?: string | undefined;
  @Input() color?: string | undefined;
  @Input() preIcon?: string | undefined;
  @Input() postIcon?: string | undefined;
  @Input() dropdownSubmenu?: boolean | undefined;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const rootElem: HTMLElement = this.renderer.selectRootElement(
        this.el.nativeElement,
        true
      );
      // make it as accordion for smaller screens
      if (window.innerWidth < 992) {
        // close all inner dropdowns when parent is closed
        rootElem.querySelectorAll('.navbar .dropdown').forEach((dropdown) => {
          dropdown.addEventListener('hidden.bs.dropdown', () => {
            // after dropdown is hidden, then find all submenus
            dropdown.querySelectorAll('.submenu').forEach((submenu) => {
              // hide every submenu as well
              (submenu as unknown as HTMLElement).style.display = 'none';
            });
          });
        });

        rootElem.querySelectorAll('.dropdown-menu li').forEach((element) => {
          element.addEventListener('click', (e) => {
            const submenu = element.querySelector(
              'smx-nav-link > .submenu'
            ) as HTMLElement;

            if (submenu) {
              // prevent opening link if link needs to open dropdown

              e.preventDefault();
              if (submenu.style.display === 'block') {
                submenu.style.display = 'none';
              } else {
                submenu.style.display = 'block';
              }
            }
          });
        });
      }
    });
  }
}
