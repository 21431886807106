export const environment = {
  deployed: true,
  production: true,
  stsAuthority: 'https://nirgel.us.auth0.com',
  trackingApiRoot: 'https://nirgel.eu-4.evennode.com/api',
  backendUrl: 'https://nirgel.eu-4.evennode.com',
  clientRoot: 'https://staging.nirgel.com',
  serviceWorker: 'sw-master.js',
  vapidPublicKey:
    'BJF61v-iyfY0_OWYCEUVuUQa9A0YQxnzkdBfqB5B3K_Lb7uTX0rrD8Tb3SK55HQVcytXJkVJTlnLU8zyFyImRkw',
};
