import { NgModule } from '@angular/core';

import { AccountRoutingModule } from './account-routing.module';
import { MakePaymentComponent } from './make-payment/make-payment.component';
import { AccountComponent } from './account.component';
import { SharedModule } from '../shared/shared.module';
import { ActiveBundleResolverService } from './active-bundle-resolver.service';
import { PayGuardService } from './make-payment/pay-guard.service';
import { ContactSupportComponent } from './contact-support/contact-support.component';
import { ManageAccountComponent } from './manage/manage-account.component';
import { AccountDetailsResolverService } from './manage/account-details-resolver.service';
import { ContactSupportGuard } from './contact-support/contact-support.guard';
import { BundlesResolverService } from './bundles-resolver.service';

@NgModule({
  declarations: [MakePaymentComponent, AccountComponent, ContactSupportComponent, ManageAccountComponent],
  imports: [AccountRoutingModule, SharedModule],
  providers: [
    ActiveBundleResolverService,
    BundlesResolverService,
    PayGuardService,
    AccountDetailsResolverService,
    ContactSupportGuard,
  ],
})
export class AccountModule {}
