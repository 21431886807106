import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AddMemberComponent } from './team/add-member.component';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin-routing.module';
import { MemberComponent } from './team/member.component';
import { SharedModule } from '../shared/shared.module';
import { TeamComponent } from './team/team.component';
import { MemberResolverService } from './team/resolvers/member-resolver.service';
import { AccountModule } from '../account/account.module';
import { CanDeactivateAddMember } from './team/can-deactivate-add-member';

@NgModule({
  declarations: [AddMemberComponent, AdminComponent, MemberComponent, TeamComponent],
  imports: [AdminRoutingModule, CommonModule, SharedModule, AccountModule],
  providers: [MemberResolverService, CanDeactivateAddMember],
})
export class AdminModule {}
