import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '../../../core/services/auth.service';
import { NbAuthJWTToken } from '@nebular/auth';
import { BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class OnlineStatusService implements OnDestroy {
  private token: NbAuthJWTToken | undefined;
  private subscriptions = new Subscription();
  private listenersSet = false;

  isOnline$ = new BehaviorSubject<boolean>(navigator.onLine);
  /**
   * Emits a flag whether viewing offline data is permissible at that juncture
   *
   * The app is regarded as `offlinable` if it is currently `offline` and there is an `auth token` stored in
   * the browser. The `token` maybe either current or expired
   */
  isOfflinable$ = new BehaviorSubject<boolean>(false);

  constructor(private authService: AuthService, private http: HttpClient) {
    const tknSub = this.authService.token$.subscribe((token) => {
      this.token = token;
      if (!this.listenersSet) {
        this.setEventListeners();
      }
    });
    this.subscriptions.add(tknSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Checks whether the browser is truely online by performing a ping to the backend.
   *
   * This is a workaround for some browsers that give false online-positives
   */
  async isTruelyOnline() {
    try {
      const response = await firstValueFrom(
        this.http.get<any>(`${environment.backendUrl}`, {
          observe: 'response',
        })
      );

      if (response.ok) return true;
      return false;
    } catch (error) {
      return false;
    }
  }

  private setEventListeners() {
    window.addEventListener('online', async () => {
      const truelyOnline = await this.isTruelyOnline();
      if (truelyOnline) {
        console.log('online');
        this.isOfflinable$.next(false);
        return this.isOnline$.next(true);
      }

      this.isOfflinable$.next(!!this.token);
      console.log('lie-FI');
      return this.isOnline$.next(false);
    });

    window.addEventListener('offline', () => {
      this.isOfflinable$.next(!!this.token);
      this.isOnline$.next(false);
      console.log('offline');
    });

    this.listenersSet = true;
    // Trigger the first network scan
    this.scanConnection().catch((error) => console.error(error));
  }

  private async scanConnection() {
    const checkForWifi = () => {
      window.setTimeout(() => {
        dispatchEvent(new Event('offline'));
      }, 30000);
    };

    try {
      if (navigator.onLine) {
        const truelyOnline = await this.isTruelyOnline();
        truelyOnline
          ? window.setTimeout(() => {
              // App online. Periodically search for Lie-fi
              // N.B. Lie-fi search is initiated in the 'online' eventListener handler, just fire the 'online' event
              dispatchEvent(new Event('online'));
            }, 60000)
          : checkForWifi(); // Server unreachable. Continue to check for it's availability
      } else {
        // App is definately offline. Passively wait for an online event to be fired by navigator
      }
    } catch (error) {
      console.error(error);
    }
  }
}
